import L from 'leaflet'
import { appStore } from '../store'
import {
  actionBuildTransportPlaces,
  actionBuildMarker,
  actionOpenMarker,
  actionBuildCustomMarker,
} from '../actions/withRedux'
import {
  actionGoToValid,
  actionInputEndItemsChange,
  actionInputEndValueChange,
  actionInputItemsChange,
  actionInputStartItemsChange,
  actionInputStartValueChange,
  actionInputValueChange,
  actionResetStationIndex,
  actionSetGeolocationError,
  actionSetPlaceClicked,
  actionSetOpenedCollapse,
} from '../actions/board'
import {
  actionSetLineSelected,
  actionSetReduxMarkers,
  actionSetPublicPlaces,
  actionSetTransportPlaces,
  actionAddCustomMarkers,
  actionSetCustomMarkers,
} from '../actions/map'
import { buildCustomMarkers } from './map'
import axios from '../middlewares/axios'
import history from '../history'
import {
  actionAddPlace,
  actionAddStopArea,
  actionInitDate,
  actionInitDatePickerMinMaxDate,
  actionSetCalendarDate,
  actionSetCalendarDateTime,
  batch,
} from '../actions/app'
import { initModal, toggleModal } from '../actions/modal'
import { updateDataLayer } from '../tracking'
import BikeInterface from '../interfaces/BikeInterface'
import { message } from './message'
import { dateToLibelle, navitiaDateToDate, formatDate } from '../utils/tools'
import { Circle, Marker } from 'react-leaflet'
import { actionSetTourismPartnerSelectedPartner } from '../actions/tourismPartners'
import { luminance } from 'luminance-js'
import { fitBounds } from '../utils/leaflet/map'
import { sortBy } from '../utils/leaflet/tools'
import decodeGeometry from '@mapbox/polyline' // TO CHECK
import exportedSCSS from '../scss/app.scss'

const {
  REACT_APP_ALL_POPUP_ON_TOP,
  REACT_APP_LIBRARY_URL,
  REACT_APP_PROJECT,
  REACT_APP_POLES,
  REACT_APP_AREAS_ZOOM_LEVEL,
  REACT_APP_DISRUPTION,
  REACT_APP_POPUP_AREA_COLOR_FIRST_LINE,
  REACT_APP_GO_TO_RC_URL,
  REACT_APP_GTM,
  REACT_APP_STOP_POPUP_COLOR_LINE,
  REACT_APP_FAVORITES,
  REACT_APP_PRIMARY_COLOR,
  REACT_APP_GO_TO_RC_INVERT_TO_FROM,
} = process.env

export const addPolesExchanges = (areas, markers, zoom, onlyLine = false) => {
  const polesToDisplay = JSON.parse(REACT_APP_POLES)

  if (polesToDisplay && polesToDisplay.ids) {
    const poles = onlyLine
      ? areas.reduce((accumulator, currentArea) => {
          const area = currentArea.props.area.id
          const stop = markers.find(m => m.props.stop.stop_area === area)

          if (stop && polesToDisplay.ids.includes(area)) {
            accumulator.push(currentArea)
          }

          return accumulator
        }, [])
      : areas.filter(area => polesToDisplay.ids.includes(area.props.area.id))

    if (zoom > polesToDisplay.zoom) {
      poles.map(pole => {
        return markers.push(
          appStore.dispatch(
            actionBuildMarker(
              { ...pole.props.area, id: pole.props.area.id + '-pole-exchange' },
              {
                icon: L.icon({
                  iconUrl: assetsPath('/assets/images/stops/pole.svg'),
                  iconSize: REACT_APP_AREAS_ZOOM_LEVEL > zoom ? [40, 40] : [52, 52],
                  iconAnchor: REACT_APP_AREAS_ZOOM_LEVEL > zoom ? [20, 30] : [26, 39],
                }),
                area: { ...pole.props.area },
                zIndexOffset: 10,
              },
            ),
          ),
        )
      })
    }
  }
}

/**
 * Return the correct assets path depends on base URL
 * @param {String} path assets path
 */
export const assetsPath = path => (REACT_APP_LIBRARY_URL ? REACT_APP_LIBRARY_URL + path : path)

/**
 * Build Leaflet icon size / anchor for a given category
 * @param category
 * @returns {{iconAnchor: number[], iconSize: number[]}}
 */
export const buildPlaceIconClassName = category => {
  let className = 'lc-place-icon'

  switch (category) {
    case 'poi_type:amenity:bicycle_rental':
      className += ' lc-bicycle-rental'
      break

    case 'poi_type:stations':
      className += ' lc-stations'
      break

    case 'poi_type:amenity:vcub':
      className += ' lc-vcub'
      break
    case 'poi_type:entrance':
      className += ' lc-marker-entrance'
      break
    case 'poi_type:depositaires':
      className += ' lc-depositaires lc-default'
      break

    default:
      className += ' lc-default'
      break
  }

  return className
}

/**
 * Debug in local & dev env only
 * @param {Object} {message, data} message & data to log
 * @param {String} color color of the message. Prefilled values are "success", "info", "error", or "warning"
 * @param {String} title title of the message
 */
export const debug = ({ message = 'debug :', data }, color, title = 'DEBUG_FRONT') => {
  if (isLocalOrDev()) {
    switch (color) {
      case 'success':
        color = 'Green'
        break
      case 'info':
        color = 'DodgerBlue'
        break
      case 'error':
        color = 'Red'
        break
      case 'warning':
        color = 'Orange'
        break
      default:
        color = 'info'
    }

    console.group(`%c ${title}`, `color:${color}`)

    if (data) {
      console.log(`%c ${message}`, `color:${color}`, data)
    } else {
      console.log(`%c ${message}`, `color:${color}`)
    }

    console.groupEnd()
  }
}

export const displayDisruptedMarkersOnMap = (disruptions, reduxMarkers, currentLine, stop) => {
  const now = formatDate(new Date(), 'ymdhm')
  // Handle stop_point & section disruptions on map
  const impactedObjects = []

  if (reduxMarkers.length > 0 && impactedObjects.length === 0) {
    for (const disruption of disruptions) {
      // Push only impacted_objects that are between begin & end dates today
      const dBegin = formatDate(navitiaDateToDate(disruption.begin), 'ymdhm')
      const dEnd = formatDate(navitiaDateToDate(disruption.end), 'ymdhm')

      if (now >= dBegin && now <= dEnd) {
        for (const object of disruption.impacted_objects) {
          if (['section', 'stop_point', 'stop_area'].includes(object.type)) {
            if ((object.line && object.line === currentLine.id) || !object.line) {
              object.severity = disruption.severity
              impactedObjects.push(object)
            }
          }
        }
      }
    }

    // Loop through each impacted object and display it on map
    const markers = []
    const impactedMarkers = []

    for (const impacted of impactedObjects) {
      switch (impacted.type) {
        case 'section': {
          const route = currentLine.routes.find(r => r.direction_id === currentLine.direction_id)

          const from = reduxMarkers.find(marker => {
            if (impacted.from.includes('stop_area')) {
              return marker.props.stop.stop_area === impacted.from && impacted.routes.find(r => r.id === route.route_id)
            } else {
              return marker.props.stop.id === impacted.from && impacted.routes.find(r => r.id === route.route_id)
            }
          })

          const to = reduxMarkers.find(marker => {
            if (impacted.from.includes('stop_area')) {
              return marker.props.stop.stop_area === impacted.to && impacted.routes.find(r => r.id === route.route_id)
            } else {
              return marker.props.stop.id === impacted.to && impacted.routes.find(r => r.id === route.route_id)
            }
          })

          if (from && to) {
            // Pass the severity
            from.props.stop.severity = to.props.stop.severity = impacted.severity

            // Add all markers between the "from" and the "to"
            impactedMarkers.push(...reduxMarkers.slice(reduxMarkers.indexOf(from), reduxMarkers.indexOf(to) + 1))
          }

          break
        }
        case 'stop_area':
        case 'stop_point': {
          const stop = reduxMarkers.find(
            marker => marker.props.stop[impacted.type === 'stop_area' ? 'stop_area' : 'id'] === impacted.id,
          )

          if (stop) {
            stop.props.stop.severity = impacted.severity
            impactedMarkers.push(stop)
          }

          break
        }
        default:
          break
      }

      for (const impactedMarker of impactedMarkers) {
        // Populate with retrieved markers
        impactedMarker &&
          !markers.find(m => m.props.stop.id === impactedMarker.props.stop.id) &&
          markers.push(
            appStore.dispatch(
              actionBuildMarker(impactedMarker.props.stop, {
                key: currentLine.code + '_' + impactedMarker.props.stop.index + '_disrupted',
                icon: new L.DivIcon({
                  className: `lc-circle-icon-marker lc-map-stop-marker ${
                    impactedMarker.props.stop.terminus ? ' lc-stop-terminus' : ''
                  }`,
                  iconSize: impactedMarker.props.stop.terminus ? [10, 10] : [8, 8],
                  iconAnchor: new L.Point(4, 4),
                  html: `<div><span style="border: 2px solid #${currentLine.color}; background: ${
                    impactedMarker.props.stop.severity === 'blocking' ? 'red' : 'orange'
                  };" /><div class="${
                    impactedMarker.props.stop.severity === 'blocking'
                      ? REACT_APP_DISRUPTION
                        ? 'lc-' + JSON.parse(REACT_APP_DISRUPTION).map.blocking
                        : ''
                      : ''
                  }${
                    impactedMarker.props.stop.severity === 'delays'
                      ? REACT_APP_DISRUPTION
                        ? 'lc-' + JSON.parse(REACT_APP_DISRUPTION).map.delays
                        : ''
                      : ''
                  }${impactedMarker.props.stop.terminus ? ' lc-stop-terminus' : ''}" style="border-color: #${
                    currentLine.color
                  }"></div></div>`,
                }),
                stop: impactedMarker.props.stop,
                zIndexOffset: 250,
              }),
            ),
          )
      }
    }

    // If we have open a stop, search if it's in impacted list to open with disruption's error
    if (stop) {
      const disrupted = impactedMarkers.find(m => m.props.stop.id === stop)

      // Dispatch the open marker action
      disrupted && setTimeout(() => appStore.dispatch(actionOpenMarker(disrupted.props.stop)))
    }

    appStore.dispatch(actionSetReduxMarkers(reduxMarkers.concat(markers)))
  }
}

export function displayDisruptionsForOneStop(line, stop, disruptions, language) {
  const stopDisruptions = []

  if (disruptions?.length > 0) {
    for (const d of disruptions) {
      for (const impacted of d.impacted_objects) {
        if (navitiaDateToDate(d.begin) <= new Date() && navitiaDateToDate(d.end) >= new Date()) {
          switch (impacted.type) {
            case 'network':
              stopDisruptions.push(d)
              break
            case 'stop_point':
              if (impacted.id === stop.id) {
                stopDisruptions.push(d)
              }
              break
            case 'stop_area':
              if (impacted.id === stop.stop_area) {
                stopDisruptions.push(d)
              }
              break

            case 'section': {
              const route = line.routes?.find(r => r.direction_id === line.direction_id)

              const from = line.stops?.find(s => {
                if (impacted.from.includes('stop_area')) {
                  return s.stop_area === impacted.from && impacted.routes.find(r => r.id === route.route_id)
                } else {
                  return s.id === impacted.from && impacted.routes.find(r => r.id === route.route_id)
                }
              })

              const to = line.stops?.find(s => {
                if (impacted.from.includes('stop_area')) {
                  return s.stop_area === impacted.to && impacted.routes.find(r => r.id === route.route_id)
                } else {
                  return s.id === impacted.to && impacted.routes.find(r => r.id === route.route_id)
                }
              })

              if (
                line.stops?.indexOf(from) >= line.stops?.indexOf(stop) &&
                line.stops?.indexOf(to) <= line.stops?.indexOf(stop)
              ) {
                stopDisruptions.push(d)
              }

              break
            }

            default:
              break
          }
        }
      }
    }
    return (
      <div className="lc-disruptions in-stop">
        {stopDisruptions.map(disruption => (
          <div key={`stop-disruption-${disruption.id}`} className={`lc-${disruption.severity}`}>
            {JSON.parse(REACT_APP_DISRUPTION).severityTitle !== false && (
              <div className="lc-disruption-severity">
                <div className="lc-icon" />
                {JSON.parse(REACT_APP_DISRUPTION).titleInsteadOfSeverity === true && disruption.title
                  ? disruption.title
                  : disruption.severity === 'blocking'
                  ? 'Perturbation majeure'
                  : disruption.severity === 'delays'
                  ? 'Perturbation'
                  : 'Information'}
              </div>
            )}
            <div
              className="lc-disruption"
              //tabIndex="0"
              aria-label={
                disruptionsDatetime(disruption.begin, disruption.end, language) +
                ' : ' +
                disruption.message?.replace(/<[^>]+>/g, ' ')
              }>
              {disruption.title && !JSON.parse(REACT_APP_DISRUPTION).titleInsteadOfSeverity && (
                <div className="lc-disruption-title">{disruption.title}</div>
              )}
              {disruption.message ? (
                <div className="lc-disruption-content" dangerouslySetInnerHTML={{ __html: disruption.message }} />
              ) : (
                <div className="lc-disruption-content empty">Aucun motif renseigné</div>
              )}
              {JSON.parse(REACT_APP_DISRUPTION).date !== false && (
                <div className="lc-disruption-dates">
                  {disruptionsDatetime(disruption.begin, disruption.end, language)}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    )
  }
}

/**
 * Comparator to use on a sort Array function
 * Ex : array.sort(compareValues('key', 'desc'))
 *
 * @param {String} key props to sort by
 * @param {String} order 'asc' or 'desc'
 */
export function compareValues(key, order = 'asc') {
  return function innerSort(a, b) {
    if (!Object.prototype.hasOwnProperty.call(a, key) || !Object.prototype.hasOwnProperty.call(b, key)) {
      // property doesn't exist on either object
      return 0
    }

    const varA = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key]
    const varB = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key]
    let comparison = 0

    if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }

    return order === 'desc' ? comparison * -1 : comparison
  }
}

/**
 *
 * @param envVar
 */
export const envVarToBool = envVar => {
  if (envVar) {
    return envVar === 'true'
  } else {
    return false
  }
}

/**
 * Flatten the given object
 * @param object
 * @returns Array
 */
export const flattenObject = object => {
  const acc = []

  for (const item of Object.keys(object)) {
    const items = Array.isArray(object[item])
      ? object[item]
      : Object.keys(object[item]).reduce((acc, subItem) => {
          acc.push(...object[item][subItem])
          return acc
        }, [])

    acc.push(...items)
  }

  return acc
}

/**
 *
 * @param data
 * @param reduxMarkers
 * @returns {*}
 */
export const getRef = (data, reduxMarkers) => {
  // If we have the ref on the data (like in stop_areas or stop_points
  // without line selected) grab it, else, retrieve it from the
  // reduxMarkers from the map
  const marker = reduxMarkers?.find(m => {
    if (m.props.place) {
      return m.key === data.id.toString()
    } else if (m.props.area) {
      if (data.id.includes('stop_area')) {
        return m.props.area.id === data.id
      } else {
        return m.props.area.id === data.stop_area
      }
    } else {
      if (data?.id.includes('stop_area')) {
        return m.props.stop.stop_area === data.id
      } else {
        return m.props.stop.id === data.id
      }
    }
  })

  if (!marker) {
    return
  }

  return marker.divIcon
    ? marker.ref
    : (marker.props ? (marker.props.place ? marker.props.place : marker.props.area || marker.props.stop) : marker).ref
}

/**
 * Retrieve all line data for the given one
 * @param lines
 * @param line
 * @returns Object
 */
export const getLine = (lines, line) => {
  return {
    ...lines.find(({ id, code, network, cat }) =>
      line.id
        ? id === line.id
        : line.mode
        ? String(code).toLowerCase() === String(line.code).toLowerCase() && line.mode === cat
        : code === line.code && network === line.network,
    ),
    ...line,
  }
}

/**
 * Convert URL search params to object
 * @param url
 */
export const getURLSearchParams = url => {
  const search = new URLSearchParams(url.search)
  const params = {}

  for (const entry of search.entries()) {
    params[entry.shift()] = decodeURIComponent(entry.shift())
  }

  return params
}

export const humanReadableOpeningHours = (opening, language) => {
  const openingTable = opening.split(';')
  let ariaOpening = ' '

  for (const rule of openingTable) {
    switch (rule) {
      case '24/7':
        ariaOpening += translate('open-hour-24/7', false) + '. '
        break
      default:
        ariaOpening += replaceAllDays(rule, language) + '. '
        break
    }
  }

  return (
    <div className="lc-opening-hours" aria-label={translate('open-hour-title', false) + ariaOpening}>
      <div className="lc-opening-hours-title">{translate('open-hour-title')}</div>
      <div className="lc-opening-hours-list">
        {openingTable.map(rule => {
          switch (rule) {
            case '24/7':
              return <span key={rule}>{translate('open-hour-24/7')}</span>
            default:
              return <span key={rule}>{replaceAllDays(rule, language)}</span>
          }
        })}
      </div>
    </div>
  )
}

const replaceAllDays = (string, language) => {
  const days = {
    Mo: translate('open-hour-mo', false),
    Tu: translate('open-hour-tu', false),
    We: translate('open-hour-we', false),
    Th: translate('open-hour-th', false),
    Fr: translate('open-hour-fr', false),
    Sa: translate('open-hour-sa', false),
    Su: translate('open-hour-su', false),
    PH: translate('open-hour-ph', false),
    SH: translate('open-hour-sh', false),
  }

  const months = {
    Jan: translate('open-hour-jan', false),
    Feb: translate('open-hour-feb', false),
    Mar: translate('open-hour-mar', false),
    Apr: translate('open-hour-apr', false),
    May: translate('open-hour-may', false),
    Jun: translate('open-hour-jun', false),
    Jul: translate('open-hour-jul', false),
    Aug: translate('open-hour-aug', false),
    Sep: translate('open-hour-sep', false),
    Oct: translate('open-hour-oct', false),
    Nov: translate('open-hour-nov', false),
    Dec: translate('open-hour-dec', false),
  }

  let stringReplace = string

  // remove first blank char
  if (stringReplace.charAt(0) === ' ') {
    stringReplace = stringReplace.substr(1)
  }

  // replace months
  for (const month of Object.keys(months)) {
    stringReplace = stringReplace.replace(new RegExp(`\\b${month}\\b`, 'g'), months[month])
  }

  // replace days
  for (const day of Object.keys(days)) {
    stringReplace = stringReplace.replace(new RegExp(`\\b${day}\\b`, 'g'), days[day])
  }

  // add colon after day
  if (!string.includes('SH') && !string.includes('PH')) {
    stringReplace = stringReplace.replace(' ', language === 'en' ? ': ' : ' : ')
  }

  // replace - for months
  const slot = stringReplace.split(': ')[0]
  const currentSlot = slot.split('-')

  if (
    stringReplace.split(': ')[0].includes('-') &&
    !Object.values(days).includes(currentSlot[0]) &&
    !Object.values(days).includes(currentSlot[1])
  ) {
    if (currentSlot[0] !== currentSlot[1]) {
      stringReplace = stringReplace.replace(
        stringReplace.split(': ')[0],
        translate(
          'open-hour-months-separator',
          false,
          { key: 'm1', value: slot.split('-')[0] },
          { key: 'm2', value: slot.split('-')[1] },
        ),
      )
    } else {
      stringReplace = stringReplace.replace(stringReplace.split(': ')[0], currentSlot[0])
    }
  }

  // remove double colons
  stringReplace = stringReplace.replace(/: : /g, language === 'en' ? ': ' : ' : ')

  // replace - for days (but not in hours)
  if (stringReplace.split(': ')[0]?.includes('-')) {
    stringReplace = stringReplace.replace('-', ` ${translate('open-hour-days-separator', false)} `)
    stringReplace = stringReplace.replace(/([A-Za-z])-/g, '$1 ' + translate('open-hour-days-separator', false) + ' ')
  }

  // same case but when months are innvolved
  if (stringReplace.split(': ')[1]?.includes('-')) {
    stringReplace = stringReplace.replace(/([A-Za-z])-/g, '$1 ' + translate('open-hour-days-separator', false) + ' ')
  }

  // add space between hours
  stringReplace = stringReplace.replace(/-/g, ' - ')

  // replace closed or off with "fermé"
  stringReplace = stringReplace.replaceAll(/closed|off/g, translate('closed', false))
  stringReplace = stringReplace.replaceAll(',', ', ')
  return stringReplace
}

/**
 * Test if the module passed as param is active
 * @param {String} module module we want to test
 * @returns Boolean is active module or not
 */
export const isActiveModule = module => {
  try {
    const active = getActiveModule()

    return active?.id === module
  } catch (e) {
    console.error(e)
    throw e
  }
}

/**
 * Get active module
 * @returns Object of active module or false is no module is selected
 */
export const getActiveModule = () => {
  const { modules } = appStore.getState().app

  try {
    const pathname = history.location.pathname

    const active = modules.find(({ id, url, data }) => {
      if (url) {
        return pathname.includes(url)
      }

      if (id === 'thematic') {
        return pathname.includes(`/${data}`)
      } else {
        return pathname.includes(id)
      }
    })
    return active ? active : false
  } catch (e) {
    console.error(e)
    return false
  }
}

/**
 * Detect if we are on a local or dev env
 */
export const isLocalOrDev = () => ['local', 'dev'].includes(process.env.REACT_APP_ENV)

/**
 *  Detect if journey is on an other day
 * @param {*} journey
 * @param {Boolean} roadmap Message is displayed on roadmap
 */
export const itineraryOnOtherDay = (journey, roadmap = false) => {
  const { language } = appStore.getState().app
  const d = new Date()
  const year = d.getFullYear().toString()
  let month = (d.getMonth() + 1).toString()
  let day = d.getDate().toString()

  if (month.length < 2) {
    month = '0' + month
  }

  if (day.length < 2) {
    day = '0' + day
  }

  const clientDate = year + month + day

  if (clientDate - journey.departure_date_time.split('T')[0]) {
    return (
      <div className={'lc-journey-warning lc-departure-next-day lc-itinerary' + (roadmap ? ' lc-in-roadmap' : '')}>
        <div className="lc-icon" />
        {translate('route-calculation-departure-next-day')}{' '}
        {dateToLibelle(journey.departure_date_time, language, 'full')}
      </div>
    )
  }
}

/**
 * Display information about on demand transport sections
 * @param {*} journey
 * @param {*} roadmap Message is displayed on roadmap
 * @returns
 */
export const itineraryHasOdt = (journey, roadmap = false) => {
  const { lines } = appStore.getState().app
  const hasOdt = journey.sections.filter(s => s?.additional_informations?.includes('odt_with_stop_time')).length > 0
  let fakeTraces = false
  let displayAsInfo = false

  if (hasOdt) {
    const linesOdt = journey.sections.reduce((acc, section) => {
      if (
        section?.additional_informations?.includes('odt_with_stop_time') &&
        !acc.includes(section.display_informations.code)
      ) {
        acc.push(section.display_informations.code)
      }

      return acc
    }, [])

    for (const line of linesOdt) {
      let sline = lines.find(l => l.code === line)
      if (sline?.tad?.zone && !sline?.tad?.hideMessageFakeTraces) {
        fakeTraces = true
      }
      if (sline?.tad?.displayAsInfo) {
        displayAsInfo = true
      }
    }

    return (
      <>
        <div
          className={
            'lc-journey-warning lc-journey-with-odt lc-itinerary' +
            (displayAsInfo ? ' info' : '') +
            (roadmap ? ' lc-in-roadmap' : '')
          }>
          <div className="lc-icon" />
          {translate('route-calculation-journey-with-odt', false, {
            key: 'list',
            value:
              linesOdt.length > 1
                ? translate('more-than-one-line', false, {
                    key: 'lines',
                    value: linesOdt.join(', '),
                  })
                : translate('one-line', false, {
                    key: 'line',
                    value: linesOdt[0],
                  }),
          })}
        </div>
        {fakeTraces && (
          <div className={'lc-journey-warning info lc-itinerary false-trace' + (roadmap ? ' lc-in-roadmap' : '')}>
            <div className="lc-icon" />
            {translate('route-calculation-false-trace')}
          </div>
        )}
      </>
    )
  }

  return null
}

// returns the most important groups
export const mostImportantGroup = (groups, modes) => {
  for (const mode of modes) {
    if (Object.keys(groups).includes(mode.name)) {
      return mode.name
    }
  }

  return null
}

/**
 * Add resize event and return a function to remove it
 * @returns {function(): void}
 */
export const addResizeEvent = isMobile => {
  const resizeListener = () => {
    resize(isMobile)
  }

  window.addEventListener('resize', resizeListener)
  return () => window.removeEventListener('resize', resizeListener)
}

/**
 * Resize the fckin panel
 */
export const resize = (isMobile, div) => {
  const { size, component } = appStore.getState().app
  const { options } = component.props
  const noBoard = document.querySelector('.lc-no-board')

  if (noBoard) {
    return
  }

  const { domElement } = appStore.getState().app
  const { headerHeight } = exportedSCSS
  const boundingRects = document.querySelector(domElement).getBoundingClientRect()
  const board = document.querySelector(`${domElement} .lc-board`) || document.querySelector(`${domElement}`)
  const toBeResized = div || document.querySelector("[data-lc-scroll='scroll']")

  if (!toBeResized) {
    return
  }

  if (isMobile || (!options?.config?.size && !size)) {
    toBeResized.style.maxHeight = 'inherit'
    return
  }

  const boundingRectsElement = toBeResized.getBoundingClientRect()

  const parentWithMargins =
    toBeResized.offsetParent &&
    Array.prototype.find.call(
      toBeResized.offsetParent?.children,
      child => child.classList.contains('lc-elevation') || child.classList.contains('lc-react-tabs'),
    )

  let height =
    boundingRects.height +
    parseInt(headerHeight) -
    (boundingRectsElement.y - boundingRects.y) -
    parseInt(getComputedStyle(board).marginTop) -
    parseInt(getComputedStyle(toBeResized).paddingTop) -
    parseInt(getComputedStyle(toBeResized).paddingBottom)

  if (parentWithMargins) {
    height -= parseInt(getComputedStyle(parentWithMargins).marginBottom)
  }

  // Ugly next schedules date fix on roadmap
  const nextSchedulesJourney =
    document.querySelector('.lc-lastest-nexts-schedules-date.journey') ||
    document.querySelector('.lc-lines-lasted-updated-schedules')

  if (nextSchedulesJourney) {
    height -=
      parseInt(getComputedStyle(nextSchedulesJourney).height) +
      parseInt(getComputedStyle(nextSchedulesJourney).paddingBottom)
  }

  toBeResized.style.maxHeight = height + 'px'
}

/**
 * Sort an array by a porperty and alphabetic order
 * @param array
 * @param property
 */
export const sortAlphabetic = (array, property) => {
  array.sort((a, b) => a[property].localeCompare(b[property]))
}

/**
 * Update the position of a popup, to be right near the marker icon
 * @param leafletElement
 */
export const updatePopupPosition = (leafletElement, data) => {
  // Retrieve the popup element & update its position
  const icon = leafletElement.getElement()
  const { markerMode, lineSelected } = appStore.getState().map
  const { lines } = appStore.getState().app

  // TODO Why there is a posibility to get icon null ???
  if (!icon) {
    return
  }

  const popup = leafletElement.getPopup()
  const element = popup.getElement()

  // Resort the lines by their position... Cause it's the more logical way to handle it :)
  if (data?.lines) {
    sortBy(data.lines, 'position')
  }

  // Set line color variable for CSS
  if (envVarToBool(REACT_APP_POPUP_AREA_COLOR_FIRST_LINE)) {
    if (element) {
      if (data?.lines?.length > 0) {
        const firstLine = getLine(lines, data.lines[0])

        if (firstLine?.color) {
          element.querySelector('.lc-infobox').style.setProperty('--lc-infobox-color', `#${firstLine?.color}`)
        }
      }
    }
  }

  if (
    envVarToBool(REACT_APP_STOP_POPUP_COLOR_LINE) &&
    lineSelected &&
    data?.lines?.find(l => l.id === lineSelected.id)
  ) {
    element.querySelector('.lc-infobox').style.setProperty('--lc-infobox-color', `#${lineSelected.color}`)
    element
      .querySelector('.lc-infobox')
      .style.setProperty('--lc-infobox-text-color', luminance(lineSelected.color) > 0.5 ? '#333' : '#fff')
  }

  const styleOfIcon = window.getComputedStyle(icon)
  const height = parseInt(styleOfIcon.getPropertyValue('height'))
  const marginTop = Math.abs(parseInt(styleOfIcon.getPropertyValue('margin-top')))

  if (envVarToBool(REACT_APP_ALL_POPUP_ON_TOP)) {
    setTimeout(() => {
      if (element && element.querySelector('.lc-infobox')) {
        element
          .querySelector('.lc-infobox')
          .style.setProperty('--lc-tooltip-arrow-top', element?.getBoundingClientRect()?.height - 1 + 'px')
      }
    }, 100)

    if (icon.classList.contains('lc-stop-marker') && marginTop !== height / 2) {
      const diffOffset = marginTop - height / 2

      popup.options.offset = new L.Point(0, (5 + diffOffset) * -1)
    } else {
      popup.options.offset = new L.Point(
        0,
        data?.divIcon
          ? markerMode === 'default'
            ? -parseInt(icon.querySelector('img')?.height) + 10
            : icon.offsetHeight + 5
          : icon.querySelector('img') || icon.tagName.toLowerCase() === 'img'
          ? -5
          : 5,
      )
    }
  } else {
    if (data?.divIcon && element) {
      element.querySelector('.lc-infobox').style.setProperty('--lc-tooltip-arrow-top', element.offsetHeight - 1 + 'px')
    }

    // if stop marker margin is override
    const diffOffset = marginTop !== height / 2 ? (marginTop - height / 2) * -1 : 0
    // icon.classList.contains("lc-stop-marker") &&
    // Jérémy 08/02/2023 : pourquoi seulement les lc-stop-marker ?
    let iconOffsetWidth = icon.offsetWidth

    if (iconOffsetWidth === undefined && icon.getBBox() !== undefined) {
      iconOffsetWidth = icon.getBBox().width
    }

    popup.options.offset = new L.Point(
      !data?.divIcon ? element?.offsetWidth / 2 + iconOffsetWidth / 2 + 17 : 0,
      !data?.divIcon
        ? element?.offsetHeight - 2.5 + diffOffset
        : markerMode === 'default'
        ? -parseInt(icon.querySelector('img')?.height) + 10
        : icon.offsetHeight + 5,
    )
  }

  setTimeout(() => {
    popup.update()
  })
}

/**
 * Limit api call
 * @param func
 * @param wait
 * @param immediate
 * @returns {Function}
 */
export const debounce = (func, wait, immediate) => {
  let timeout

  return function () {
    const later = () => {
      timeout = null

      if (!immediate) {
        func.apply(this, arguments)
      }
    }

    const callNow = immediate && !timeout

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)

    if (callNow) {
      func.apply(this, arguments)
    }
  }
}

/**
 * Launch the request debounced
 */
const debounceRequest = debounce((inputValue, type, state) => {
  const { pathname } = history.location
  const { component, configApp, isMobile, places } = state.app
  const { disableAutocompleteGeolocation } = component.props
  let fileForThematics = null

  if (component?.props?.thematicPlaces) {
    fileForThematics = component.props.moduleData.file
  }

  if (inputValue.length === 0) {
    initInputAddresses(component)
  } else {
    const params = {
      params: {
        type,
        query: inputValue,
        file: fileForThematics,
      },
    }

    if (state.tourismPartners) {
      params.params.typePartner = state.tourismPartners.typePartner
    }

    if (component?.props?.searchIn) {
      params.params.searchIn = component.props.searchIn
    }

    axios
      .get('/api/autocomplete', params)
      .then(result => {
        const geolocInput = [
          {
            id: 'geoloc',
            name: translate('autocomplete-geoloc'),
            geolocation: true,
          },
        ]

        // TODO Finish it for modules
        let autocompleteResults = result.data
        const results = []

        for (const r of result.data) {
          if (r.isPartner) {
            results.push(r)
          } else if (r.embedded_type === 'stations') {
            const station = state.app.stations.find(s => s.id === r.id)

            if (station) {
              results.push(r)
            }
          } else if (r.embedded_type === 'stop_area' || r?.id?.includes('stop_area:')) {
            if (state.app?.stopsNetwork?.length) {
              results.push(r)
            } else {
              const stopArea = state.app.areas.find(a => a.id === r.id)

              if (stopArea || (isActiveModule('route-calculation') && configApp?.autocomplete_areas_not_generated)) {
                results.push(r)
              }
            }
          } else if (r?.id?.includes('line:')) {
            results.push(r)
          } else {
            if (!r.embedded_type && r?.id !== 'no_result' && r.merge === true) {
              results.push({
                embedded_type: r?.id?.includes('aeroway') ? 'aeroway' : 'poi',
                addByMerge: true,
                id: r.id,
                name: r.label || r.name,
                poi: r,
              })
            } else if (r.addToPlaces && r.embedded_type === 'poi') {
              const findPlace = places.find(p => p.id === r.id)
              if (!findPlace) {
                results.push(r)
                appStore.dispatch(actionAddPlace(r.poi))
              }
            } else {
              results.push(r)
            }
          }
        }

        autocompleteResults = results

        let resultToDispatch = displayGeolocationEntry(configApp, disableAutocompleteGeolocation, isMobile)
          ? geolocInput.concat(autocompleteResults)
          : autocompleteResults

        resultToDispatch = resultToDispatch.map((item, index) => {
          return { ...item, index: index }
        })

        if (
          pathname.includes('/places-interest') ||
          pathname.includes('/lines') ||
          state.board.thematicPlaces ||
          pathname.includes('/towns')
        ) {
          resultToDispatch = resultToDispatch.filter(a => a.id !== 'geoloc')
        }

        if (!pathname.includes('/route-calculation')) {
          appStore.dispatch(actionInputItemsChange(resultToDispatch))
        } else {
          if (type === 'inputStart') {
            appStore.dispatch(actionInputStartItemsChange(resultToDispatch))
          } else {
            appStore.dispatch(actionInputEndItemsChange(resultToDispatch))
          }
        }
      })
      .catch(e => {
        const error = e.response && e.response.data ? e.response.data.id : e

        console.warn(error)
      })
  }
}, 500)

/**
 * Display the disruptions datetime well formatted
 * @param {String} begin Navitia datetime of the disruption's beginning
 * @param {String} end Navitia datatime of the disruption's end
 */
export const disruptionsDatetime = (begin, end, language) => {
  const today = formatDate(new Date(), 'ymd')
  const beginDate = navitiaDateToDate(begin)
  const endDate = navitiaDateToDate(end)
  const beginFormatDate = formatDate(beginDate, isSystemUS(language) ? 'm/d/y' : 'd/m/y')
  const endFormatDate = formatDate(endDate, isSystemUS(language) ? 'MM/DD/YYYY' : 'DD/MM/YYYY')
  const beginFormatHours = formatDate(beginDate, isSystemUS(language) ? 'h:m a' : 'h:m')
  const endFormatHours = formatDate(endDate, isSystemUS(language) ? 'h:m a' : 'h:m')

  if (beginDate === today && endDate === today) {
    switch (language) {
      case 'en':
        return 'Today from ' + beginFormatHours + ' to ' + endFormatHours
      default:
        return "Aujourd'hui de " + beginFormatHours + ' à ' + endFormatHours
    }
  } else if (beginDate === today) {
    switch (language) {
      case 'en':
        if (endDate) {
          return 'Today at ' + beginFormatHours + ' to ' + endFormatDate + ' at ' + endFormatHours
        } else {
          return 'Today at ' + beginFormatHours
        }
      default:
        if (endDate) {
          return "Aujourd'hui à " + beginFormatHours + ' au ' + endFormatDate + ' à ' + endFormatHours
        } else {
          return "Aujourd'hui à" + beginFormatHours
        }
    }
  } else if (endDate === today) {
    switch (language) {
      case 'en':
        return 'From ' + beginFormatDate + ' at ' + beginFormatHours + ' until today at ' + endFormatHours
      default:
        return 'Du ' + beginFormatDate + ' à ' + beginFormatHours + " jusqu'à aujourd'hui à " + endFormatHours
    }
  } else if (beginDate === endDate) {
    switch (language) {
      case 'en':
        if (endDate) {
          return beginFormatDate + ' from ' + beginFormatHours + ' to ' + endFormatHours
        } else {
          return beginFormatDate + ' from ' + beginFormatHours
        }
      default:
        if (endDate) {
          return 'Le ' + beginFormatDate + ' de ' + beginFormatHours + ' à ' + endFormatHours
        } else {
          return 'Le ' + beginFormatDate + ' à partir de ' + beginFormatHours
        }
    }
  } else {
    switch (language) {
      case 'en':
        if (endDate) {
          return (
            'From ' + beginFormatDate + ' at ' + beginFormatHours + ' to ' + endFormatDate + ' at ' + endFormatHours
          )
        } else {
          return 'From ' + beginFormatDate + ' at ' + beginFormatHours
        }
      default:
        if (endDate) {
          return 'Du ' + beginFormatDate + ' à ' + beginFormatHours + ' au ' + endFormatDate + ' à ' + endFormatHours
        } else {
          return 'A partir du ' + beginFormatDate + ' à ' + beginFormatHours
        }
    }
  }
}

/**
 * Init input for autocomplete with geoloc object and history
 */
export const initInputAddresses = async component => {
  const { pathname } = history.location
  const params = getURLSearchParams(history.location)
  const { disableAutocompleteGeolocation, token, clientId } = component.props
  const { configApp, isMobile, lines } = appStore.getState().app

  const geolocInput = [
    {
      id: 'geoloc',
      name: translate('autocomplete-geoloc'),
      geolocation: true,
    },
  ]

  const historyRecovered = storageAvailable('localStorage')
    ? JSON.parse(window.localStorage.getItem(`history_${REACT_APP_PROJECT}`)) || []
    : []

  const historyStored = []

  for (const historicItem of historyRecovered) {
    const type = historicItem.type

    switch (type) {
      case 'line':
      case 'poi':
      case 'stop_area': {
        const file = type === 'line' ? 'lines' : type === 'poi' ? 'places' : 'areas'
        const findItem = component?.props[file]?.find(i => i.id === historicItem.item_id)

        if (findItem) {
          if (file === 'lines' && findItem.routes.length) {
            historyStored.push(historicItem)
          } else if (file !== 'lines') {
            historyStored.push(historicItem)
          }
        }

        break
      }
      default:
        historyStored.push(historicItem)
    }
  }

  const favoritesNotOrder = []
  const favorites = []

  if (REACT_APP_FAVORITES && token) {
    try {
      const favReq = await axios.get(`/api/favorites?token=${token}${clientId ? '&client_id=' + clientId : ''}`)

      if (favReq && favReq.data) {
        for (const fav of favReq.data) {
          const type = fav?.id?.startsWith('line:') ? 'line' : 'stop_area'

          if (!favoritesNotOrder.find(f => f.id === `favorite-${fav.id}`)) {
            favoritesNotOrder.push({
              id: `favorite-${fav.id}`,
              item_id: fav.id,
              code: type === 'line' && lines ? lines.find(l => l.id === fav.id)?.code : null,
              type: type,
              name: fav.name,
              favorite: true,
            })
          }
        }
      }

      JSON.parse(REACT_APP_FAVORITES)?.stops &&
        favorites.push(...favoritesNotOrder.filter(f => f.type.includes('stop')))

      JSON.parse(REACT_APP_FAVORITES)?.lines && favorites.push(...favoritesNotOrder.filter(f => f.type === 'line'))
    } catch (error) {
      console.warn(error)
    }
  }

  const init = []

  if (pathname.includes('/lines')) {
    init.push(...historyStored.filter(h => h.type === 'stop_area' || h.type === 'line'))
    init.push(...favorites.filter(f => f.type === 'stop_area' || f.type === 'line'))
  } else if (isActiveModule('around') && !pathname.includes('/company')) {
    displayGeolocationEntry(configApp, disableAutocompleteGeolocation, isMobile) && init.push(...geolocInput)
    init.push(...historyStored)
    init.push(...favorites)
  } else if (pathname.includes('/route-calculation')) {
    displayGeolocationEntry(configApp, disableAutocompleteGeolocation, isMobile) && init.push(...geolocInput)
    init.push(...historyStored.filter(h => h.type !== 'line'))
    init.push(...favorites.filter(f => f.type !== 'line'))
  } else if (pathname.includes('/station')) {
    init.push(...historyStored.filter(h => h.type === 'stop_area'))
  }

  if (!pathname.includes('/route-calculation')) {
    appStore.dispatch(actionInputItemsChange(init))
  } else {
    if (!params.from) {
      appStore.dispatch(actionInputStartItemsChange(init))
    }

    if (!params.to) {
      appStore.dispatch(actionInputEndItemsChange(init))
    }
  }
}

export const focusInput = (e, inputProps, state) => {
  const input = e.target

  // Remove previous geolocation errors
  appStore.dispatch(actionSetGeolocationError(null))

  if (state.app.isMobile) {
    input.blur()
    appStore.dispatch(batch(initModal(inputProps), toggleModal()))
  }
}

/**
 * While user using an autocomplete input :
 * remove the pin // TODO
 * display chars in input
 * launch debounce function
 * @param event
 * @param type
 * @param state
 */
export const onChangeAutocompleteInput = (event, type, state) => {
  const inputValue = event.target.value
  const { pathname } = history.location

  appStore.dispatch(actionGoToValid(false))
  // TODO remove marker around/start/end

  if (pathname.includes('/route-calculation')) {
    const params = getURLSearchParams(history.location)

    if (type === 'inputStart') {
      if (inputValue.length === 0 && params.from) {
        if (params.to) {
          history.push({
            pathname,
            search: '?to=' + params.to,
          })
        } else {
          history.push({
            pathname,
          })
        }
      } else {
        appStore.dispatch(actionInputStartValueChange({ name: inputValue }))
      }
    } else {
      if (inputValue.length === 0 && params.to) {
        if (params.from) {
          history.push({
            pathname,
            search: '?from=' + params.from,
          })
        } else {
          history.push({
            pathname,
          })
        }
      } else {
        appStore.dispatch(actionInputEndValueChange({ name: inputValue }))
      }
    }
  } else {
    appStore.dispatch(actionInputValueChange(inputValue))

    if (inputValue.length === 0 && type !== 'inputTourismPartners') {
      appStore.dispatch(actionOpenMarker(null))
      history.push({
        pathname,
      })
    }
  }

  debounceRequest(inputValue, type, state)
}

/**
 * Return position geolocated
 * @param options
 * @returns {Promise<any>}
 */
const getCurrentPosition = (options = {}) => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  })
}

/**
 * Get the item selected by the user and adapt input about it
 * @param valueSelected
 * @param itemSelected
 * @param type
 * @param state
 * @param isModal
 */
export const onSelectAutocompleteValue = async (valueSelected, itemSelected, type, state, isModal) => {
  const { component, lines, stops, areas, touchscreenSelected, modules } = state.app
  const { pathname } = history.location
  const params = getURLSearchParams(history.location)

  // Add selected item in history
  if (!itemSelected.geolocation && !itemSelected.history && !itemSelected.region && storageAvailable('localStorage')) {
    if (
      !itemSelected.id.startsWith('stop_area') ||
      (itemSelected.id.startsWith('stop_area') && areas.find(a => a.id === itemSelected.id))
    ) {
      addHistoricItem(itemSelected)
    }
  }

  if (pathname.includes('/lines')) {
    if (itemSelected.id.includes('stop_area')) {
      component.onStopSelected(areas.find(a => a.id === itemSelected.item_id || a.id === itemSelected.id))
      appStore.dispatch(actionGoToValid(true))
    } else if (itemSelected.id.includes('stop_point')) {
      itemSelected.item_id = stops.filter(s => s.id === itemSelected.item_id)[0].stop_area
      component.onStopSelected(areas.find(a => a.id === itemSelected.item_id))
      appStore.dispatch(actionGoToValid(true))
    } else {
      component.onLineSelected(lines.find(l => l.id === itemSelected.item_id || l.id === itemSelected.id))
    }
  } else if (isActiveModule('around') || pathname === '/') {
    let searchAround = '?from='

    if (
      itemSelected.id.includes('poi:') &&
      component.props.itemCoordsIfNotFound === true &&
      !component.props.statePlaces.find(place => place.id === itemSelected.id) &&
      itemSelected.poi &&
      itemSelected.poi.coord
    ) {
      searchAround = searchAround + itemSelected.poi.coord.lon + ';' + itemSelected.poi.coord.lat
      setTimeout(() => {
        appStore.dispatch(actionInputValueChange(itemSelected.name))
      }, 500)
    } else if (itemSelected.geolocation) {
      if (touchscreenSelected) {
        searchAround += touchscreenSelected.coords
      } else {
        try {
          const position = await getCurrentPosition({
            timeout: 5000,
            enableHighAccuracy: true,
          })

          const { longitude, latitude } = position.coords

          searchAround += longitude + ';' + latitude
        } catch (e) {
          // Toggle the modal if we are on mobile
          isModal && appStore.dispatch(toggleModal())
          // Remove the focus of the current active element
          document.activeElement.blur()
          // Store the current geolocation error
          appStore.dispatch(actionSetGeolocationError(e))
          throw e
        }
      }
    } else if (itemSelected.history || itemSelected.favorite) {
      searchAround = !itemSelected.item_id.includes('line:')
        ? searchAround + itemSelected.item_id
        : '?line=' + itemSelected.item_id
    } else {
      searchAround = !itemSelected.id.includes('line:') ? searchAround + itemSelected.id : '?line=' + itemSelected.id
    }

    if (!searchAround.includes('line:') || !searchAround.includes('admin:fr')) {
      appStore.dispatch(actionGoToValid(true))
    }

    // module aorund-in-board
    if (pathname === '/') {
      history.push({ pathname: '/around', search: searchAround + '&back=board' })
    } else {
      history.push({ pathname, search: searchAround })
    }
  } else if (pathname.includes('/route-calculation')) {
    let searchRouteCalc = ''

    if (itemSelected.geolocation) {
      if (touchscreenSelected) {
        searchRouteCalc += touchscreenSelected.coords
      } else {
        try {
          const position = await getCurrentPosition({
            timeout: 5000,
            enableHighAccuracy: true,
          })

          const { longitude, latitude } = position.coords

          searchRouteCalc = longitude + ';' + latitude
        } catch (e) {
          // Toggle the modal if we are on mobile
          isModal && appStore.dispatch(toggleModal())
          // Remove the focus of the current active element
          document.activeElement.blur()
          // Store the current geolocation error
          appStore.dispatch(actionSetGeolocationError(e))
          throw e
        }
      }
    } else if (itemSelected.history || itemSelected.favorite) {
      // TODO need around to get a stop_point
      searchRouteCalc = itemSelected.item_id
    } else {
      searchRouteCalc = itemSelected.id
    }

    if (type === 'inputStart') {
      if (!params.to) {
        history.push({ pathname, search: '?from=' + searchRouteCalc })
      } else {
        history.push({
          pathname,
          search: '?from=' + searchRouteCalc + '&to=' + params.to,
        })
      }
    } else if (type === 'inputEnd') {
      if (!params.from) {
        history.push({ pathname, search: '?to=' + searchRouteCalc })
      } else {
        history.push({
          pathname,
          search: '?from=' + params.from + '&to=' + searchRouteCalc,
        })
      }
    }
  } else if (pathname.includes('/network-lines') || pathname.includes('/new-network-tabs')) {
    let tabToGo = 0

    if (itemSelected.id.includes('stop_')) {
      updateDataLayer({
        event: 'map-openNetworkItem',
        type: 'Stop',
        name: itemSelected.name,
        from: 'Autocompletion',
      })
      tabToGo =
        modules
          .find(module => ['network-lines', 'new-network-tabs'].includes(module.id))
          .tabs.find(tab => ['network-map', 'new-network-lines'].includes(tab.id)).position - 1

      const searchParam = addGetParam(params, {
        stop_area: itemSelected.id,
        tab: tabToGo,
      })

      history.push({
        pathname,
        search: searchParam,
      })
    } else if (itemSelected.id.includes('line:')) {
      updateDataLayer({
        event: 'map-openNetworkItem',
        type: 'Ligne',
        name: itemSelected.name,
        from: 'Autocompletion',
      })
      tabToGo =
        modules
          .find(module => ['network-lines', 'new-network-tabs'].includes(module.id))
          .tabs.find(tab => ['network-map', 'new-network-lines'].includes(tab.id)).position - 1
      const searchParam = addGetParam(params, { line: itemSelected.id, tab: tabToGo })

      history.push({
        pathname,
        search: searchParam,
      })
    } else if (itemSelected.id.includes('town:')) {
      updateDataLayer({
        event: 'map-openNetworkItem',
        type: 'Ville',
        name: itemSelected.name,
        from: 'Autocompletion',
      })
      tabToGo =
        modules
          .find(module => ['network-lines', 'new-network-tabs'].includes(module.id))
          .tabs.find(tab => ['network-towns', 'new-network-towns'].includes(tab.id)).position - 1

      const searchParam = addGetParam(params, { town: itemSelected.insee, tab: tabToGo })

      history.push({
        pathname,
        search: searchParam,
      })
    } else {
      return
    }
  } else if (pathname.includes('/partners') || pathname.includes('/tourism-partners')) {
    history.push({ pathname, search: `?type=${itemSelected.type}&partner=${itemSelected.id}` })
  } else if (state.board.thematicPlaces) {
    // TODO REVIEW
    history.push({
      pathname,
      search: '?place=' + itemSelected.id.replace(/history-|favori-/g, ''),
    })
  }

  if (type === 'inputStart') {
    appStore.dispatch(actionInputStartValueChange({ name: valueSelected }))
  } else if (type === 'inputEnd') {
    appStore.dispatch(actionInputEndValueChange({ name: valueSelected }))
  } else {
    appStore.dispatch(actionInputValueChange(valueSelected))
  }

  // Toggle the modal if we are on mobile
  isModal && appStore.dispatch(toggleModal())
}

/**
 * add item in historic
 * @param item
 */
export const addHistoricItem = item => {
  const historyStored = JSON.parse(window.localStorage.getItem(`history_${REACT_APP_PROJECT}`)) || []

  historyStored.reverse()

  if (historyStored.filter(p => p.item_id === item.id).length === 0) {
    if (item.id.includes('poi:') && item.embedded_type === 'company') {
      return
    } else if (item.id.startsWith('line:')) {
      if (item.routes?.length) {
        historyStored.push({
          id: 'history-' + item.id,
          type: 'line',
          name: item.name,
          mode: item.mode,
          color: item.color,
          item_id: item.id,
          code: item.code,
          history: true,
        })
      } else {
        console.warn("don't add line without routes into history")
      }
    } else if (item.id.includes('stop_area:')) {
      historyStored.push({
        id: 'history-' + item.id,
        type: 'stop_area',
        name: item.name,
        item_id: item.id,
        history: true,
      })
    } else if (item.address) {
      historyStored.push({
        id: 'history-' + item.id,
        type: 'address',
        name: item.name,
        item_id: item.id,
        history: true,
      })
    } else if (item.id.includes('admin:')) {
      historyStored.push({
        id: 'history-' + item.id,
        type: 'administrative_region',
        name: item.name,
        item_id: item.id,
        coord: item.administrative_region.coord,
        history: true,
      })
    }
  }

  historyStored.reverse()

  if (historyStored.length > 3) {
    historyStored.length = 3
  }

  window.localStorage.setItem(`history_${REACT_APP_PROJECT}`, JSON.stringify(historyStored))
}

/**
 * add || remove like on item
 * @param item
 */
export const likeItem = item => {
  let likeStored = JSON.parse(window.localStorage.getItem(`like_${REACT_APP_PROJECT}`)) || []
  let isLiked = -1

  if (likeStored.find(i => i === item.id)) {
    likeStored = likeStored.filter(i => i !== item.id)
    // api
  } else {
    likeStored.push(item.id)
    // api
    isLiked = 1
  }

  axios
    .get(`/api/likes?id=${item.id}&value=${isLiked === 1 ? 'add' : 'remove'}`)
    .then(response => {
      const likes = response.data.likes

      appStore.dispatch(actionSetTourismPartnerSelectedPartner({ ...item, isLiked, likes }))
      window.localStorage.setItem(`like_${REACT_APP_PROJECT}`, JSON.stringify(likeStored))
    })
    .catch(e => {
      const error = e.response && e.response.data ? e.response.data.id : e

      console.warn(error)
    })
}

/**
 * get item is liked
 * @param item
 */
export const isItemLiked = async item => {
  let likeStored = JSON.parse(window.localStorage.getItem(`like_${REACT_APP_PROJECT}`)) || []
  let isLiked = -1
  let likes = 0

  if (likeStored.find(i => i === item.id)) {
    isLiked = 1
  }

  await axios
    .get(`/api/likes?id=${item.id}`)
    .then(response => {
      likes = response.data.likes
    })
    .catch(e => {
      console.log(e)
      const error = e.response && e.response.data ? e.response.data.id : e

      console.warn(error)
    })

  appStore.dispatch(actionSetTourismPartnerSelectedPartner({ ...item, isLiked, likes }))
}

export const goToAround = item => {
  appStore.dispatch(actionSetTransportPlaces([]))
  appStore.dispatch(actionSetPublicPlaces([]))

  setTimeout(() => {
    const url = `/around?from=${item.id.startsWith('stop_') ? item.id : item.coord.lon + ';' + item.coord.lat}`

    history.push(url)
  })
}

export const goToStopPoint = item => {
  setTimeout(() => {
    const url = `/lines?${item.id.startsWith('stop_area') ? 'stop_area' : 'stop'}=${item.id}`

    history.push(url)
  })
}

export const goToRouteCalculation = item => {
  // This var should only be present in library ! So we can history.push
  if (REACT_APP_GO_TO_RC_URL) {
    const { places, domElement } = appStore.getState().app
    let type = null
    let to = null

    if (typeof item.address === 'object') {
      type = 'address'
      to = `${item.address.lon};${item.address.lat}`
    } else if (item.id) {
      if (String(item.id).includes('stop_area:')) {
        type = 'stop_area'
        to = item.id
      } else if (String(item.id).includes('stop_point:')) {
        type = 'stop_point'
        to = item.id
      } else if (String(item.id).includes('poi:') || item.coord) {
        type = 'address'
        to = `${item.coord.lon};${item.coord.lat}`
      }
    } else {
      if (item.includes('stop_:')) {
        type = item.includes('stop_area:') ? 'stop_area' : 'stop_point'
        to = `${item}`
      } else if (item.includes('poi:')) {
        const place = places.find(p => p.id === item)

        if (place) {
          type = 'address'
          to = `${place.coord.lon};${place.coord.lat}`
        }
      }
    }

    if (type && to) {
      // Create a link to the new URL
      const a = document.createElement('a')

      a.href = `${REACT_APP_GO_TO_RC_URL}?to_type=${type}&to=${to}`
      document.querySelector(domElement).appendChild(a)
      a.click()
    }
  } else {
    appStore.dispatch(actionSetTransportPlaces([]))
    appStore.dispatch(actionSetPublicPlaces([]))

    const fromto = envVarToBool(REACT_APP_GO_TO_RC_INVERT_TO_FROM) ? 'from' : 'to'

    setTimeout(() => {
      const url = `/route-calculation?${fromto}=${
        item.cat_id === 'poi_type:stations'
          ? 'sncf_' + item.id
          : item instanceof BikeInterface
          ? item.coord.lon + ';' + item.coord.lat
          : item.id
          ? item.id
          : item.address
          ? item.address.lon + ';' + item.address.lat
          : item
      }`

      history.push(url)
    })
  }
}

/**
 * Remove duplicates entries of an Array by a specifiq property
 * @param array
 * @param property
 * @returns Array
 */
export const unique = (array, property = false) => {
  if (property) {
    return array.filter((e, i) => array.findIndex(a => a[property] === e[property]) === i)
  } else {
    return array.filter((value, index, array) => array.indexOf(value) === index)
  }
}

/**
 * Get data from poi selection in list
 * @param place
 * @param token
 * @returns {Promise<void>}
 */
export const clickOnPlaceInList = async (place, pois = null, thematic = null) => {
  const needRequest = [
    'poi_type:amenity:bicycle_rental',
    'poi_type:amenity:bicycle_parking',
    'poi_type:amenity:parking',
    'poi_type:amenity:car_rental',
    'poi_type:amenity:citiz',
    'poi_type:stations',
  ]

  appStore.dispatch(actionResetStationIndex())
  appStore.dispatch(actionSetPlaceClicked(null))

  const scrollToElement = () => {
    // Scrollto element
    setTimeout(() => {
      const scroll = document.querySelector("[data-lc-scroll='scroll']")
      const placeInfos = document.querySelector('.lc-board [data-lc-place-infos]')

      // Avoid crash if there is no scroll element
      scroll && scroll.scrollTo(0, 0)

      if (scroll && placeInfos) {
        const scrollRect = scroll.getBoundingClientRect()
        const placeRect = placeInfos.getBoundingClientRect()

        scroll.scrollTo(0, placeRect.top - scrollRect.top - 40)
      }
    }, 500)
  }

  if (place && needRequest.includes(place.cat_id)) {
    if (place.cat_id !== 'poi_type:stations') {
      const type = place.cat_id.includes('bicycle_rental')
        ? 'bss'
        : place.cat_id.includes('bicycle_parking')
        ? 'bike_parking'
        : place.cat_id.includes('car_rental')
        ? 'car_rental'
        : place.cat_id.includes('citiz')
        ? 'citiz'
        : 'parking'

      axios
        .get(`/api/availability?type=${type}&id=${place.id}`)
        .then(result => {
          place.stand = result.data
        })
        .catch(e => {
          place.stand = {}
          const error = e.response && e.response.data ? e.response.data.id : e

          console.warn(error)
        })
        .finally(() => {
          appStore.dispatch(actionSetPlaceClicked(place))

          if ((!place.cat_id.includes('car_rental') || !place.cat_id.includes('citiz')) && pois && pois.length > 0) {
            appStore.dispatch(actionBuildTransportPlaces(pois))
          }

          if (place.ref) {
            setTimeout(() => {
              const element = place.ref.leafletElement

              updatePopupPosition(element, place)
            })
          }

          scrollToElement()
        })
    } else {
      appStore.dispatch(actionOpenMarker(place))
      axios
        .get(`/api/stations?id=${place.id}`)
        .then(result => {
          place.stand = result.data
        })
        .catch(e => {
          place.stand = {}
          const error = e.response && e.response.data ? e.response.data.id : e

          console.warn(error)
        })
        .finally(() => {
          appStore.dispatch(actionSetPlaceClicked(place))
          scrollToElement()
        })
    }
  } else {
    // TODO Avoid duplicate with the else case
    appStore.dispatch(actionSetPlaceClicked(place))
  }

  scrollToElement()

  if (thematic) {
    appStore.dispatch(actionGoToValid(true))
    appStore.dispatch(actionInputValueChange(place.name))
  }
}

/**
 * Create a google coord
 * @param lat
 * @param lng
 */
export const createCoords = (lat, lng) => {
  return [lat, lng]
}

/**
 * Return if value is real coordinates
 * @param coord
 * @returns {boolean}
 */
export const isCoords = coord => {
  const lon = coord.split(';')[0]
  const lat = coord.split(';')[1]

  return !!(!isNaN(lon) && isBetween(lon, -180, 180) && !isNaN(lat) && isBetween(lat, -90, 90))
}

/**
 * Try if a place should't be clusterised
 * @param place
 * @returns {boolean}
 */
export const isNotToClusterised = place => {
  return (
    place.cat_id === 'poi_type:amenity:bicycle_rental' ||
    place.cat_id === 'poi_type:stations' || // No cluster for VELO'V OR stations
    place.cat_id === 'poi_type:amenity:park_ride' || // No cluster for park_ride #annecy
    place.cat_id === 'poi_type:pois_background' || // No cluster for pois_background
    place.cat_id === 'poi_type:entrance' || // No cluster for park_ride #annecy
    (history.location.pathname.includes('/p+r') &&
      (place.cat_id === 'poi_type:amenity:parking' || place.cat_id === 'poi_type:amenity:bicycle_parking'))
  ) // No cluster for p+r in thematics
}

export const isNotPlacesTabAround = places => {
  return isActiveModule('around') && places?.length > 0
}

/**
 * Calculate real position of an HTMLElement depending how deep it is in the tree
 * @param {HTMLElement} element HTML element which to find real position
 * @returns {Object} Position top & left
 */
export function getAbsolutePosition(element) {
  let other = element
  let top = 0
  let left = 0

  do {
    left += element.offsetLeft - element.scrollLeft
    top += element.offsetTop - element.scrollTop
    element = element.offsetParent
    other = other.parentNode

    while (other !== element) {
      left -= other.scrollLeft
      top -= other.scrollTop
      other = other.parentNode
    }
  } while (element.offsetParent)

  return { top, left }
}

/**
 *
 * @param object
 * @param type
 * @returns {Promise<*>}
 */
// TODO cf version leaflet for route-calculation
export const getCoordsFromUrlObject = async (object, type = null, component) => {
  const { pathname } = history.location

  if (object.includes('stop_') || object.includes('poi:')) {
    const name = object.includes('stop_area')
      ? 'areas'
      : object.includes('stop_point')
      ? 'stops'
      : object.includes('sncf_stop_area')
      ? 'stations'
      : 'places'

    let findObj = component.props[name].find(i => i.id === object)

    if (!findObj && object.includes('stop_area') && component.props['stations']) {
      findObj = component.props['stations'].find(i => i.id === object || `sncf_${i.id}` === object)
    }

    if (findObj) {
      if (pathname.includes('/route-calculation')) {
        if (type === 'inputStart') {
          appStore.dispatch(actionInputStartValueChange(findObj))
        } else if (type === 'inputEnd') {
          appStore.dispatch(actionInputEndValueChange(findObj))
        }

        component.createMarker(createCoords(findObj.coord.lat, findObj.coord.lon), type)
      } else {
        appStore.dispatch(actionGoToValid(true))
        appStore.dispatch(actionInputValueChange(findObj.name))
        return createCoords(findObj.coord.lat, findObj.coord.lon)
      }
    } else {
      const reponseGetPlace = await axios(`/api/get-place?id=${object}`)
      if (reponseGetPlace.data?.id) {
        findObj = reponseGetPlace.data
        if (object.startsWith('stop_area:')) {
          appStore.dispatch(actionAddStopArea(findObj))
        } else if (object.startsWith('poi:')) {
          appStore.dispatch(actionAddPlace(findObj))
        } else {
          throw new Error(`${object} not found`)
        }
        if (pathname.includes('/route-calculation')) {
          if (type === 'inputStart') {
            appStore.dispatch(actionInputStartValueChange(findObj))
          } else if (type === 'inputEnd') {
            appStore.dispatch(actionInputEndValueChange(findObj))
          }

          component.createMarker(createCoords(findObj.coord.lat, findObj.coord.lon), type)
        } else {
          appStore.dispatch(actionGoToValid(true))
          appStore.dispatch(actionInputValueChange(findObj.name))
          return createCoords(findObj.coord.lat, findObj.coord.lon)
        }
      } else {
        if (['inputStart', 'inputEnd'].includes(type)) {
          component.setState({
            warning:
              type === 'inputStart'
                ? translate('route-calculation-no-departure')
                : translate('route-calculation-no-arrival'),
          })
        }

        throw new Error(`${object} not found`)
      }
    }
  } else if (object.includes('admin:')) {
    const adminRegion = component.props.adminRegions.find(admin => admin.id === object)

    if (adminRegion && pathname.includes('/route-calculation')) {
      if (type === 'inputStart') {
        appStore.dispatch(actionInputStartValueChange(adminRegion))
      } else if (type === 'inputEnd') {
        appStore.dispatch(actionInputEndValueChange(adminRegion))
      }

      component.createMarker(createCoords(adminRegion.coord.lat, adminRegion.coord.lon), type)
    } else {
      if (['inputStart', 'inputEnd'].includes(type)) {
        component.setState({
          warning:
            type === 'inputStart'
              ? translate('route-calculation-no-departure')
              : translate('route-calculation-no-arrival'),
        })
      }

      throw new Error(`${object} not found`)
    }
  } else {
    if (isCoords(object)) {
      const lat = object.split(';')[1]
      const lng = object.split(';')[0]
      let apiResponse = null

      await axios
        .get('/api/geocoding', {
          params: {
            lat: lat,
            lng: lng,
          },
        })
        .then(response => {
          apiResponse = response
        })
        .catch(e => {
          console.warn('Error : ', e.response && e.response.data && e.response.data.id)
          component.setState({
            noErrorIcon: false,
            error:
              e.response && e.response.data.id === 'no-places'
                ? translate('around-error-no-places')
                : translate('around-error-unknow'),
          })
        })
        .finally(() => {
          if (pathname.includes('/route-calculation')) {
            if (type === 'inputStart' || type === 'inputEnd') {
              // set id object with lat:lng of what is picked on the map to not call this function again
              // inputStart|EndObj.id !== params.from|to
              if (apiResponse) {
                apiResponse.data[0].id = object
              }
            }

            if (type === 'inputStart') {
              appStore.dispatch(actionInputStartValueChange(apiResponse ? apiResponse.data[0] : { name: '' }))
            } else if (type === 'inputEnd') {
              appStore.dispatch(actionInputEndValueChange(apiResponse ? apiResponse.data[0] : { name: '' }))
            }

            component.createMarker(
              createCoords(
                apiResponse ? apiResponse.data[0].address.coord.lat : lat,
                apiResponse ? apiResponse.data[0].address.coord.lon : lng,
              ),
              type,
            )
          } else {
            appStore.dispatch(actionGoToValid(true))
            appStore.dispatch(
              actionInputValueChange(apiResponse.data && apiResponse.data.length > 0 ? apiResponse.data[0].name : ''),
            )
          }
        })
      return createCoords(lat, lng)
    } else {
      appStore.dispatch(actionGoToValid(false))
      appStore.dispatch(actionInputValueChange(''))
    }
  }
}

export const showFavorites = (type, token) => {
  return (
    REACT_APP_FAVORITES &&
    JSON.parse(REACT_APP_FAVORITES)?.[type] &&
    ((JSON.parse(REACT_APP_FAVORITES)?.onlyIfConnected && token) || !JSON.parse(REACT_APP_FAVORITES)?.onlyIfConnected)
  )
}

export const tagOnShare = (network, itineraries = false, customEventName = null, name = null) => {
  const dataLayer = {
    event: customEventName ? customEventName : itineraries ? 'map-itinerariesShare' : 'map-itineraryShare',
    socialNetwork: network,
    name: name,
  }

  updateDataLayer(dataLayer)
  message({ clicked: 'share', share: network })
}

/**
 * Translate a sentence with variables in it. Caution : if args are passed, the output will contains HTML !
 * @param {String} key key of line to translate
 * @param {...any} args Strings to replace
 */
export const translate = (key, renderHtml = true, ...args) => {
  const data = appStore.getState().app.languageFile

  if (Object.keys(data).length === 0) {
    return isLocalOrDev() ? key : ''
  }

  if (args?.length === 0) {
    return data[key] ?? (isLocalOrDev() ? key : '')
  } else {
    try {
      return data[key]?.match(/(\$\w+)/g).reduce((acc, value) => {
        const arg = args.find(arg => arg.key === value.replace('$', ''))

        // If we have an arg from the current value, replace with HTML content
        if (arg) {
          if (renderHtml) {
            acc = acc.replace(value, `<span class="lc-translate-${key}-${arg.key}">${arg.value}</span>`)
          } else {
            acc = acc.replace(value, arg.value)
          }
        } else {
          isLocalOrDev() &&
            debug({ message: `Can't retrieve an arg value from ${value}` }, 'warning', 'Missing translation')
        }

        return acc
      }, data[key])
    } catch (e) {
      isLocalOrDev() && console.warn(`Error while translating ${key}`, e.message)
    }
  }
}

/**
 * Define if we need to use us system
 * @param language
 * @returns {boolean}
 */
export const isSystemUS = language => {
  return ['en'].includes(language)
}

export const navitiaDateToHoursMin = (date, language, extend = false) => {
  const time = navitiaDateToDate(date)

  return isSystemUS(language) ? formatDate(time, 'h:m a', language, extend) : formatDate(time, 'h:m', language, extend)
}

export const onTabSelected = (component, index) => {
  const { isMobile, openedCollapse, linesModes } = component.props

  if (!index && index !== 0) {
    index = component.state.tab
  }

  // Retrieve the most important group displayed and select it if it's not already done
  const group = mostImportantGroup(component.state.groups, linesModes)

  index === 0 && openedCollapse !== group && appStore.dispatch(actionSetOpenedCollapse(group))

  component.setState({ tab: index }, () => {
    appStore.dispatch(actionSetLineSelected(null))

    // Put scroll data attr on the current active tab & remove it to the other
    if (document.querySelector("[data-lc-scroll='scroll']")) {
      document.querySelector("[data-lc-scroll='scroll']").removeAttribute('data-lc-scroll')
    }

    document.querySelector('.lc-tab-panel.lc-active').setAttribute('data-lc-scroll', 'scroll')

    resize(isMobile)
  })
}

export const storageAvailable = type => {
  if (REACT_APP_GTM && !appStore.getState().app.hasAuthorizedCookies) {
    // Case with GTM cookies and user revoke cookies
    return false
  } else {
    try {
      const storage = window[type]
      const x = '__storage_test__'

      storage.setItem(x, x)
      storage.removeItem(x)
      return true
    } catch (e) {
      return false
    }
  }
}

export const handleKeyUp = (event, callback) => {
  if (event.key === 'Enter' || event.key === ' ') {
    callback()
  }
}

export const addGetParam = (params, paramsToAdd) => {
  const string = new URLSearchParams({ ...params, ...paramsToAdd }).toString()

  return '?' + string
}

/**
 * Detect if the app is loaded in an iframe or not
 */
export const isInFrame = () => window.self !== window.top

/**
 * Create undraggable around pin
 * @param {*} position
 * @param {*} radius
 * @returns
 */
export const createaroundPin = (position, options) => {
  const { radius = [], color = 'black', opacity = 0.1 } = options
  const refCircle = []

  // create around pin marker
  const pin = (
    <Marker
      options={{ zIndex: 999 }}
      icon={L.icon({
        iconUrl: assetsPath('/assets/images/pin.svg'),
        iconSize: [50, 50],
        iconAnchor: [25, 25],
      })}
      position={position}
      draggable={false}
    />
  )

  const circles = []

  for (const rad of radius) {
    // add circle
    circles.push(
      <Circle
        center={position}
        ref={r => refCircle.push(r)}
        radius={+rad}
        fillColor={color}
        fillOpacity={opacity}
        color="transparent"
      />,
    )
  }

  return {
    pin,
    circles,
    refCircle,
  }
}

export const checkMoreDataReceived = (data, project) => {
  if (project?.startsWith('bordeaux-tbm')) {
    // if realtime schedules add bus icon at coord
    if (data.schedules?.length > 0) {
      let isBoat = false
      let isTram = false
      let isTer = false

      if (data?.line?.mode?.toLowerCase() === 'ferry') {
        isBoat = true
      } else if (data?.line?.mode?.toLowerCase() === 'tramway') {
        isTram = true
      } else if (data?.line?.mode?.toLowerCase() === 'ter') {
        isTer = true
      }

      const busMarkers = data.schedules
        .filter(schedule => schedule.isRealTime === true && schedule.vehiclePosition)
        .map(schedule => ({
          id: schedule.id,
          position: { lat: schedule.vehiclePosition.latitude, lon: schedule.vehiclePosition.longitude },
          icon: {
            url: assetsPath(
              `/assets/images/modes/${isBoat ? 'boat' : isTram ? 'tram' : isTer ? 'ter' : 'bus'}-realtime.svg`,
            ),
            className: 'lc-custom-marker-realtime',
          },
          zIndexOffset: 9000,
        }))

      appStore.dispatch(actionAddCustomMarkers(buildCustomMarkers(busMarkers)))
    }
  }
}

export const initDateTimePicker = dates => {
  let dateSelected = new Date()
  let now = new Date()
  let defaultDateTime = dates.defaultDateTime || null

  if (dates.calendarRescrictDate) {
    dates.minDate = formatDate(new Date(), 'y-m-d')
    dates.defaultDateTime = formatDate(new Date(), 'y-m-d')
    const maxDate = new Date()

    maxDate.setDate(maxDate.getDate() + dates.calendarRescrictDate)
    dates.maxDate = formatDate(maxDate, 'y-m-d')
  }

  // Check if current date is between min and max
  if (dates.defaultDateTime && dates.minDate && dates.maxDate) {
    const minD = new Date(dates.minDate),
      maxD = new Date(dates.maxDate),
      now = new Date()

    if (now > minD && now < maxD) {
      defaultDateTime = now
    }
  }

  appStore.dispatch(actionInitDatePickerMinMaxDate(dates.minDate, dates.maxDate, dates.defaultDateTime))

  if (dates.defaultDateTime?.split('T')[1]) {
    appStore.dispatch(
      actionSetCalendarDateTime(new Date(defaultDateTime).getHours(), new Date(defaultDateTime).getMinutes()),
    )
  }

  if (defaultDateTime !== undefined) {
    appStore.dispatch(actionSetCalendarDate(new Date(defaultDateTime)))
  } else {
    if (dates.minDate !== undefined && new Date(dates.minDate) > now) {
      dateSelected = new Date(dates.minDate)
      dateSelected.setHours(10)
      appStore.dispatch(actionSetCalendarDate(dateSelected))
    }

    if (dates.maxDate !== undefined && new Date(dates.maxDate) < now) {
      dateSelected = new Date(dates.maxDate)
      dateSelected.setHours(now.getHours())
      dateSelected.setMinutes(now.getMinutes())
      appStore.dispatch(actionSetCalendarDate(dateSelected))
    }
  }

  // Init selected date
  appStore.dispatch(actionInitDate(dateSelected))
}

/**
 * Meters to Kilometers
 * @param {*} value
 */
export const unitsMetersToKm = (value, round = false, inChoice = false, forAria = false, translateUnit = false) => {
  const number = parseFloat(value)
  const dist = number >= 1000 ? (round ? Math.round(number / 1000) : number / 1000) : number
  const unit = number >= 1000 ? 'km' : 'm'

  return forAria ? (
    `${dist} ${unit === 'km' ? 'kilomètres' : 'mètres'}`
  ) : inChoice ? (
    <div>
      <span>{dist}</span> {translateUnit ? translate(`unit-${unit}`) : unit}
    </div>
  ) : (
    `${dist} ${unit}`
  )
}

/**
 * Display a marker with lense on geojson
 * @param {*} coord
 * @param {*} type
 */
export const displayPositionOnTrace = (coord, type) => {
  appStore.dispatch(
    actionAddCustomMarkers([
      appStore.dispatch(
        actionBuildCustomMarker(
          {
            id: 'geovelo-hover-instruction',
            name: 'geovelo-hover-instruction',
            coord,
          },
          {
            key: `geovelo-hover-instruction-${type}`,
            icon: new L.DivIcon({
              className: `lc-circle-icon-marker instruction`,
              iconSize: [20, 20],
              tooltipAnchor: new L.Point(5, 0),
              html: `<span style="border: 2px solid ${REACT_APP_PRIMARY_COLOR}; background-color: rgba(255, 255, 255, 0.7)" />`,
            }),
            zIndexOffset: 50,
          },
        ),
      ),
    ]),
  )
}

/**
 * Delete marker with lense on geojson
 */
export const removePositionOnTrace = () => {
  const { customMarkers } = appStore.getState().map

  appStore.dispatch(actionSetCustomMarkers(customMarkers.filter(m => !m.key.startsWith('geovelo-hover-instruction'))))
}

/**
 * Zoom on trace from an instruction or elevation
 * @param {*} map
 * @param {*} coords
 */
export const zoomPositionOnTrace = (map, coords) => {
  fitBounds(map, [coords])
  resize(map.props.isMobile)
}

/**
 * detect if at least one mode is selected
 * @param {*} modes
 * @returns
 */
export const noModesSelected = modes => {
  const modesOnly = { ...modes }

  delete modesOnly['represents']
  delete modesOnly['avoid']
  return Object.values(modesOnly).every(value => value === false)
}

/**
 * return hours and minutes duration
 * @param {} value
 * @returns
 */
export const duration = value => {
  const hours = Math.floor(value / 3600)

  value %= 3600
  const minutes = Math.floor(value / 60)

  return {
    hours,
    minutes,
  }
}

/**
 * return if the line is defined as tad or not in modes.json
 * @param {Array} linesModes List of modes
 * @param {Object} line Line object
 */
export const isDefinedAsTAD = (linesModes, line) => {
  const mode = linesModes.find(lineMode => lineMode.modes.includes(line.mode))

  return mode?.tad
}

export const getMinDurationJourneys = journeys => {
  let journey = { duration: 9999999 }

  for (const j of journeys) {
    if (j.duration < journey.duration) {
      journey = j
    }
  }

  return journey
}

export const displayDuration = journey => {
  const d = duration(journey.duration)

  if (d) {
    return `${d.hours > 0 ? `${d.hours} ${translate('hour-split')}` : ''}${
      d.minutes > 0 ? ` ${d.minutes} ${translate('minute-split')}` : ''
    }`
  } else {
    return ''
  }
}

/**
 * Write a geojson from encoded shapes
 * @param {*} encodedFeatures as an array of objects, with encoded polylines and properties
 * @param {*} path as a string to know where write the geojson
 * @param {*} precision
 */
export const createGeojsonFromEncodedPolyline = (shapes, precision = 5) => {
  const levelUp = f => x => x.map(f)
  const decodeLineString = c => decodeGeometry.toGeoJSON(c, precision).coordinates
  const decodeLineString2 = levelUp(decodeLineString)
  const decodeLineString3 = levelUp(decodeLineString2)
  const buildProp = p => (typeof p === 'object' ? p : { zoom: 1 })

  const buildFeature = (type, coordinates, props) => ({
    type: 'Feature',
    geometry: {
      type,
      coordinates,
    },
    properties: buildProp(props),
  })

  const decodePolyline = f => buildFeature('MultiLineString', decodeLineString2(f.poly), f.props)
  const decodePolygon = f => buildFeature('MultiPolygon', decodeLineString3(f.poly), f.props)

  const decoders = [
    [['l', undefined, null], decodePolyline],
    [['g'], decodePolygon],
  ]

  // eslint-disable-next-line
  const decodeFeature = f => decoders.find(x => x[0].indexOf(f.type) != -1)[1](f)
  const decodeFeature2 = levelUp(decodeFeature)

  const decodeFeatureCollection = c => ({
    type: 'FeatureCollection',
    features: decodeFeature2(c),
  })

  const geojson = decodeFeatureCollection(shapes)

  return geojson
}

// --------------------------- PRIVATE --------------------------- //

/**
 * check if x is between min and max
 * @param x
 * @param min
 * @param max
 * @returns {boolean}
 */
const isBetween = (x, min, max) => x >= min && x <= max

/**
 * Check if autocompletion include geolocation entry
 * @param {*} configApp
 * @param {*} disableAutocompleteGeolocation
 * @param {*} isMobile
 * @returns {boolean}
 */
const displayGeolocationEntry = (configApp, disableAutocompleteGeolocation, isMobile) => {
  let display = false

  // by default autocomplete is available and for mobile devices only
  if (configApp?.autocomplete_geolocation !== false) {
    // in config.json you can force to display geolocation on desktop
    if (isMobile || configApp?.autocomplete_geolocation_allow_desktop === true) {
      display = true
    }
  }

  // from modules.json you can force to disable the geolocation
  if (disableAutocompleteGeolocation === true) {
    display = false
  }

  return display
}
