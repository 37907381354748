import { useSelector, useDispatch } from 'react-redux'
import { humanReadableOpeningHours, translate, assetsPath, handleKeyUp } from '../../../services/tools'
import { actionDecrementStationIndex, actionIncrementStationIndex } from '../../../actions/board'
import Tippy from '@tippy.js/react'
import { message } from '../../../services/message'
import { formatDate } from '../../../utils/tools'
import BikeInterface from '../../../interfaces/BikeInterface'
import {
  PlaceInfo,
  PlaceInfoFlex,
  PlaceInfoOpeningHours,
  PlaceInfoTitle,
  PlaceInfoContent,
  PlaceRealtimeInfo,
  RealtimeImage,
  PRPlaceInfo,
  VCubPlaceInfos,
  BikePrices,
  PictoBike,
  PlaceCars,
  PlaceCar,
  CustomText,
  StationSchedulesHeader,
  StationSchedulesContent,
  StationSchedulesEntry,
  StationSchedulesEntryMode,
  StationPagination,
  StationPaginationNext,
  StationPaginationPrevious,
  StationSchedules,
  PlaceInfoInline,
  StationSchedulesDate,
  EntranceLines,
  EntranceAccess,
  VerticalDivider,
  ChargingStationTypes,
} from './styled'
import { UILine } from '../UILine'
import history from '../../../history'
import UIDisruptedLineOverlay from '../UIDisruptedLineOverlay'
import { appStore } from '../../../store'

const { REACT_APP_PROJECT, REACT_APP_LINES_MAIN_TYPE } = process.env

// "poi_type:amenity:parcs_relais"
export const UIParcsRelais = props => {
  const { place } = props

  if (!place.capacity) {
    return null
  }

  return (
    <PlaceInfo>
      <PlaceInfoFlex>
        <PlaceInfoTitle className="lc-place-info-title">{translate('total-places')}</PlaceInfoTitle>
        &nbsp;<PlaceInfoContent className="lc-place-info-content">{place.capacity}</PlaceInfoContent>
      </PlaceInfoFlex>
      {place.capacity_PRM && (
        <PlaceInfoFlex>
          <PlaceInfoTitle className="lc-place-info-title">{translate('total-places-prm')}</PlaceInfoTitle>
          &nbsp;<PlaceInfoContent>{place.capacity_PRM}</PlaceInfoContent>
        </PlaceInfoFlex>
      )}
      {place.guarded && (
        <PlaceInfoFlex>
          <PlaceInfoTitle className="lc-place-info-title">{translate('parking-guarded')}</PlaceInfoTitle>
          &nbsp;<PlaceInfoContent>{place.guarded ? translate('yes') : translate('no')}</PlaceInfoContent>
        </PlaceInfoFlex>
      )}
    </PlaceInfo>
  )
}

// "poi_type:stations"
export const UIStations = props => {
  const { place, displayon } = props
  const dispatch = useDispatch()
  const { currentStationDepartureIndex, currentStationArrivalIndex } = useSelector(state => state.board)

  if (!place || displayon === 'map') {
    return null
  }

  return (
    <PlaceInfo>
      <StationSchedulesDate>
        {translate('sncf-stations-date', false, { key: 'date', value: formatDate(new Date(), 'd/mY') })}
      </StationSchedulesDate>
      <StationSchedules departure={`${place.stand?.departures?.length ?? false}`}>
        <StationSchedulesHeader>
          <div>{translate('sncf-stations-departure')}</div>
        </StationSchedulesHeader>

        <StationSchedulesContent className={place.stand && place.stand?.departures?.length ? 'lc-not-empty' : ''}>
          {place.stand && place.stand?.departures?.length
            ? place.stand.departures
                .filter(
                  (_, index) =>
                    index > currentStationDepartureIndex * 3 - 1 && index <= currentStationDepartureIndex * 3 + 2,
                )
                .map((departure, index) => (
                  <StationSchedulesEntry
                    key={index}
                    className="lc-station-schedules-entry"
                    tabIndex="0"
                    aria-label={translate(
                      'aria-station-label',
                      false,
                      { key: 'type', value: translate('sncf-stations-departure', false) },
                      { key: 'mode', value: departure.mode },
                      { key: 'time', value: departure.basetime },
                      { key: 'direction', value: departure.direction },
                    )}>
                    <div>
                      <div>{departure.realtime ? departure.time : departure.basetime}</div>
                      {departure.realtime && <del>{departure.basetime}</del>}
                    </div>
                    <div className="lc-station-schedules-entryDirection">
                      {departure.direction}
                      <StationSchedulesEntryMode className="lc-station-schedules-entry-mode">
                        {departure.mode}
                      </StationSchedulesEntryMode>
                    </div>
                  </StationSchedulesEntry>
                ))
            : translate('no-stations-departures')}
        </StationSchedulesContent>
        {place.stand && place.stand?.departures?.length > 3 && (
          <StationPagination>
            {currentStationDepartureIndex > 0 && (
              <StationPaginationPrevious
                onClick={e => {
                  e.stopPropagation()
                  currentStationDepartureIndex > 0 && dispatch(actionDecrementStationIndex('departure'))
                }}
                onKeyUp={e =>
                  handleKeyUp(e, () => {
                    currentStationDepartureIndex > 0 && dispatch(actionDecrementStationIndex('departure'))
                  })
                }
                role="button"
                tabIndex="0"
                aria-label={translate('aria-station-previous-departure', false)}>
                <img src={assetsPath('/assets/images/v.svg')} alt={translate('collapse-arrow')} aria-hidden="true" />
              </StationPaginationPrevious>
            )}

            {(currentStationDepartureIndex + 1) * 3 < place.stand?.departures?.length && (
              <StationPaginationNext
                onClick={e => {
                  e.stopPropagation()
                  currentStationDepartureIndex < 6 && dispatch(actionIncrementStationIndex('departure'))
                }}
                onKeyUp={e =>
                  handleKeyUp(e, () => {
                    currentStationDepartureIndex < 6 && dispatch(actionIncrementStationIndex('departure'))
                  })
                }
                role="button"
                tabIndex="0"
                aria-label={translate('aria-station-next-departure', false)}>
                <img src={assetsPath('/assets/images/v.svg')} alt={translate('collapse-arrow')} aria-hidden="true" />
              </StationPaginationNext>
            )}
          </StationPagination>
        )}
      </StationSchedules>
      <StationSchedules>
        <StationSchedulesHeader>
          <div>{translate('sncf-stations-arrival')}</div>
        </StationSchedulesHeader>
        <StationSchedulesContent className={place.stand && place.stand?.arrivals?.length ? ' lc-not-empty' : ''}>
          {place.stand && place.stand?.arrivals?.length
            ? place.stand.arrivals
                .filter(
                  (_, index) =>
                    index > currentStationArrivalIndex * 3 - 1 && index <= currentStationArrivalIndex * 3 + 2,
                )
                .map((arrival, index) => (
                  <StationSchedulesEntry
                    key={index}
                    tabIndex="0"
                    aria-label={translate(
                      'aria-station-label',
                      false,
                      { key: 'type', value: translate('sncf-stations-arrival', false) },
                      { key: 'mode', value: arrival.mode },
                      { key: 'time', value: arrival.basetime },
                      { key: 'direction', value: arrival.direction },
                    )}>
                    <div>
                      <div>{arrival.realtime ? arrival.time : arrival.basetime}</div>
                      {arrival.realtime && (
                        <div>
                          <del>{arrival.basetime}</del>
                        </div>
                      )}
                    </div>
                    <div className="lc-station-schedules-entryDirection">
                      {arrival.direction}
                      <br />
                      <StationSchedulesEntryMode className="lc-station-schedules-entry-mode">
                        {arrival.mode}
                      </StationSchedulesEntryMode>
                    </div>
                  </StationSchedulesEntry>
                ))
            : translate('no-stations-arrivals')}
        </StationSchedulesContent>
        {place.stand && place.stand?.arrivals?.length > 3 && (
          <StationPagination>
            {currentStationArrivalIndex > 0 && (
              <StationPaginationPrevious
                onClick={e => {
                  e.stopPropagation()
                  currentStationArrivalIndex > 0 && dispatch(actionDecrementStationIndex('arrival'))
                }}
                onKeyUp={e =>
                  handleKeyUp(e, () => {
                    currentStationArrivalIndex > 0 && dispatch(actionDecrementStationIndex('arrival'))
                  })
                }
                role="button"
                tabIndex="0"
                aria-label={translate('aria-station-previous-arrival', false)}>
                <img src={assetsPath('/assets/images/v.svg')} alt={translate('collapse-arrow')} aria-hidden="true" />
              </StationPaginationPrevious>
            )}
            {(currentStationArrivalIndex + 1) * 3 < place.stand?.departures?.length && (
              <StationPaginationNext
                onClick={e => {
                  e.stopPropagation()
                  currentStationArrivalIndex < 6 && dispatch(actionIncrementStationIndex('arrival'))
                }}
                onKeyUp={e =>
                  handleKeyUp(e, () => {
                    currentStationArrivalIndex < 6 && dispatch(actionIncrementStationIndex('arrival'))
                  })
                }
                role="button"
                tabIndex="0"
                aria-label={translate('aria-station-next-arrival', false)}>
                <img src={assetsPath('/assets/images/v.svg')} alt={translate('collapse-arrow')} aria-hidden="true" />
              </StationPaginationNext>
            )}
          </StationPagination>
        )}
      </StationSchedules>
    </PlaceInfo>
  )
}

// "poi_type:amenity:bicycle_parking"
export const UIBicycleParking = props => {
  const { place } = props

  return (
    <PlaceInfoFlex tabIndex="0" aria-label={translate('total-places', false) + ' ' + place.capacity}>
      <PlaceInfoTitle className="lc-place-info-title">{translate('total-places')}</PlaceInfoTitle>
      &nbsp;<PlaceInfoContent className="lc-place-info-content">{place.capacity}</PlaceInfoContent>
    </PlaceInfoFlex>
  )
}

// "poi_type:amenity:bicycle_rental"
export const UIBicycleRental = props => {
  const { place, options } = props

  switch (REACT_APP_PROJECT) {
    case 'montelimar-montelibus': {
      if (!place.is_available && place?.stand?.status === 'open') {
        place.is_available = true
      }
      const availableBikes = place.stand?.availableBikes >= 0 ? place.stand.availableBikes : place.availableBikes
      const availablePlaces = place.stand?.availablePlaces >= 0 ? place.stand.availablePlaces : place.availablePlaces
      return (
        <>
          {place.is_available !== undefined && place.is_available === false && (
            <PlaceInfo>
              <PlaceInfoTitle className="lc-place-info-title">{translate('bike-station-unavailable')}</PlaceInfoTitle>
            </PlaceInfo>
          )}
          {availableBikes !== undefined || availablePlaces !== undefined ? (
            <div className="popupContainer">
              {availableBikes !== undefined && (
                <div data-realtime-seats className="placeInfoContainer">
                  <img
                    src={assetsPath('/assets/images/modes/bss.svg')}
                    alt={translate('bss-bikes-available')}
                    className="bikePicto"
                  />
                  <div className="availableBikesAndRealtimeGif">
                    <div className="availableBikes">{availableBikes >= 0 ? availableBikes : translate('no-data')}</div>
                    <Tippy
                      theme={'latitude'}
                      touch={['hold', 500]}
                      placement={'right'}
                      boundary="window"
                      content={translate('realtime-gif-title')}>
                      <img
                        src={assetsPath('/assets/images/realtime.gif')}
                        alt={translate('realtime-gif-alt')}
                        aria-hidden="true"
                        className="realtimeGif"
                      />
                    </Tippy>
                  </div>
                </div>
              )}
              {availablePlaces !== undefined && (
                <div data-realtime-seats className="placeInfoContainer">
                  <img
                    src={assetsPath('/assets/images/bss-seat.svg')}
                    alt={translate('bss-bikes-places-available')}
                    className="bikePicto"
                  />
                  <div className="availableBikesAndRealtimeGif">
                    <div className="availableBikes">
                      &nbsp;{availablePlaces >= 0 ? availablePlaces : translate('no-data')}
                    </div>
                    <Tippy
                      theme={'latitude'}
                      touch={['hold', 500]}
                      placement={'right'}
                      boundary="window"
                      content={translate('realtime-gif-title')}>
                      <RealtimeImage
                        src={assetsPath('/assets/images/realtime.gif')}
                        alt={translate('realtime-gif-alt')}
                        aria-hidden="true"
                        marginLeft={true}
                        width={'15px'}
                        height={'15px'}
                      />
                    </Tippy>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </>
      )
    }
    default:
      if (place.stand && place.stand.forceUseBikeInterface !== true) {
        const bikesAvailable =
          place.stand && Object.keys(place.stand).length > 0
            ? place.stand.available_bikes
            : place.availablePlaces
            ? place.availablePlaces
            : place.availableBikes || translate('no-data')

        const placesAvailable =
          place.stand && Object.keys(place.stand).length > 0 ? place.stand.available_places : translate('no-data')

        if (!place.is_available && place?.stand?.status === 'open') {
          place.is_available = true
        }
        return (
          <>
            {place.is_available === true ? (
              <>
                <PlaceInfo
                  tabIndex="0"
                  aria-label={
                    translate('aria-bikes-available', false, {
                      key: 'bikes',
                      value: bikesAvailable,
                    }) +
                    '. ' +
                    translate('aria-bikes-available', false, {
                      key: 'seats',
                      value: bikesAvailable,
                    })
                  }
                  className="lc-place-bss-availability">
                  <PlaceInfoTitle className="lc-place-info-title">
                    {translate('nb-bikes-available')}&nbsp;
                  </PlaceInfoTitle>
                  <PlaceInfoContent className="lc-place-info-content">
                    {parseInt(bikesAvailable)}
                    <PictoBike
                      src={assetsPath('/assets/images/modes/bss.svg')}
                      alt={translate('bss-bikes-available')}
                      aria-hidden="true"
                    />
                    <Tippy
                      theme={'latitude'}
                      touch={['hold', 500]}
                      placement={'right'}
                      boundary="window"
                      content={translate('realtime-gif-title')}>
                      <RealtimeImage
                        src={assetsPath('/assets/images/realtime.gif')}
                        alt={translate('realtime-gif-alt')}
                        aria-hidden="true"
                        width={'12px'}
                        height={'12px'}
                      />
                    </Tippy>
                  </PlaceInfoContent>
                </PlaceInfo>
                {placesAvailable >= 0 ? (
                  <PlaceInfo
                    tabIndex="0"
                    aria-label={
                      translate('aria-bikes-places-available', false, {
                        key: 'bikes',
                        value: placesAvailable,
                      }) +
                      '. ' +
                      translate('aria-bikes-places-available', false, {
                        key: 'seats',
                        value: placesAvailable,
                      })
                    }
                    className="lc-place-bss-availability">
                    <PlaceInfoTitle className="lc-place-info-title">
                      {translate('nb-bikes-places-available')}&nbsp;
                    </PlaceInfoTitle>
                    <PlaceInfoContent className="lc-place-info-content">
                      {placesAvailable}
                      <PictoBike
                        src={assetsPath('/assets/images/bss-seat.svg')}
                        alt={translate('bss-bikes-places-available')}
                        aria-hidden="true"
                      />
                      <Tippy
                        theme={'latitude'}
                        touch={['hold', 500]}
                        placement={'right'}
                        boundary="window"
                        content={translate('realtime-gif-title')}>
                        <RealtimeImage
                          src={assetsPath('/assets/images/realtime.gif')}
                          alt={translate('realtime-gif-alt')}
                          aria-hidden="true"
                          width={'12px'}
                          height={'12px'}
                        />
                      </Tippy>
                    </PlaceInfoContent>
                  </PlaceInfo>
                ) : null}
              </>
            ) : (
              <PlaceInfo>
                <PlaceInfoTitle
                  className="lc-place-info-title"
                  tabIndex="0"
                  aria-label={translate('bss-closed', false)}>
                  {translate('bss-closed')}
                </PlaceInfoTitle>
              </PlaceInfo>
            )}
          </>
        )
      } else if (place instanceof BikeInterface || place.stand?.forceUseBikeInterface === true) {
        return (
          <>
            {place.is_available !== undefined && place.is_available === false && (
              <PlaceInfo>
                <PlaceInfoTitle className="lc-place-info-title">{translate('bike-station-unavailable')}</PlaceInfoTitle>
              </PlaceInfo>
            )}
            {place.availableBikes !== undefined && (
              <PlaceInfo>
                <PlaceInfoFlex data-realtime-seats>
                  <PictoBike src={assetsPath('/assets/images/modes/bss.svg')} alt={translate('bss-bikes-available')} />
                  <PlaceInfoTitle className="lc-place-info-title">
                    {translate('nb-bikes-available')}&nbsp;
                  </PlaceInfoTitle>
                  <PlaceInfoContent className="lc-place-info-content lc-bike-interface">
                    &nbsp;
                    {place.availableBikes >= 0 ? place.availableBikes : translate('no-data')}
                    <Tippy
                      theme={'latitude'}
                      touch={['hold', 500]}
                      placement={'right'}
                      boundary="window"
                      content={translate('realtime-gif-title')}>
                      <RealtimeImage
                        src={assetsPath('/assets/images/realtime.gif')}
                        alt={translate('realtime-gif-alt')}
                        aria-hidden="true"
                        marginLeft={true}
                        width={'15px'}
                        height={'15px'}
                      />
                    </Tippy>
                  </PlaceInfoContent>
                </PlaceInfoFlex>
              </PlaceInfo>
            )}
            {place.availablePlaces !== undefined && (
              <PlaceInfo>
                <PlaceInfoFlex data-realtime-seats>
                  <PictoBike
                    src={assetsPath('/assets/images/bss-seat.svg')}
                    alt={translate('bss-bikes-places-available')}
                  />
                  <PlaceInfoTitle className="lc-place-info-title">
                    {translate('nb-bikes-places-available')}&nbsp;
                  </PlaceInfoTitle>
                  <PlaceInfoContent className="lc-place-info-content lc-bike-interface">
                    &nbsp;{place.availablePlaces >= 0 ? place.availablePlaces : translate('no-data')}
                    <Tippy
                      theme={'latitude'}
                      touch={['hold', 500]}
                      placement={'right'}
                      boundary="window"
                      content={translate('realtime-gif-title')}>
                      <RealtimeImage
                        src={assetsPath('/assets/images/realtime.gif')}
                        alt={translate('realtime-gif-alt')}
                        aria-hidden="true"
                        marginLeft={true}
                        width={'15px'}
                        height={'15px'}
                      />
                    </Tippy>
                  </PlaceInfoContent>
                </PlaceInfoFlex>
              </PlaceInfo>
            )}
            {options?.dontShowCapacity !== true && place.capacity !== undefined && props.displayon === 'board' && (
              <PlaceInfo>
                <PlaceInfoFlex>
                  <PlaceInfoTitle className="lc-place-info-title">
                    {translate('nb-seats-available')}&nbsp;
                  </PlaceInfoTitle>
                  <PlaceInfoContent className="lc-place-info-content">
                    {place.capacity || translate('no-data')}
                    <PictoBike src={assetsPath('/assets/images/bss-seat.svg')} alt={translate('available-places')} />
                  </PlaceInfoContent>
                </PlaceInfoFlex>
              </PlaceInfo>
            )}
            {place.prices !== undefined && (
              <PlaceInfo>
                <PlaceInfoTitle className="lc-place-info-title">{translate('prices')}</PlaceInfoTitle>
                <PlaceInfoContent className="lc-place-info-content">
                  <BikePrices>
                    {Object.keys(place.prices).map(time => {
                      let min = parseInt(time)
                      let hours = 0

                      if (time > 59) {
                        hours = parseInt(Math.floor(min / 60))
                        min = min % 60
                      }

                      if (min < 10) {
                        min = '0' + min
                      }

                      return (
                        <li key={'price-' + time}>
                          {hours > 0 ? `${hours}h${min}` : `${min}min`} : {place.prices[time]}€
                        </li>
                      )
                    })}
                  </BikePrices>
                </PlaceInfoContent>
              </PlaceInfo>
            )}
            {place.price_url !== undefined && (
              <PlaceInfo>
                <PlaceInfoTitle className="lc-place-info-title">{translate('see-prices')}</PlaceInfoTitle>
                <PlaceInfoContent className="lc-place-info-content">
                  <a href={place.price_url} target="_blank" rel="noopener noreferrer">
                    {place.price_url}
                  </a>
                </PlaceInfoContent>
              </PlaceInfo>
            )}
          </>
        )
      } else {
        return null
      }
  }
}

// "poi_type:amenity:park_ride"
export const UIParkRide = props => {
  const { place } = props

  if (!place.stand) {
    return null
  }

  return (
    <PlaceInfo>
      <PlaceInfoFlex>
        <PlaceInfoTitle className="lc-place-info-title">{translate('total-places')}&nbsp;</PlaceInfoTitle>
        <PlaceInfoContent className="lc-place-info-content">
          {place.stand.total_places} &nbsp;
          {place.stand.occupied_PRM ? '- ' + place.stand.occupied_PRM + ' ' + translate('disabled-spaces') : ''}
        </PlaceInfoContent>
      </PlaceInfoFlex>
    </PlaceInfo>
  )
}

// "poi_type:amenity:parking"
export const UIParking = props => {
  const { place } = props
  const { configApp } = appStore.getState().app

  if (configApp.hide_parking_capacity && !place.capacity) {
    return null
  }

  switch (REACT_APP_PROJECT) {
    case 'rennes-star':
      return place.stand ? (
        <div className="lc-poi-content-parking-availability">
          <img
            src={assetsPath('/assets/images/realtime.gif')}
            alt={translate('realtime-gif-alt')}
            title={translate('realtime-gif-title')}
          />{' '}
          {translate('realtime-available-places', false, {
            key: 'time',
            value: new Date().toLocaleTimeString('fr', { hour: '2-digit', minute: '2-digit' }),
          })}
          <div className="lc-poi-content-parking-car-park-places">
            {place.stand?.available >= 0 && (
              <div>
                {place.stand?.available} {translate('realtime-available-places-places', false)}
              </div>
            )}
            {place.stand?.available_ridesharing >= 0 && (
              <div>
                {place.stand?.available_ridesharing} {translate('realtime-available-places-ridesharing', false)}
              </div>
            )}
            {place.stand?.available_PRM >= 0 && (
              <div>
                {place.stand?.available_PRM} {translate('realtime-available-places-pmr', false)}
              </div>
            )}
            {place.stand?.available_electric_vehicle >= 0 && (
              <div>
                {place.stand?.available_electric_vehicle} {translate('realtime-available-places-electric', false)}
              </div>
            )}
          </div>
        </div>
      ) : null
    default:
      return (
        <PlaceInfo
          className="lc-place-info"
          tabIndex="0"
          aria-label={
            place.capacity +
            ' ' +
            translate('total-places', false) +
            '. ' +
            (place.stand && place.stand.occupied_PRM
              ? place.stand.occupied_PRM + ' ' + translate('disabled-spaces', false) + '. '
              : '') +
            (place.stand && place.stand.available && place.stand.available !== 0
              ? translate('aria-available-places', false, {
                  key: 'count',
                  value: place.stand.available,
                })
              : place.stand && place.stand.available === 0
              ? translate('no-available-places', false)
              : translate('no-informations-for-available-places', false))
          }>
          {(place.stand && place.stand.has_realtime === false) || (!place.stand && place.has_realtime === false) ? (
            <PlaceRealtimeInfo data-realtime-seats>{translate('no-pr-no-realtime-data')}</PlaceRealtimeInfo>
          ) : (place.stand && place.stand.available && place.stand.available !== 0) ||
            (place.stand && place.stand.available_PRM && place.stand.available_PRM !== 0) ? (
            <>
              <PlaceRealtimeInfo data-realtime-seats>
                <b>{translate('realtime-availability')}&nbsp;</b>
                {place.stand.available && place.stand.available !== 0 ? (
                  <span>
                    {place.stand.available} {translate('available-places')}&nbsp;
                    <Tippy
                      theme={'latitude'}
                      touch={['hold', 500]}
                      placement={'right'}
                      boundary="window"
                      content={translate('realtime-gif-title')}>
                      <RealtimeImage
                        src={assetsPath('/assets/images/realtime.gif')}
                        alt={translate('realtime-gif-alt')}
                        aria-hidden="true"
                      />
                    </Tippy>
                  </span>
                ) : null}
                {place.stand.available_PRM && place.stand.available_PRM !== 0 ? (
                  <span>
                    {place.stand.available !== 0 ? ' / ' : ''}
                    {place.stand.available_PRM} {translate('realtime-disabled-spaces')}&nbsp;
                    <Tippy
                      theme={'latitude'}
                      touch={['hold', 500]}
                      placement={'right'}
                      boundary="window"
                      content={translate('realtime-gif-title')}>
                      <RealtimeImage
                        src={assetsPath('/assets/images/realtime.gif')}
                        alt={translate('realtime-gif-alt')}
                        aria-hidden="true"
                      />
                    </Tippy>
                  </span>
                ) : null}
              </PlaceRealtimeInfo>
            </>
          ) : place.stand && place.stand.available === 0 ? (
            <PlaceRealtimeInfo data-realtime-seats>{translate('no-available-places')}</PlaceRealtimeInfo>
          ) : (
            <PlaceRealtimeInfo data-realtime-seats>
              {translate('no-informations-for-available-places')}
            </PlaceRealtimeInfo>
          )}
          {place.stand?.available_electric_vehicle >= 0 ? (
            <PlaceInfoInline marginTop="5">
              <PlaceInfoTitle className="lc-place-info-title">{translate('available-electric-car')}</PlaceInfoTitle>
              &nbsp;
              <PlaceInfoContent className="lc-place-info-content">
                {place.stand?.available_electric_vehicle}{' '}
                <Tippy
                  theme={'latitude'}
                  touch={['hold', 500]}
                  placement={'right'}
                  boundary="window"
                  content={translate('realtime-gif-title')}>
                  <RealtimeImage
                    src={assetsPath('/assets/images/realtime.gif')}
                    alt={translate('realtime-gif-alt')}
                    aria-hidden="true"
                    marginLeft={true}
                  />
                </Tippy>
              </PlaceInfoContent>
            </PlaceInfoInline>
          ) : null}
          {place.stand?.available_ridesharing >= 0 ? (
            <PlaceInfoInline marginTop="5">
              <PlaceInfoTitle className="lc-place-info-title">{translate('available-cosharing-car')}</PlaceInfoTitle>
              &nbsp;
              <PlaceInfoContent className="lc-place-info-content">
                {place.stand?.available_ridesharing}{' '}
                <Tippy
                  theme={'latitude'}
                  touch={['hold', 500]}
                  placement={'right'}
                  boundary="window"
                  content={translate('realtime-gif-title')}>
                  <RealtimeImage
                    src={assetsPath('/assets/images/realtime.gif')}
                    alt={translate('realtime-gif-alt')}
                    aria-hidden="true"
                    marginLeft={true}
                  />
                </Tippy>
              </PlaceInfoContent>
            </PlaceInfoInline>
          ) : null}
          {place.capacity && (
            <PlaceInfoFlex marginTop="5">
              <PlaceInfoTitle className="lc-place-info-title">{translate('total-places')}</PlaceInfoTitle>
              &nbsp;<PlaceInfoContent className="lc-place-info-content">{place.capacity}</PlaceInfoContent>
            </PlaceInfoFlex>
          )}
          {place.stand?.occupied_PRM ? (
            <PlaceInfoFlex marginTop="5" className="lc-place-content-parking-occipied-prm">
              <PlaceInfoTitle className="lc-place-info-title">{translate('disabled-spaces')}</PlaceInfoTitle>
              &nbsp;<PlaceInfoContent>{place.stand.occupied_PRM}</PlaceInfoContent>
            </PlaceInfoFlex>
          ) : null}
          {place.available_bikes ? (
            <PlaceInfoFlex marginTop="5" className="lc-place-content-parking-available-bikes">
              <PlaceInfoTitle className="lc-place-info-title">{translate('nb-bikes-available')}</PlaceInfoTitle>
              &nbsp;<PlaceInfoContent>{place.available_bikes}</PlaceInfoContent>
            </PlaceInfoFlex>
          ) : null}
          {place.available_secured_bikes ? (
            <PlaceInfoFlex marginTop="5" className="lc-place-content-parking-available-secured-bikes">
              <PlaceInfoTitle>{translate('nb-bikes-secured-available')}</PlaceInfoTitle>
              &nbsp;<PlaceInfoContent>{place.available_secured_bikes}</PlaceInfoContent>
            </PlaceInfoFlex>
          ) : null}
          {place.capacity_PRM ? (
            <PlaceInfoFlex marginTop="5">
              <PlaceInfoTitle className="lc-place-info-title">{translate('disabled-spaces', false)}</PlaceInfoTitle>
              &nbsp;<PlaceInfoContent className="lc-place-info-content">{place.capacity_PRM}</PlaceInfoContent>
            </PlaceInfoFlex>
          ) : null}
        </PlaceInfo>
      )
  }
}

// "poi_type:p+r"
export const UIPR = props => {
  const { place, options, language, displayon } = props
  const { component } = useSelector(state => state.app)
  const { moduleData } = component.props
  const prDetails = options?.thematics?.find(t => t.name === 'p+r')?.details || moduleData.forcePRDetails
  const prMoreInfos = () => message({ clicked: 'p+r-more-infos', id: place.id })
  const updatedAt = place.updatedAt ? new Date(place.updatedAt.replace(' ', 'T')) : null
  const now = new Date()

  const formattedUpdatedAt = updatedAt
    ? updatedAt?.getDate() === now.getDate() &&
      updatedAt?.getMonth() === now.getMonth() &&
      updatedAt?.getFullYear() === now.getFullYear()
      ? `${translate('data-updated-at')} ${formatDate(updatedAt, 'h:m', language)}`
      : `${translate('data-updated-on')} ${formatDate(updatedAt, 'd/m/y h:m', language)}`
    : null

  return (
    <PRPlaceInfo displayon={displayon}>
      <address className="lc-pr-address">{place.info}</address>

      {displayon === 'map' && place.predictFilter && (place.stand?.time || place.predictions) ? (
        <strong>{translate('pr-temporary-predict-filter')}</strong>
      ) : (
        <>
          {displayon === 'map' && (
            <>
              <div className="lc-pr-slots-container">
                {place.color === 'closed' ? (
                  <div className="lc-pr-realtime-seats lc-no-available-places" data-realtime-seats>
                    {translate('closed-pr')}
                  </div>
                ) : place.stand.available && place.stand.available !== 0 ? (
                  <>
                    <div className="lc-pr-realtime-seats" data-realtime-seats>
                      {place.stand.available}
                      {place.stand.available_electric_cars === null && place.stand.available_prm === null && (
                        <span className="lc-slots">
                          {place.stand.available <= 1 ? translate('slot') : translate('slots')}
                        </span>
                      )}
                      {/* {place.stand.available <= 1 ? translate("slot") : translate("slots")} */}
                    </div>
                  </>
                ) : place.stand.available === 0 ? (
                  <div className="lc-pr-realtime-seats lc-no-available-places" data-realtime-seats>
                    {translate('no-available-places')}
                  </div>
                ) : (
                  <div className="lc-pr-realtime-seats lc-no-available-places" data-realtime-seats>
                    {translate('no-informations-for-available-places')}
                  </div>
                )}
                {place.color !== 'closed' && place.stand.available_electric_cars !== null && (
                  <>
                    <div className="lc-pr-realtime-elec-slots">{place.stand.available_electric_cars}</div>
                  </>
                )}
                {place.color !== 'closed' && place.stand.available_prm !== null && (
                  <>
                    <div className="lc-pr-realtime-pmr-slots">{place.stand.available_prm}</div>
                  </>
                )}
              </div>
              {place.stand.available > 0 && (
                <div className="lc-pr-time">
                  {place.stand.time ? translate(`predict-${place.stand.time}`) : formattedUpdatedAt}
                </div>
              )}
              {prDetails && (
                <div
                  className="lc-pr-more-infos"
                  role="button"
                  tabIndex="0"
                  onClick={e => {
                    e.stopPropagation()
                    prMoreInfos()
                  }}
                  onKeyDown={e => handleKeyUp(prMoreInfos, e)}>
                  {translate('more-informations')}
                </div>
              )}
            </>
          )}
        </>
      )}
    </PRPlaceInfo>
  )
}

// "poi_type:vcub"
export const UIVCub = props => {
  const { place, options, displayon } = props
  const vcubDetails = options?.thematics?.find(t => t.name === 'vcub')?.details
  const vcubMoreInfos = () => message({ clicked: 'vcub-more-infos', id: place.id })

  if (!place.stand) {
    return null
  }

  return (
    <VCubPlaceInfos displayon={displayon}>
      {displayon === 'map' && place.predictFilter && (place.stand?.time || place.predictions) ? (
        <strong>{translate('vcub-temporary-predict-filter')}</strong>
      ) : (
        displayon === 'map' && (
          <>
            {place.color === 'unavailable' ? (
              <div className="lc-vcub-unavailable">{translate('vcub-unavailable')}</div>
            ) : place.color === 'maintenance' ? (
              <div className="lc-vcub-maintenance">{translate('vcub-maintenance')}</div>
            ) : (
              <>
                <div className="lc-bss" data-realtime-seats>
                  <span className="lc-bikes">{place.stand.available_bikes}</span>
                  {!place.stand.time && (
                    <span className="lc-electric-bikes">{place.stand.available_electric_bikes}</span>
                  )}
                  <span className="lc-seats">{place.stand.available_places}</span>
                </div>
                <div className="lc-bss-time">
                  {place.stand.time ? translate(`predict-${place.stand.time}`) : translate('data-update-time')}
                </div>
              </>
            )}
            {vcubDetails && (
              <div
                className="lc-vcub-more-infos"
                role="button"
                tabIndex="0"
                onClick={e => {
                  e.stopPropagation()
                  vcubMoreInfos()
                }}
                onKeyDown={e => handleKeyUp(vcubMoreInfos, e)}>
                {translate('more-informations')}
              </div>
            )}
          </>
        )
      )}
    </VCubPlaceInfos>
  )
}

// poi_type:amenity:velonecy
export const UIVelonecy = props => {
  const { place } = props

  if (!place) {
    return null
  }

  return (
    <>
      <PlaceInfo>
        <PlaceInfoTitle className="lc-place-info-title">{translate('services-title')}</PlaceInfoTitle>
        <PlaceInfoContent className="lc-place-info-content">
          {place.bicycle_rental !== undefined && (
            <div>
              {translate('bicycle-rental', false)} :{' '}
              {place.bicycle_rental === true ? translate('yes', false) : translate('no', false)}
            </div>
          )}
          {place.pump !== undefined && (
            <div>
              {translate('pump', false)} : {place.pump === true ? translate('yes', false) : translate('no', false)}
            </div>
          )}
          {place.repair !== undefined && (
            <div>
              {translate('repair-station', false)} :{' '}
              {place.repair === true ? translate('yes', false) : translate('no', false)}
            </div>
          )}
        </PlaceInfoContent>
      </PlaceInfo>
    </>
  )
}

// "poi_type:amenity:bicycle_secure_parking"
export const UIBicycleSecureParking = props => {
  const { place } = props

  if (!place) {
    return false
  }

  return (
    <>
      <PlaceInfo>
        <PlaceInfoFlex>
          <PlaceInfoTitle
            className="lc-place-info-title"
            tabIndex="0"
            aria-label={place.capacity + ' ' + translate('total-places', false)}>
            {translate('nb-places')}&nbsp;
          </PlaceInfoTitle>
          <PlaceInfoContent className="lc-place-info-content">{place.capacity}</PlaceInfoContent>
        </PlaceInfoFlex>
      </PlaceInfo>
      {place.prices && (
        <PlaceInfo>
          <PlaceInfoFlex>
            <PlaceInfoTitle
              className="lc-place-info-title"
              tabIndex="0"
              aria-label={
                translate('prices', false) +
                ' ' +
                place.prices
                  .split(';')
                  .map(price =>
                    price.replace('month', translate('month', false)).replace('year', translate('year', false)),
                  )
                  .join(', ')
              }>
              {translate('prices')}&nbsp;
            </PlaceInfoTitle>
            <PlaceInfoContent className="lc-place-info-content">
              {place.prices
                .split(';')
                .map(price =>
                  price.replace('month', translate('month', false)).replace('year', translate('year', false)),
                )
                .join(', ')}
            </PlaceInfoContent>
          </PlaceInfoFlex>
        </PlaceInfo>
      )}
    </>
  )
}

// "poi_type:amenity:bicycle_bollard"
export const UIBicycleBollard = props => {
  const { place } = props

  if (!place) {
    return null
  }

  return (
    <PlaceInfoFlex tabIndex="0" aria-label={place.capacity + ' ' + translate('total-places', false)}>
      <PlaceInfoTitle className="lc-place-info-title">{translate('nb-places')}&nbsp;</PlaceInfoTitle>
      <PlaceInfoContent className="lc-place-info-content">{place.capacity}</PlaceInfoContent>
    </PlaceInfoFlex>
  )
}

// "poi_type:amenity:bicycle_repair_station"
export const UIBicycleRepairStation = props => {
  const { place } = props

  if (!place) {
    return null
  }

  return (
    <PlaceInfo>
      <PlaceInfoTitle className="lc-place-info-title">{translate('services-title')}</PlaceInfoTitle>
      <PlaceInfoContent className="lc-place-info-content">
        <span>
          {translate('pump', false)} : {place.pump === true ? translate('yes', false) : translate('no', false)}
        </span>
        <br />
        <span>
          {translate('repair-station', false)} :{' '}
          {place.repair === true ? translate('yes', false) : translate('no', false)}
        </span>
      </PlaceInfoContent>
    </PlaceInfo>
  )
}

// "poi_type:amenity:private_bicycle_rental"
export const UIPrivateBicycleRental = props => {
  const { place } = props

  if (!place) {
    return null
  }

  return (
    <PlaceInfo>
      <PlaceInfoTitle className="lc-place-info-title">{translate('services-title')}</PlaceInfoTitle>
      <PlaceInfoContent className="lc-place-info-content">
        <span>
          {translate('repair-station', false)} :{' '}
          {place.repair === true ? translate('yes', false) : translate('no', false)}
        </span>
      </PlaceInfoContent>
    </PlaceInfo>
  )
}

// "poi_type:leisure:swimming_area"
export const UISwimmingArea = props => {
  const { place } = props

  if (!place) {
    return null
  }

  return (
    <PlaceInfo>
      <PlaceInfoTitle className="lc-place-info-title">{translate('services-title')}</PlaceInfoTitle>
      <PlaceInfoContent className="lc-place-info-content">
        {place.fee !== undefined && (
          <div>
            {translate('beach-fee', false)} :{' '}
            {place.fee === 'no' ? translate('no', false) : place.fee === 'yes' ? translate('yes', false) : place.fee}
          </div>
        )}
        {place.supervised !== undefined && (
          <div>
            {translate('beach-supervised', false)} :{' '}
            {place.supervised === 'no'
              ? translate('no', false)
              : place.supervised === 'yes'
              ? translate('yes', false)
              : place.supervised}
          </div>
        )}
        {place.bike_ride !== undefined && (
          <div>
            {translate('bike-ride', false)} :{' '}
            {place.bike_ride === 'yes' ? translate('yes', false) : translate('no', false)}
          </div>
        )}
        {place.parking !== undefined && (
          <div>
            {translate('parking', false)} : {place.parking === 'yes' ? translate('yes', false) : translate('no', false)}
          </div>
        )}
        {place.services !== undefined && (
          <div>
            {translate('other-services', false)} :&nbsp;
            {place.services
              .split(';')
              .map(s => translate(s, false))
              .join(', ')}
          </div>
        )}
      </PlaceInfoContent>
    </PlaceInfo>
  )
}

// ["poi_type:amenity:car_rental", "poi_type:amenity:citiz"].includes(place.cat_id)
export const UICitiz = props => {
  const { place, displayon } = props

  if (!place || (!place.stand && displayon === 'map')) {
    return null
  }

  const verbose = ['arras-artis'].includes(REACT_APP_PROJECT)

  if (verbose) {
    return (
      <>
        {(place.stand?.available_cars && place.stand?.available_cars !== 0) ||
        (place.stand?.available_electric_cars && place.stand?.available_electric_cars !== 0) ? (
          <>
            {place.stand?.available_cars !== 0 && (
              <PlaceInfo
                tabIndex="0"
                aria-label={`${place.stand?.available_cars} ${translate('cars-available', false)}`}>
                <PlaceInfoFlex>
                  <PlaceInfoTitle className="lc-place-info-title">{translate('nb-car-available')}&nbsp;</PlaceInfoTitle>
                  <PlaceInfoContent data-realtime-seats>
                    {place.stand?.available_cars}
                    <PictoBike
                      src={assetsPath('/assets/images/modes/citiz-car.svg')}
                      alt={translate('cars-available', false)}
                      aria-hidden="true"
                    />
                  </PlaceInfoContent>
                </PlaceInfoFlex>
              </PlaceInfo>
            )}
            {place.stand?.available_electric_cars !== 0 && (
              <PlaceInfo
                tabIndex="0"
                aria-label={`${place.stand?.available_electric_cars} ${translate('electrics-cars-available', false)}`}>
                <PlaceInfoFlex>
                  <PlaceInfoTitle className="lc-place-info-title">
                    {translate('nb-electric-car-available')}&nbsp;
                  </PlaceInfoTitle>
                  <PlaceInfoContent data-realtime-seats>
                    {place.stand?.available_electric_cars}
                    <PictoBike
                      src={assetsPath('/assets/images/modes/citiz-electric-car.svg')}
                      alt={translate('electrics-cars-available', false)}
                      aria-hidden="true"
                    />
                  </PlaceInfoContent>
                </PlaceInfoFlex>
              </PlaceInfo>
            )}
          </>
        ) : place.stand?.available_cars === 0 ? (
          <PlaceInfo>
            <PlaceInfoContent className="lc-place-info-content">
              <PlaceRealtimeInfo data-realtime-seats>{translate('no-available-car')}</PlaceRealtimeInfo>
            </PlaceInfoContent>
          </PlaceInfo>
        ) : (
          <PlaceInfo>
            <PlaceInfoContent className="lc-place-info-content">
              <PlaceRealtimeInfo>{translate('no-informations-for-available-cars')}</PlaceRealtimeInfo>
            </PlaceInfoContent>
          </PlaceInfo>
        )}
      </>
    )
  }

  // beatifull version :

  if (place.stand?.merge_all_available_cars === true) {
    return (
      <PlaceInfo
        tabIndex="0"
        aria-label={`${place.stand?.available_cars + place.stand?.available_electric_cars} ${translate(
          'cars-available',
          false,
        )}`}>
        <PlaceInfoFlex>
          <PlaceInfoContent data-realtime-seats>
            <PictoBike
              className="lc-citiz-car-picto"
              src={assetsPath('/assets/images/modes/citiz-car.svg')}
              alt={translate('cars-available', false)}
              aria-hidden="true"
            />
            {translate('nb-car-available')}&nbsp;
            {place.stand?.available_cars}
          </PlaceInfoContent>
        </PlaceInfoFlex>
      </PlaceInfo>
    )
  }

  return (
    <PlaceInfo
      tabIndex="0"
      aria-label={
        place.stand
          ? `${place.stand?.available_cars ? place.stand?.available_cars : 0} ${translate('cars-available', false)}. ${
              place.stand.available_electric_cars ? place.stand.available_electric_cars : 0
            } ${translate('electrics-cars-available', false)}`
          : translate('no-informations-for-available-cars', false)
      }>
      <PlaceInfoTitle className="lc-place-info-title">{translate('nb-car-available')}</PlaceInfoTitle>
      <PlaceInfoContent className="lc-place-info-content">
        {(place.stand?.available_cars && place.stand?.available_cars !== 0) ||
        (place.stand?.available_electric_cars && place.stand?.available_electric_cars !== 0) ? (
          <PlaceCars data-realtime-seats>
            <PlaceCar>
              {place.stand?.available_cars}
              <Tippy
                theme={'latitude'}
                touch={['hold', 500]}
                placement={'right'}
                boundary="window"
                content={translate('car')}>
                <img
                  src={assetsPath('/assets/images/modes/citiz-car.svg')}
                  alt={translate('cars-available', false)}
                  aria-hidden="true"
                />
              </Tippy>
            </PlaceCar>
            <PlaceCar>
              {place.stand?.available_electric_cars}
              <Tippy
                theme={'latitude'}
                touch={['hold', 500]}
                placement={'right'}
                boundary="window"
                content={translate('electric-car')}>
                <img
                  src={assetsPath('/assets/images/modes/citiz-electric-car.svg')}
                  alt={translate('electrics-cars-available', false)}
                  aria-hidden="true"
                />
              </Tippy>
            </PlaceCar>
            <Tippy
              theme={'latitude'}
              touch={['hold', 500]}
              placement={'right'}
              boundary="window"
              content={translate('realtime-gif-title')}>
              <img
                src={assetsPath('/assets/images/realtime.gif')}
                alt={translate('realtime-gif-alt', false)}
                aria-hidden="true"
                className="lc-realtime-gif"
              />
            </Tippy>
          </PlaceCars>
        ) : place.stand?.available_cars === 0 ? (
          <PlaceRealtimeInfo data-realtime-seats>{translate('no-available-car')}</PlaceRealtimeInfo>
        ) : (
          <PlaceRealtimeInfo>{translate('no-informations-for-available-cars')}</PlaceRealtimeInfo>
        )}
      </PlaceInfoContent>
    </PlaceInfo>
  )
}

export const UILoading = props => {
  const { place, displayon } = props

  if (displayon === 'map') {
    return null
  }

  if (
    !place.stand &&
    ['poi_type:amenity:park_ride', 'poi_type:amenity:parking'].includes(place.cat_id) &&
    !(place instanceof BikeInterface)
  ) {
    return (
      <div className="lc-loading" data-lc-loading>
        <img src={assetsPath('/assets/images/loading.gif')} width={20} alt={translate('back')} aria-hidden="true" />
      </div>
    )
  } else {
    return null
  }
}

export const UIOpeningHours = props => {
  const { place, language } = props

  if (!place.opening_hours && !place.opening_hours_txt) {
    return null
  }

  if (place.opening_hours_txt) {
    return (
      <PlaceInfoOpeningHours>
        <div className="lc-opening-hours" aria-label={translate('open-hour-title', false) + place.opening_hours_tx}>
          <div className="lc-opening-hours-title">{translate('open-hour-title')}</div>
          <div className="lc-opening-hours-list">{place.opening_hours_txt}</div>
        </div>
      </PlaceInfoOpeningHours>
    )
  }

  return <PlaceInfoOpeningHours>{humanReadableOpeningHours(place.opening_hours, language)}</PlaceInfoOpeningHours>
}

export const UIAddress = props => {
  const { place, displayon } = props

  const showAddress =
    (place.address || place.city) &&
    ![
      'poi_type:p+r',
      'poi_type:p+r-reduced',
      'poi_type:alpin_skiing',
      'poi_type:cross_country_skiing',
      'poi_type:sled',
      'poi_type:snowshoeing',
      'poi_type:stations',
    ].includes(place.cat_id)

  if (!showAddress || (place.cat_id === 'poi_type:vcub' && displayon === 'map')) {
    return null
  }

  if (place.cat_id === 'poi_type:vcub') {
    place.cp = null
    place.city = null
  }

  return (
    <PlaceInfo
      className={`lc-place-info-adress lc-displayon-${displayon}`}
      tabIndex="0"
      aria-label={translate('address-title', false) + ' ' + place.address + ' ' + place.cp + ' ' + place.city}>
      <PlaceInfoTitle className="lc-place-info-title">{translate('address-title')}</PlaceInfoTitle>
      <PlaceInfoContent className="lc-place-info-content">
        {place.address}
        {(place.cp || place.city) && (
          <>
            {place.address && <br />} {place.cp} {place.city}
          </>
        )}
      </PlaceInfoContent>
    </PlaceInfo>
  )
}

export const UIEmail = props => {
  const { place } = props

  if (!place.email) {
    return null
  }

  return (
    <PlaceInfo tabIndex="0" aria-label={translate('mail-title') + ' ' + place.email}>
      <PlaceInfoTitle className="lc-place-info-title">{translate('mail-title')}</PlaceInfoTitle>
      <PlaceInfoContent className="lc-place-info-content">
        <a
          href={`mailto:${place.email}`}
          onClick={e => {
            e.stopPropagation()
          }}>
          {place.email}
        </a>
      </PlaceInfoContent>
    </PlaceInfo>
  )
}

export const UIPhone = props => {
  const { place } = props

  if (!place.phone) {
    return null
  }

  return (
    <PlaceInfo tabIndex="0" aria-label={translate('phone-title', false) + ' ' + place.phone}>
      <PlaceInfoTitle className="lc-place-info-title">{translate('phone-title')}</PlaceInfoTitle>
      <PlaceInfoContent className="lc-place-info-content">
        <a
          href={`tel:${place.phone}`}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            return false
          }}>
          {place.phone}
        </a>
      </PlaceInfoContent>
    </PlaceInfo>
  )
}

export const UIWebsite = props => {
  const { place } = props

  if (!place.website) {
    return null
  }

  return (
    <PlaceInfo tabIndex="0" aria-label={translate('website-title', false) + ' ' + place.website}>
      <PlaceInfoTitle className="lc-place-info-title">{translate('website-title')}</PlaceInfoTitle>
      <PlaceInfoContent className="lc-place-info-content">
        <a href={place.website} target="_blank" rel="noopener noreferrer">
          {place.website}
        </a>
      </PlaceInfoContent>
    </PlaceInfo>
  )
}

export const UIWheelchair = props => {
  const { place } = props

  if (!place.wheelchair) {
    return null
  }

  return (
    <PlaceInfo
      tabIndex="0"
      aria-label={
        translate('wheelchair-title', false) +
        ' ' +
        (place.wheelchair === 'yes'
          ? translate('wheelchair-yes', false)
          : place.place === 'no'
          ? translate('wheelchair-no', false)
          : translate('wheelchair-limited', false))
      }>
      <PlaceInfoFlex>
        <PlaceInfoTitle className="lc-place-info-title">{translate('wheelchair-title')}</PlaceInfoTitle>
        <PlaceInfoContent className="lc-place-info-content">
          &nbsp;
          {place.wheelchair === 'yes'
            ? translate('wheelchair-yes')
            : place.wheelchair === 'no'
            ? translate('wheelchair-no')
            : translate('wheelchair-limited')}
        </PlaceInfoContent>
      </PlaceInfoFlex>
    </PlaceInfo>
  )
}

export const UIInfo = props => {
  const { place } = props

  if (!place.info || ['poi_type:p+r', 'poi_type:p+r-reduced', 'poi_type:company'].includes(place.cat_id)) {
    return null
  }

  return <PlaceInfoContent className="lc-info">{place.info}</PlaceInfoContent>
}

export const UICustomText = props => {
  const { place } = props

  if (!place || !place.displayCustomText) {
    return null
  }

  if (place.cat_id === 'poi_type:amenity:bicycle_rental' && place.stand) {
    return null
  }

  return (
    <PlaceInfo>
      <PlaceInfoContent className="lc-place-info-content">
        {place.displayCustomTextFormat === 'html' ? (
          <CustomText
            className="lc-display-custom-text"
            dangerouslySetInnerHTML={{ __html: translate(place.displayCustomText, false) }}></CustomText>
        ) : (
          <CustomText className="lc-display-custom-text">
            <img src={assetsPath('/assets/images/information.svg')} alt="information" />
            {translate(place.displayCustomText)}
          </CustomText>
        )}
      </PlaceInfoContent>
    </PlaceInfo>
  )
}

export const UIDistributeur = props => {
  const { place } = props

  if (!place && !place.buy_tickets && !place.reload_card && !place.solidarity_pricing) {
    return null
  }

  if (REACT_APP_PROJECT?.startsWith('bordeaux-tbm')) {
    return (
      <>
        <PlaceInfo
          tabIndex="0"
          aria-label={
            translate('title-buy-tickets', false) +
            ' ' +
            (place.buy_tickets ? translate('yes', false) : translate('no', false))
          }>
          <PlaceInfoInline>
            <PlaceInfoTitle className="lc-place-info-title">{translate('title-buy-tickets')}</PlaceInfoTitle>
            <PlaceInfoContent className="lc-place-info-content">
              &nbsp;{place.buy_tickets ? translate('yes') : translate('no')}
            </PlaceInfoContent>
          </PlaceInfoInline>
        </PlaceInfo>
        <PlaceInfo
          tabIndex="0"
          aria-label={
            translate('title-reload-card', false) +
            ' ' +
            (place.reload_card ? translate('yes', false) : translate('no', false))
          }>
          <PlaceInfoInline>
            <PlaceInfoTitle className="lc-place-info-title">{translate('title-reload-card')}</PlaceInfoTitle>
            <PlaceInfoContent className="lc-place-info-content">
              &nbsp;{place.reload_card ? translate('yes') : translate('no')}
            </PlaceInfoContent>
          </PlaceInfoInline>
        </PlaceInfo>
        <PlaceInfo
          tabIndex="0"
          aria-label={
            translate('title-solidarity-pricing', false) +
            ' ' +
            (place.solidarity_pricing ? translate('yes', false) : translate('no', false))
          }>
          <PlaceInfoInline>
            <PlaceInfoTitle className="lc-place-info-title">{translate('title-solidarity-pricing')}</PlaceInfoTitle>
            <PlaceInfoContent className="lc-place-info-content">
              &nbsp;{place.solidarity_pricing ? translate('yes') : translate('no')}
            </PlaceInfoContent>
          </PlaceInfoInline>
        </PlaceInfo>
      </>
    )
  } else {
    return null
  }
}

export const UIToilet = props => {
  const { place } = props

  if (!place) {
    return null
  }

  return (
    <PlaceInfo>
      {place.pmr && <PlaceInfoTitle className="lc-place-info-title">{translate('aria-lines-stop-pmr')}</PlaceInfoTitle>}
    </PlaceInfo>
  )
}

export const UILocker = props => {
  const { place } = props

  if (!place) {
    return null
  }

  return <></>
}

export const UIMotorcycleParking = props => {
  const { place } = props

  if (!place) {
    return null
  }

  return (
    <PlaceInfo>
      <PlaceInfoFlex>
        <PlaceInfoTitle className="lc-place-info-title">{translate('total-places')}</PlaceInfoTitle>
        &nbsp;<PlaceInfoContent className="lc-place-info-content">{place.capacity}</PlaceInfoContent>
      </PlaceInfoFlex>
    </PlaceInfo>
  )
}

export const UIWaterPoint = props => {
  const { place } = props

  if (!place) {
    return null
  }

  return <></>
}

export const UIDescription = props => {
  const { place } = props

  if (!place?.description) {
    return null
  }

  return (
    <PlaceInfo className="lc-place-description">
      <PlaceInfoFlex>
        <PlaceInfoTitle>{translate('place-description')}</PlaceInfoTitle>
        <PlaceInfoContent
          className="lc-place-info-content"
          dangerouslySetInnerHTML={{ __html: place.description }}></PlaceInfoContent>
      </PlaceInfoFlex>
    </PlaceInfo>
  )
}

export const UICondAccSpe = props => {
  const { place } = props

  if (!place?.cond_acc_spec) {
    return null
  }

  return (
    <PlaceInfo className="lc-place-cond-acc-spe">
      <PlaceInfoFlex>
        <PlaceInfoTitle>{translate('place-cond_acc_spec')}</PlaceInfoTitle>
        <PlaceInfoContent
          className="lc-place-info-content"
          dangerouslySetInnerHTML={{ __html: place.cond_acc_spec }}></PlaceInfoContent>
      </PlaceInfoFlex>
    </PlaceInfo>
  )
}

export const UIBAV = props => {
  const { place } = props

  if (!place) {
    return null
  }

  return (
    <PlaceInfo tabIndex="0" aria-label={translate('bav-title', false) + ' ' + place.flow1 + ' ' + place.flow2}>
      <PlaceInfoTitle className="lc-place-info-title">{translate('bav-title')}</PlaceInfoTitle>
      <PlaceInfoContent className="lc-place-info-content">
        {place.flow1}
        <br />
        {place.flow2}
      </PlaceInfoContent>
    </PlaceInfo>
  )
}

export const UIHiking = props => {
  const { place, displayon } = props

  if (!place) {
    return null
  }

  return (
    <>
      {displayon !== 'map' && (
        <>
          <PlaceInfo
            tabIndex="0"
            aria-label={
              translate('hiking-desc', false) +
              ' ' +
              place.desc
                .replace(/(<([^>]+)>)/gi, '')
                .replace('&lt;', /</g)
                .replace('&gt;', />/g)
                .replace('&#39;', /'/g)
                .replace('&#34;', /"/g)
                .replace('&#96;', /`/g)
                .replace('&#61;', /=/g)
                .replace('&amp;', /&/g)
            }>
            <PlaceInfoTitle className="lc-place-info-title">{translate('hiking-desc')}</PlaceInfoTitle>
            <PlaceInfoContent dangerouslySetInnerHTML={{ __html: place.desc }}></PlaceInfoContent>
          </PlaceInfo>
          <br />
        </>
      )}
      <PlaceInfo>
        <PlaceInfoInline tabIndex="0" aria-label={translate('hiking-distance', false) + ' ' + place.distance}>
          <PlaceInfoTitle className="lc-place-info-title">{translate('hiking-distance')}</PlaceInfoTitle>
          <PlaceInfoContent className="lc-place-info-content"> {place.distance}</PlaceInfoContent>
        </PlaceInfoInline>
        <PlaceInfoInline tabIndex="0" aria-label={translate('hiking-duration', false) + ' ' + place.duration}>
          <PlaceInfoTitle className="lc-place-info-title">{translate('hiking-duration')}</PlaceInfoTitle>
          <PlaceInfoContent className="lc-place-info-content"> {place.duration}</PlaceInfoContent>
        </PlaceInfoInline>
        <PlaceInfoInline tabIndex="0" aria-label={translate('hiking-difficulty', false) + ' ' + place.difficulty}>
          <PlaceInfoTitle className="lc-place-info-title">{translate('hiking-difficulty')}</PlaceInfoTitle>
          <PlaceInfoContent className="lc-place-info-content"> {place.difficulty}</PlaceInfoContent>
        </PlaceInfoInline>
      </PlaceInfo>
    </>
  )
}

export const UIEntrance = props => {
  const { place, lines, stops, displayon } = props

  if (!place) {
    return null
  }

  const firstLine = lines.find(fl => fl.id === place.lines[0])

  return (
    <PlaceInfo>
      <EntranceLines displayon={displayon}>
        {firstLine && (
          <img
            className="lc-entrance-mode"
            src={assetsPath(`/assets/images/route-calculation/modes/${firstLine.cat}.svg`)}
            alt={firstLine.cat}
            aria-hidden="true"
          />
        )}
        {place.lines?.map(l => {
          const findLine = lines.find(fl => fl.id === l)

          const findStop = stops.find(
            s => s.stop_area === place.stop_area && s.lines.map(r => r.id).includes(findLine.id),
          )

          if (findLine && findStop) {
            const findRoute = findStop.lines.find(r => r.id === findLine.id)

            return (
              <UIDisruptedLineOverlay
                key={`entrance-line-${findLine.code}`}
                line={findLine}
                styleLine={REACT_APP_LINES_MAIN_TYPE}
                inpopup={false}>
                <UILine
                  line={findLine}
                  image={process.env.REACT_APP_LINES_MAIN_TYPE.includes('image')}
                  onClick={() => {
                    history.push({
                      pathname: '/lines',
                      search: `?current=${findRoute?.route_id ? findRoute.route_id : findLine.id}&stop=${findStop.id}`,
                    })
                    return false
                  }}
                  onKeyUp={e =>
                    handleKeyUp(e, () => {
                      history.push({
                        pathname: '/lines',
                        search: `?current=${findRoute?.route_id ? findRoute.route_id : findLine.id}&stop=${
                          findStop.id
                        }`,
                      })
                    })
                  }
                />
              </UIDisruptedLineOverlay>
            )
          } else {
            return <></>
          }
        })}
      </EntranceLines>
      {(place.staircase === true || place.escalator === true || place.elevator === true) && (
        <>
          <VerticalDivider />
          <EntranceAccess displayon={displayon}>
            {place.staircase === true && (
              <img
                className="lc-access-staircase"
                alt="staircase"
                src={assetsPath('assets/images/access/access_staircase.svg')}
              />
            )}
            {place.escalator === true && (
              <img
                className="lc-access-escalator"
                alt="escalator"
                src={assetsPath('assets/images/access/access_escalator.svg')}
              />
            )}
            {place.elevator === true && (
              <img
                className="lc-access-elevator"
                alt="elevator ok"
                src={assetsPath('assets/images/access/access_elevator.svg')}
              />
            )}
          </EntranceAccess>
        </>
      )}
    </PlaceInfo>
  )
}

export const UICommercantPartenaire = props => {
  const { place } = props

  if (!place) {
    return null
  }

  return (
    <PlaceInfo>
      <PlaceInfoFlex>
        <PlaceInfoContent className="lc-place-info-content">
          <img src={assetsPath('/assets/images/places/' + place.product + '.svg')} alt="" height="25" />
        </PlaceInfoContent>
        &nbsp;
        <PlaceInfoTitle className="lc-place-info-title lc-place-info-title-commercant-partenaire">
          {translate(place.product)}
        </PlaceInfoTitle>
      </PlaceInfoFlex>
    </PlaceInfo>
  )
}

export const UIPointDeVente = props => {
  const { place } = props

  if (!place) {
    return null
  }

  return (
    <PlaceInfo>
      {place.type && (
        <PlaceInfoInline tabIndex="0" aria-label={translate('place-type', false) + ' : ' + place.type}>
          <PlaceInfoTitle>{translate('place-type')}</PlaceInfoTitle>
          <PlaceInfoContent>&nbsp;{place.type}</PlaceInfoContent>
        </PlaceInfoInline>
      )}
      {place.offer && (
        <PlaceInfoInline
          tabIndex="0"
          aria-label={translate('place-offer', false) + place.offer}
          style={{ marginTop: '10px' }}>
          <PlaceInfoTitle>{translate('place-offer')}</PlaceInfoTitle>
          <PlaceInfoContent>&nbsp;{place.offer}</PlaceInfoContent>
        </PlaceInfoInline>
      )}
      {place.zones_sold && (
        <PlaceInfoInline
          tabIndex="0"
          aria-label={translate('place-zones-sold', false) + place.zones_sold}
          style={{ marginTop: '10px' }}>
          <PlaceInfoTitle>{translate('place-zones-sold')}</PlaceInfoTitle>
          <PlaceInfoContent>&nbsp;{place.zones_sold}</PlaceInfoContent>
        </PlaceInfoInline>
      )}
      {place.networks_sold && (
        <PlaceInfoInline
          tabIndex="0"
          aria-label={translate('place-networks-sold', false) + place.zones_sold}
          style={{ marginTop: '10px' }}>
          <PlaceInfoTitle>{translate('place-networks-sold')}</PlaceInfoTitle>
          <PlaceInfoContent>&nbsp;{place.networks_sold.join(', ')}</PlaceInfoContent>
        </PlaceInfoInline>
      )}
    </PlaceInfo>
  )
}

export const UIChargingStation = props => {
  const { place } = props

  if (!place) {
    return null
  }

  return (
    <PlaceInfo>
      <PlaceInfoInline tabIndex="0" aria-label={translate('place-type', false) + ' : ' + place.type}>
        <PlaceInfoTitle className="lc-place-info-title">Borne(s) présente(s) :</PlaceInfoTitle>
        <PlaceInfoContent>&nbsp;{place.nb_bornes}</PlaceInfoContent>
      </PlaceInfoInline>
      {place.prise_types.length > 0 ? (
        <ChargingStationTypes tabIndex="0" aria-label={translate('place-type', false) + ' : ' + place.type}>
          <PlaceInfoTitle className="lc-place-info-title">Type de prise(s) :</PlaceInfoTitle>
          {place.prise_types.map(pt => (
            <Tippy
              theme={'latitude'}
              touch={['hold', 500]}
              placement={'right'}
              boundary="window"
              content={translate(`charging_station_${pt}`, false)}
              key={pt}>
              <img src={assetsPath(`/assets/images/places/charging_station_${pt}.svg`)} alt={pt} aria-hidden="true" />
            </Tippy>
          ))}
        </ChargingStationTypes>
      ) : null}
    </PlaceInfo>
  )
}
