import Tippy from '@tippy.js/react'
import axios from '../middlewares/axios'
import L from 'leaflet'
import { Polyline } from 'react-leaflet'
import { luminance } from 'luminance-js'
import { Fragment } from 'react'
import { GeoJSON, Marker, Popup, Tooltip } from 'react-leaflet'
import { UILine } from '../components/styled/UILine'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import { actionSetOpenedCollapse, actionSetPlaceClicked } from '../actions/board'
import {
  actionMarkerClick,
  actionSetBikePaths,
  actionSetCluster,
  actionSetEntranceMapMarkers,
  actionSetEntrancePopup,
  actionSetHeavyLines,
  actionSetComplementaryLines,
  actionSetCustomLines,
  actionSetMapBikes,
  actionSetCustomMarkerEvent,
  actionSetLinesToDisplay,
  actionSetAllLinesSelected,
  actionSetCustomMarkers,
  actionSetComplementaryLine,
} from '../actions/map'
import {
  actionBuildMapPlaces,
  actionBuildTransportPlaces,
  actionOnLineSelected,
  actionOpenMarker,
  actionOutMarker,
  actionOverMarker,
} from '../actions/withRedux'
import history from '../history'
import BikeInterface from '../interfaces/BikeInterface'
import { appStore } from '../store'
import { groupLinesByMode, sortBy, sortLinesPerPosition } from '../utils/leaflet/tools'
import { getLinesModes, navitiaDateToDate } from '../utils/tools'
import { updateMapEvents, removeMapEvents, fitBounds } from '../utils/leaflet/map'
import {
  buildPlaceIconClassName,
  clickOnPlaceInList,
  envVarToBool,
  flattenObject,
  getLine,
  getRef,
  getURLSearchParams,
  goToRouteCalculation,
  isNotToClusterised,
  mostImportantGroup,
  unique,
  updatePopupPosition,
  assetsPath,
  handleKeyUp,
  addGetParam,
  translate,
  isActiveModule,
  goToAround,
  goToStopPoint,
} from './tools'
import { message } from './message'
import UIPoiContent from '../components/styled/UIPoiContent'
import ptld from '@turf/point-to-line-distance'
import UIIntersecPopup from '../components/styled/UIIntersecPopup'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import UIStopPmr from '../components/styled/UIStopPmr'
import UIDisruptedLineOverlay from '../components/styled/UIDisruptedLineOverlay'
import UIPopupLinesByStop from '../components/styled/UIPopupLinesByStop'
import UIPopupLinesByArea from '../components/styled/UIPopupLinesByArea'

const {
  REACT_APP_LINES_MAIN_TYPE,
  REACT_APP_LINES_TYPE_EXCEPTIONS,
  REACT_APP_SHOW_PMR,
  REACT_APP_SHOW_ADDITIONAL_STOP_TOOL,
  REACT_APP_CONNECTIONS_TEXT,
  REACT_APP_PROXIMITY_LINES_AT_STOP,
  REACT_APP_GO_TO_RC_URL,
  REACT_APP_DEFAULT_LINES_WEIGHT,
  REACT_APP_ALLOW_HEAVY_LINES_ON_ROUTECALCULATION,
  REACT_APP_DISRUPTION,
  REACT_APP_CLICK_THROUGH_LINES,
  REACT_APP_HEAVY_LINES,
  REACT_APP_TERMINUS_ENTRYMAP_OVERRIDE,
  REACT_APP_TERMINUS_DISPLAY_LINES,
  REACT_APP_AREAS_ZOOM_LEVEL,
  REACT_APP_INIT_MAP_ON_BACK,
  REACT_APP_BLOCK_TERMINUS,
  REACT_APP_HEAVY_MODES,
  REACT_APP_DISPLAY_STOP_ID,
  REACT_APP_DISPLAY_ADDITIONAL_INFOS_ON_POPUP_CONTENT,
  REACT_APP_MARKER_SELECTED_PICTO,
  REACT_APP_POPUP_GROUP_LINES_BY_CAT,
  REACT_APP_POPUP_GROUP_LINES_BY_CAT_MERGE_CAT,
  REACT_APP_TERMINUS_SHOW_ONLY_LINES,
  REACT_APP_TERMINUS_ENTRYMAP_NO_PERMANENT_TOOLTIP,
} = process.env

export const buildBikePaths = async (files, hidePaths = {}) => {
  const requests = []

  for (const file of files) {
    if (!hidePaths[file.id]) {
      const options = {
        color: file.style.color,
        opacity: 1,
        weight: file.style.size,
        dashArray: file.style.dashArray,
        lineJoin: 'round',
      }

      requests.push(
        axios.get(`/api/file?name=${file.id}&folder=${'map/bike'}&ext=geojson`).then(response => {
          return <GeoJSON interactive={false} key={file.id} data={response.data} style={options} />
        }),
      )
    }
  }

  Promise.all(requests).then(paths => {
    appStore.dispatch(actionSetBikePaths(paths))
  })
}

export const buildEntranceMap = async (geojson, map) => {
  try {
    const { lines, configApp } = appStore.getState().app

    if (!geojson || !Object.keys(geojson).length) {
      message({ error: 'geojson_entrance_map_not_found' })
      return
    }

    const config = appStore.getState()?.app?.config
    const markers = []

    if (map.mapReference.current) {
      map = map.mapReference.current.leafletElement
    }

    const jalonsLinesCodes = []

    if (REACT_APP_HEAVY_LINES && typeof JSON.parse(REACT_APP_HEAVY_LINES) !== 'boolean') {
      for (const line of JSON.parse(REACT_APP_HEAVY_LINES)) {
        const heavyLine = lines.find(
          l => l.id === line || (l.cat === line.split('~~')[0] && l.code === line.split('~~')[1]),
        )

        if (heavyLine) {
          jalonsLinesCodes.push(heavyLine.code.toLowerCase())
        }
      }
    }
    if (REACT_APP_HEAVY_MODES) {
      const heavyModes = JSON.parse(REACT_APP_HEAVY_MODES)
      for (const line of lines) {
        if (
          heavyModes.find(hm => hm.mode === line.mode) &&
          !jalonsLinesCodes.find(jl => jl === line.code.toLowerCase())
        ) {
          jalonsLinesCodes.push(line.code.toLowerCase())
        }
      }
    }

    for (const feature of geojson.features) {
      const type = feature.properties.type

      // Don't display "jalon" if no heavyLines are drawn
      if (config.heavyLines === false) {
        if (type === 'jalon') {
          continue
        }
      }

      if (feature.geometry.type.includes('String') && type !== 'lines') {
        markers.push(
          <GeoJSON
            key={Math.random()}
            data={feature}
            style={{
              opacity: 0,
              weight: 18,
            }}
            onMouseMove={e => {
              const storedPopup = appStore.getState().map.entrancePopup
              const popup = e.target.getPopup()

              if (!storedPopup || storedPopup._leaflet_id !== popup._leaflet_id) {
                popup.setLatLng(e.latlng).openOn(map)
              }
            }}
            onMouseOut={e => {
              const storedPopup = appStore.getState().map.entrancePopup
              const popup = e.target.getPopup()

              if (!storedPopup || storedPopup._leaflet_id !== popup._leaflet_id) {
                e.target.closePopup()
              }
            }}
            onClick={e => {
              map.eachLayer(layer => layer.closePopup())
              const popup = e.target.getPopup()

              appStore.dispatch(actionSetEntrancePopup(popup))
              popup.setLatLng(e.latlng).openOn(map)
            }}>
            <Popup
              className={'lc-popup-leaflet'}
              closeButton={false}
              autoClose={false}
              autoPan={false}
              onClose={() => appStore.dispatch(actionSetEntrancePopup(null))}>
              {buildPopup(
                appStore.getState(),
                {
                  lines: feature.properties.desserte.split(';').map(line => ({
                    code: line.split('_')[0],
                    network: line.split('_')[1],
                  })),
                },
                true,
              )}
            </Popup>
          </GeoJSON>,
        )

        if (type === 'hover') {
          markers.push(
            <GeoJSON
              key={Math.random()}
              data={feature}
              style={{
                color: '#' + feature.properties.color,
                weight: feature.properties.weight,
              }}
            />,
          )
        }
      } else {
        if (
          type === 'image' ||
          (type === 'jalon' &&
            ((jalonsLinesCodes.length > 0 &&
              feature.properties.image
                ?.toLowerCase()
                ?.split('-')
                ?.every(i => jalonsLinesCodes.includes(i))) ||
              jalonsLinesCodes.length === 0))
        ) {
          markers.push(
            <Marker
              key={`jalon-${Math.random()}`}
              interactive={false}
              position={[feature.geometry.coordinates[1], feature.geometry.coordinates[0]]}
              icon={L.icon({
                iconUrl: assetsPath('/assets/images/lines/entrance/') + feature.properties.image + '.svg',
                iconSize: feature.properties.size,
                iconAnchor: feature.properties.anchor,
              })}
              zIndexOffset={50}
              zoom={feature.properties.zoom}
            />,
          )
        }

        if (type === 'lines') {
          markers.push(
            <GeoJSON
              key={`entrance-map-lines-${Math.random()}`}
              interactive={true}
              className={'lc-entrance-map-lines'}
              data={feature}
              style={{
                color: '#' + feature.properties.color,
                weight: feature.properties.weight,
              }}
              zoom={feature.properties.zoom}
            />,
          )
        }
      }
    }

    if (REACT_APP_HEAVY_LINES?.length > 0 && typeof JSON.parse(REACT_APP_HEAVY_LINES) !== 'boolean') {
      const markersTerminus = await buildTerminusByLines(
        configApp?.heavy_lines_from_lines
          ? lines.filter(l => l.heavyline).map(l => l.id)
          : JSON.parse(REACT_APP_HEAVY_LINES),
      )

      if (markersTerminus && markersTerminus.length > 0) {
        markers.push(...markersTerminus)
      }
    }

    setTimeout(() => appStore.dispatch(actionSetEntranceMapMarkers(markers)))
  } catch (e) {
    console.log('Error in build entrance map : ', e)
  }
}

/*
 * Attention renvoie un tableau de promisse, et le dernier élément est un tableau de promisse
 */
export const buildCompleteLines = (
  lines,
  config = {
    clickThroughLines: envVarToBool(REACT_APP_CLICK_THROUGH_LINES) === true,
  },
) => {
  const request = []
  const { hash, configApp } = appStore.getState().app

  for (let i = 0; i < lines.length; i++) {
    request.push(buildLinePath(lines[i], hash))
    if (config?.clickThroughLines && !lines[i].tad) {
      request.push(buildLinePath({ ...lines[i], clickThrough: config?.clickThroughLines }, hash))
    }
    if (configApp?.lines_decorators) {
      request.push(buildDecoratorsByLine(lines[i]))
    }
  }

  if (config?.hideTerminus !== true) {
    request.push(buildTerminusByLines(lines.map(l => l.id)))
  }

  return request
}

export const buildTerminusByLines = async terminusLines => {
  const markers = []

  if (terminusLines?.length > 0) {
    const { areas, hash, lines } = appStore.getState().app

    const overridesTerm =
      (REACT_APP_TERMINUS_ENTRYMAP_OVERRIDE && JSON.parse(REACT_APP_TERMINUS_ENTRYMAP_OVERRIDE)) || []

    const createTerms = overridesTerm.filter(t => t?.action === 'create')
    const terminus = []

    for (const line of terminusLines) {
      const [mode, code] = line.split('~~')

      const l = getLine(
        lines,
        mode && code
          ? {
              code,
              mode,
            }
          : {
              id: line,
              direction_id: 'f',
            },
      )

      if (l && l.routes && l.routes[0] && l.routes[0].id) {
        const stops = await axios.get(`/api/file?name=${l?.routes[0]?.id}~${hash}&folder=stops&ext=json`)

        if (stops && stops.data) {
          for (const stop of stops.data.filter(
            s => createTerms.find(t => t.id === s.stop_area.replace(/:/g, '')) || (s.terminus && !s.terminusInt),
          )) {
            let area = areas.find(a => a.id === stop.stop_area)

            if (area) {
              let override = null

              if (REACT_APP_TERMINUS_ENTRYMAP_OVERRIDE) {
                let termToOverride = overridesTerm.find(o => o.id === area.id.replace(/:/g, ''))

                if (termToOverride) {
                  if (termToOverride.action) {
                    if (termToOverride.action === 'create') {
                      override = termToOverride
                    } else {
                      continue
                    }
                  }

                  if (termToOverride.mergeWith) {
                    const termToMerge = terminus.find(t => t.id === `terminus-${termToOverride.mergeWith}`)

                    if (termToMerge) {
                      termToMerge.lines.push(line)
                      continue
                    } else {
                      area = areas.find(a => a.id.replace(/:/g, '') === termToOverride.mergeWith)
                      override = overridesTerm.find(o => o.id === area.id.replace(/:/g, ''))
                    }
                  } else {
                    override = termToOverride
                  }
                }
              }

              if (override?.action === 'delete') {
                continue
              }

              const existIndex = terminus.findIndex(t => {
                return t.id === `terminus-${area.id.replace(/:/g, '')}`
              })

              // check if already exist
              if (existIndex !== -1) {
                if (!terminus[existIndex].lines.includes(line)) {
                  const term = terminus[existIndex]

                  if (!term.lines.includes(l.id)) {
                    term.lines.push(l.id)
                  }

                  if (!term.direction.includes('right') && !override.color) {
                    term.color = l.color
                  }
                }
              } else {
                terminus.push({
                  id: `terminus-${area.id.replace(/:/g, '')}`,
                  name: override?.name || area.name,
                  lines: [l.id],
                  coord: area.coord,
                  color: override?.color || l.color,
                  direction: override?.direction || 'right',
                  class: override?.class || '',
                  displayLines: REACT_APP_TERMINUS_DISPLAY_LINES
                    ? envVarToBool(REACT_APP_TERMINUS_DISPLAY_LINES)
                    : true,
                  dontRenderTooltip: override?.tooltip === false,
                })
              }
            }
          }
        }
      }
    }

    if (!envVarToBool(REACT_APP_BLOCK_TERMINUS)) {
      // console.log(
      //   JSON.stringify(terminus.map(term => ({ id: term.id.replace('terminus-', ''), direction: term.direction }))),
      // )

      for (const term of terminus) {
        // console.log(term.name, term.id.replace('terminus-', ''), term.direction) // facilit searching terminus to override it
        // console.log(JSON.stringify({ id: term.id.replace('terminus-', ''), direction: term.direction }))
        let anchorX = 0
        let anchorY = 0

        switch (term.direction) {
          case 'left':
            anchorX = -5
            break
          case 'top-left':
            anchorY = -10
            anchorX = -5
            break
          case 'top':
            anchorY = -10
            anchorX = 2.5
            break
          case 'top-right':
            anchorY = -10
            anchorX = 5
            break
          case 'right':
            anchorX = 10
            break
          case 'bottom-right':
            anchorY = 10
            anchorX = 5
            break
          case 'bottom':
            anchorX = 2.5
            anchorY = 10
            break
          case 'bottom-left':
            anchorY = 10
            anchorX = -5
            break
          default:
            break
        }

        markers.push(
          <Marker
            key={term.id}
            interactive={
              envVarToBool(REACT_APP_TERMINUS_ENTRYMAP_NO_PERMANENT_TOOLTIP) ? true : term.dontRenderTooltip === true
            }
            position={[term.coord.lat, term.coord.lon]}
            zoom={{ min: 10, max: REACT_APP_AREAS_ZOOM_LEVEL || 16 }}
            icon={
              new L.DivIcon({
                className: `lc-circle-icon-marker lc-circle-icon-marker-terminus`,
                iconSize: [8, 8],
                tooltipAnchor: new L.Point(anchorX, anchorY),
                html: `<span style="border:3px solid #${term.color}" />`,
              })
            }
            lines={term.lines}>
            <Tooltip
              key={Math.random()}
              direction={term.direction.includes('-') ? term.direction.split('-')[1] : term.direction.split('-')[0]}
              className={`lc-tooltip-leaflet-terminus lc-${term.id} ${term?.class ? term.class : ''}`}
              opacity={1}
              permanent={
                envVarToBool(REACT_APP_TERMINUS_ENTRYMAP_NO_PERMANENT_TOOLTIP) ? false : term.dontRenderTooltip !== true
              }
              interactive={false}
              onOpen={() => {
                document.querySelectorAll(`.lc-tooltip-leaflet-terminus.lc-${term.id}`).forEach(div => {
                  div.style.borderColor = '#' + term.color

                  if (
                    !REACT_APP_TERMINUS_SHOW_ONLY_LINES ||
                    envVarToBool(REACT_APP_TERMINUS_SHOW_ONLY_LINES) === false
                  ) {
                    div.style.padding = '2px 8px'
                  }

                  if (term.displayLines) {
                    div.style.borderColor = 'transparent'
                    div.style.backgroundColor = 'white'
                    div.style.padding = '0px'

                    if (['left', 'top-left', 'bottom-left'].includes(term.direction)) {
                      if (
                        !REACT_APP_TERMINUS_SHOW_ONLY_LINES ||
                        envVarToBool(REACT_APP_TERMINUS_SHOW_ONLY_LINES) === false
                      ) {
                        div.style.paddingLeft = '4px'
                      }

                      div.style.paddingRight = '0px'
                    } else if (['top', 'right', 'bottom', 'top-right', 'bottom-right'].includes(term.direction)) {
                      if (
                        !REACT_APP_TERMINUS_SHOW_ONLY_LINES ||
                        envVarToBool(REACT_APP_TERMINUS_SHOW_ONLY_LINES) === false
                      ) {
                        div.style.paddingRight = '4px'
                      }

                      div.style.paddingLeft = '0px'
                    }
                  } else {
                    div.style.backgroundColor = '#' + term.color
                    div.style.color = luminance(term.color) > 0.5 ? '#333' : '#fff'
                  }
                })
              }}>
              {term.displayLines ? (
                <div
                  className={
                    'lc-em-multilines-terminus ' +
                    (term.direction.includes('left') ? 'direction-left' : 'direction-right')
                  }>
                  {(!REACT_APP_TERMINUS_SHOW_ONLY_LINES ||
                    envVarToBool(REACT_APP_TERMINUS_SHOW_ONLY_LINES) === false) && (
                    <div className={'lc-em-multilines-terminus-name'}>{term.name}</div>
                  )}
                  <div className={'lc-em-multilines-terminus-lines'}>
                    {sortBy(term.lines, 'position').map(line => (
                      <div className={'lc-em-multilines-terminus-line'} key={`terminus-line-${line}`}>
                        <UILine
                          line={lines.find(l => l.id === line)}
                          image={REACT_APP_LINES_MAIN_TYPE?.includes('image')}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                term.name
              )}
            </Tooltip>
          </Marker>,
        )
      }
    }
  }

  return markers
}

/*
 * Build all lines on map
 * @param geojson
 * @param map
 * @param selected = {type: "", id:"""}
 * type : dl|code_reg|cat|code
 * id of type
 */
export const buildAllLines = (map, selected, newWeight = false) => {
  const geojson = appStore.getState().map.allLinesGeojson
  const geojsonIntersec = appStore.getState().map.allLinesIntersecGeojson
  const allLinesColorType = appStore.getState().map.allLinesColorType
  const ddls = appStore.getState().app.ddls
  const aoapis = appStore.getState().app.aoapis
  const params = getURLSearchParams(history.location)

  // on ne redessine pas si on a toujours le même selected
  if (
    selected !== undefined &&
    appStore.getState().map.allLinesSelected !== undefined &&
    selected?.type === appStore.getState().map.allLinesSelected?.type &&
    selected?.id === appStore.getState().map.allLinesSelected?.id &&
    !newWeight
  ) {
    return
  }

  if (!selected && appStore.getState().map.allLinesSelected) {
    selected = appStore.getState().map.allLinesSelected
  }

  if (!geojson || !Object.keys(geojson).length) {
    message({ error: 'geojson_all_lines_not_found' })
    return
  }

  const getLineColor = (line, geojsonlinecolor) => {
    if (selected && selected.type) {
      if (line !== undefined) {
        if (selected.type === 'indicator') {
          let color = ''

          selected.steps.forEach(step => {
            if (step.ids.includes(line.id)) {
              color = step.color
            }
          })
          return color
        } else if (selected.type === 'region') {
          const lots = aoapis.filter(a => a.region === selected.id)
          if (lots.length > 0) {
            for (const lot of lots) {
              if (lot.lines.includes(line.id)) {
                return lot.color
              }
            }
          }
          return '#A0A0A0'
        } else if (selected.type === 'aoapi') {
          const findLot = aoapis.find(a => a.uuid === selected.id)
          if (findLot && findLot.lines.includes(line.id)) {
            return findLot.color
          } else {
            return '#A0A0A0'
          }
        } else {
          if (
            (params.cat === undefined && String(line[selected.type]) === String(selected.id)) ||
            (params.cat &&
              String(params.cat) === String(line.cat) &&
              String(line[selected.type]) === String(selected.id))
          ) {
            return selected.color ? selected.color : geojsonlinecolor
          } else {
            return '#A0A0A0'
          }
        }
      } else {
        return geojsonlinecolor
      }
    } else {
      if (allLinesColorType === 'ddl') {
        const findDdl = ddls?.find(ddl => ddl.code === line.dl)
        if (findDdl) {
          return findDdl.color
        } else {
          return '#A0A0A0'
        }
      } else if (allLinesColorType === 'aoapi') {
        const findLot = aoapis.find(a => a.lines.includes(line.id))
        if (findLot) {
          return findLot.color
        } else {
          return '#A0A0A0'
        }
      }
      return geojsonlinecolor
    }
  }

  function getWeight(weight, map) {
    const zoom = map.mapReference.current.leafletElement.getZoom()

    if (zoom === 8) {
      return weight / 3
    } else if (zoom === 9) {
      return weight / 2
    } else {
      return weight
    }
  }

  if (map.mapReference.current) {
    // map = map.mapReference.current.leafletElement;
    if (selected && selected.type && selected.type === 'id' && params.line === undefined) {
      updateMapEvents(map, 'onClick', () => {
        appStore.dispatch(actionSetAllLinesSelected({ type: '', id: null }))
      })
    } else {
      removeMapEvents(map)
    }

    // on reculcacule la weight des tracés en fonction du zoom level
    updateMapEvents(map, 'onZoomend', () => {
      buildAllLines(map, selected, true)
    })
  }

  const lines = []

  geojson.features
    .map(line => {
      const findLine = appStore.getState().app.lines.find(l => String(l.id) === String(line.properties['ID']))

      if (findLine) {
        let color = getLineColor(findLine, line.properties.COULEUR)
        let weight = getWeight(
          Math.round(parseFloat(parseFloat(line.properties['#StrokeWeight']).toFixed(2)) * 2.5),
          map,
        )

        return { ...line, color: color, weight: weight, findLine: findLine }
      } else {
        return { ...line, findLine: findLine }
      }
    })
    .sort((l1, l2) => {
      // on tri les lignes pour que celles qui sont grises soient en dessous au moment du rendu
      if (l1.color === '#A0A0A0') {
        return -1
      } else if (l2.color === '#A0A0A0') {
        return 1
      } else {
        return 0
      }
    })
    .forEach((line, index) => {
      const findLine = line.findLine

      if (findLine) {
        lines.push(
          <Polyline
            key={'pl-' + index}
            positions={line.geometry.coordinates.map(coord => [coord[1], coord[0]])}
            color={line.color}
            weight={line.weight}
            interactive={true}
            onClick={() => {
              if (isActiveModule('analyzes')) {
                const params = getURLSearchParams(history.location)
                const searchParam = addGetParam(params, { line: findLine.id })

                history.push({
                  ...history.location,
                  search: searchParam,
                })
              } else {
                history.push({
                  pathname: '/lines-regions-services',
                  search: '?line=' + findLine.id,
                })
              }
            }}>
            <Tooltip direction="top" sticky={true}>
              <div key={'popup-select-line-' + findLine.id}>
                <UILine image={false} line={findLine} />
              </div>
            </Tooltip>
          </Polyline>,
        )
      }
    })

  if (geojsonIntersec && geojsonIntersec.features && geojsonIntersec.features.length > 0) {
    geojsonIntersec.features.forEach((intersec, index) => {
      const weights = intersec.properties.lines.split(';').map(id => {
        return appStore.getState().app.lines.find(l => String(l.id) === String(id))?.weight
      })

      let weight = getWeight(Math.max(...weights), map)

      const geojsonContent = (
        <div className="lc-intersection-content">
          {intersec.properties.lines.split(';').map(id => {
            const findLine = appStore.getState().app.lines.find(l => String(id) === String(l.id))

            if (findLine) {
              return (
                <div
                  key={'popup-select-line-' + findLine.id}
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    history.push({
                      pathname: '/lines-regions-services',
                      search: '?line=' + findLine.id,
                    })
                  }}
                  onKeyUp={e =>
                    handleKeyUp(e, () => {
                      history.push({
                        pathname: '/lines-regions-services',
                        search: '?line=' + findLine.id,
                      })
                    })
                  }>
                  <UILine image={false} line={findLine} />
                </div>
              )
            } else {
              return ''
            }
          })}
        </div>
      )

      lines.push(
        <GeoJSON
          key={'intersec-' + index}
          data={intersec}
          style={() => ({
            color: '#000000',
            opacity: 0,
            weight: weight,
            interactive: true,
          })}>
          <Popup
            className="lc-intersections-popup"
            closeButton={false}
            autoClose={false}
            autoPan={false}
            keepInView={true}
            direction="right">
            {geojsonContent}
          </Popup>
          <Tooltip direction="top" sticky={true}>
            {geojsonContent}
          </Tooltip>
        </GeoJSON>,
      )
    })
  }

  setTimeout(() => {
    // on centre sur la selection de ligne
    // seulement quand on a des lignes à mettre en avant (color !== "#A0A0A0")
    const linesZoomOn = lines.filter(l => l.props.color !== '#A0A0A0' && !l.key.includes('intersec'))
    const linesCount = lines.filter(l => !l.key.includes('intersec'))

    if (linesZoomOn.length !== linesCount.length && !newWeight) {
      fitBounds(map, linesZoomOn)
    }
  }, 500)

  setTimeout(() => {
    appStore.dispatch(actionSetLinesToDisplay(lines)) // appStore.getState().map.reactLines
  })
}

/**
 * Build & render heavy lines
 * @param state
 */
export const buildHeavyLines = (state, zoom, zoomLvl) => {
  const { heavyIds, lines, hash, map, configApp } = state.app
  const heavyLinesIds = [...heavyIds]

  if (REACT_APP_HEAVY_MODES && JSON.parse(REACT_APP_HEAVY_MODES).length) {
    const heavyModes = JSON.parse(REACT_APP_HEAVY_MODES)

    for (const l of lines) {
      const findMode = heavyModes.find(m => l.mode === m.mode)

      if (findMode && zoomLvl && (findMode.minZoom || findMode.maxZoom)) {
        if (zoomLvl && findMode.minZoom && zoomLvl >= findMode.minZoom && !findMode.maxZoom) {
          if (!heavyLinesIds.includes(l.id)) {
            heavyLinesIds.push(l.id)
          }
        } else if (zoomLvl && findMode.maxZoom && zoomLvl <= findMode.maxZoom && !findMode.minZoom) {
          if (!heavyLinesIds.includes(l.id)) {
            heavyLinesIds.push(l.id)
          }
        } else if (
          zoomLvl &&
          findMode.minZoom &&
          zoomLvl >= findMode.minZoom &&
          findMode.maxZoom &&
          zoomLvl <= findMode.maxZoom
        ) {
          if (!heavyLinesIds.includes(l.id)) {
            heavyLinesIds.push(l.id)
          }
        } else if (findMode && !findMode.minZoom && !findMode.maxZoom) {
          if (!heavyLinesIds.includes(l.id)) {
            heavyLinesIds.push(l.id)
          }
        }
      } else if (findMode) {
        if (!heavyLinesIds.includes(l.id)) {
          heavyLinesIds.push(l.id)
        }
      }
    }
  }

  if (configApp?.heavy_lines_from_lines) {
    heavyLinesIds.length = 0
    for (const l of lines) {
      if (l.heavyline) {
        heavyLinesIds.push(l.id)
      }
    }
  }

  if (
    (state.map.heavyLines || state?.app?.config?.heavyLines === false) &&
    heavyLinesIds?.length === state?.map?.heavyLines?.length
  ) {
    if (!zoom && REACT_APP_INIT_MAP_ON_BACK && state.map.heavyLines) {
      fitBounds(map, state.map.heavyLines)
    }

    return
  }

  const requests = []

  for (const heavy of sortLinesPerPosition(heavyLinesIds, lines)) {
    const [mode, code] = heavy.split('~~')

    const data = getLine(
      lines,
      mode && code
        ? {
            code,
            mode,
          }
        : {
            id: heavy,
            direction_id: 'f',
          },
    )

    if (data.code) {
      if (REACT_APP_HEAVY_MODES && JSON.parse(REACT_APP_HEAVY_MODES).length) {
        const heavyModes = JSON.parse(REACT_APP_HEAVY_MODES)
        const findMode = heavyModes.find(m => data.mode === m.mode)

        if (findMode?.weight) {
          data.weight = findMode.weight
        }
        if (findMode?.color) {
          data.color = findMode.color
        }

        if (data && findMode && findMode.bringToback === true) {
          data.bringToback = true
        }
      }

      // TODO recode displayLinePath

      requests.push(
        buildLinePath({ ...data, displayOnlyMainSegment: configApp.heavy_lines_only_main_segment === true }, hash),
      )

      if (envVarToBool(REACT_APP_CLICK_THROUGH_LINES)) {
        requests.push(
          buildLinePath(
            { ...data, displayOnlyMainSegment: configApp.heavy_lines_only_main_segment === true, clickThrough: true },
            hash,
          ),
        )
      }
    }
  }

  Promise.all(requests).then(polylines => {
    appStore.dispatch(actionSetHeavyLines(polylines))

    if (!zoom) {
      fitBounds(map, polylines)
    }
  })
}

/**
 * Build & render complementary lines
 * @param state
 */
export const buildComplementaryLines = (state, hoverLine) => {
  if (state?.app?.config?.complementaryLines === false) {
    return
  }

  const { complementaryIds, lines, hash } = state.app
  const { pathname } = history.location
  const params = getURLSearchParams(history.location)

  if (
    !pathname.includes('route-calculation') ||
    (pathname.includes('route-calculation') &&
      envVarToBool(REACT_APP_ALLOW_HEAVY_LINES_ON_ROUTECALCULATION) &&
      Object.keys(params).length < 3)
  ) {
    if (hoverLine) {
      const complementary = state.map.complementaryLines?.find(c => c.props.line.id === hoverLine.id)

      if (complementary) {
        const geojson = (
          <GeoJSON
            interactive={true}
            data={complementary.props.data}
            style={{ ...complementary.props.style, color: `#${hoverLine.color}` }}
            line={hoverLine}
          />
        )

        appStore.dispatch(actionSetComplementaryLine(geojson))
      }
    } else if (state.map.complementaryLines) {
      appStore.dispatch(actionSetComplementaryLine(null))
    }

    if (!state.map.complementaryLines || state.map.complementaryLines === undefined) {
      const requests = []

      for (const id of complementaryIds) {
        const data = getLine(lines, {
          id,
          direction_id: 'f',
          complementaryLine: true,
          complementaryLineHover: hoverLine && hoverLine.id === id,
        })

        if (data.code) {
          // TODO recode displayLinePath
          requests.push(buildLinePath(data, hash))

          if (envVarToBool(REACT_APP_CLICK_THROUGH_LINES)) {
            requests.push(buildLinePath({ ...data, clickThrough: true }, hash))
          }
        }
      }

      Promise.all(requests).then(polylines => {
        appStore.dispatch(actionSetComplementaryLines([...polylines].filter(a => a !== undefined)))
      })
    }
  }
}

export function buildCustomLines(state, customMapLines) {
  const { lines, hash } = state.app
  const requests = []

  for (const customLine of customMapLines) {
    const data = getLine(lines, {
      id: customLine.id ? customLine.id : customLine,
      direction_id: 'f',
    })

    if (data.code) {
      // TODO recode displayLinePath
      requests.push(buildLinePath(data, hash))
    } else {
      message({ error: 'custom_line_not_found', id: customLine, message: 'Custom line is not found in line list' })
    }
  }

  Promise.all(requests).then(polylines => {
    appStore.dispatch(actionSetCustomLines(polylines))
  })
}

export const buildLinePath = (data, hash, isSelectedLine = false) => {
  const displayLinesAt = appStore.getState().app.config?.displayLinesAt
  const aroundHighlightSelectedLine = appStore.getState().app.configApp?.around_highlight_selected_line

  // Force direction_id to 'f' by default if not exists
  if (!data.direction_id) {
    data.direction_id = 'f'
  }

  let folder = data.type ? (+data.type !== 4 ? 'routes/future/lines' : 'routes/current/lines') : 'routes'

  const name = data.type
    ? `${data.code}~${hash}`
    : `${encodeURIComponent(data.code)}_${data.network}_${data.direction_id}~${hash}`

  if (displayLinesAt) {
    const datasets = appStore.getState().app.datasets
    const datedisplayLinesAt = new Date(displayLinesAt)

    const dataset = datasets.find(ds => {
      if (ds.start_validation_date && ds.end_validation_date) {
        const startDate = new Date(navitiaDateToDate(ds.start_validation_date))
        const endDate = new Date(navitiaDateToDate(ds.end_validation_date))

        if (startDate <= datedisplayLinesAt && endDate >= datedisplayLinesAt) {
          return true
        }
      }

      return false
    })

    if (dataset) {
      folder = `datasets/${dataset.id.replace(':', '_')}/routes`
    }
  }

  const geojsonUrl = `/api/file?folder=${folder}&ext=geojson&name=${name}`

  return axios
    .get(geojsonUrl)
    .then(response => {
      let geojson = response.data.length === 0 ? null : response.data

      const options = {
        color: '#' + data.color,
        opacity: 1,
        weight: REACT_APP_DEFAULT_LINES_WEIGHT && !isSelectedLine ? REACT_APP_DEFAULT_LINES_WEIGHT : 5,
        zIndex: 7,
      }

      let geojsonClassName = ''

      if (geojson) {
        if (data.displayOnlyMainSegment) {
          geojson = { ...geojson, features: geojson?.features?.filter(f => f.properties?.segment === 'main') }
        }
        if ((data.tad && data.tad.zone) || (data.tad && geojson.features[0].geometry.type == 'Polygon')) {
          // Navitia can't integrate Polygon type... so MultiLineString became a Polygon :D
          geojson.features[0].geometry.type = 'Polygon'
          options.weight = 2
          options.fillColor = '#' + data.color
          options.fillOpacity = 0.2
        }

        if (data.dashArray !== undefined) {
          options.dashArray = data.dashArray
        }

        if (data.weight !== undefined) {
          options.weight = data.weight
        }

        if (data.complementaryLine) {
          options.color = !data.complementaryLineHover ? '#999' : options.color
          options.weight = !data.complementaryLineHover ? 3 : 4
        }

        if (data.clickThrough) {
          options.opacity = 0
          options.weight = 15
          geojsonClassName = 'lc-geojson-click-through'
        }

        if (aroundHighlightSelectedLine && data.highlight) {
          const highlights = [
            { c: '#FFFFFF', w: 14, o: 0.1 },
            { c: '#FFFFFF', w: 12, o: 0.3 },
            { c: '#FFFFFF', w: 6, o: 0.5 },
            { c: '#FFFFFF', w: 4, o: 0.6 },
            { c: '#DDDDDD', w: 2, o: 0.7 },
          ]

          return [
            ...highlights.map(({ c, w, o }) => (
              <GeoJSON
                interactive={data.clickThrough ? true : false}
                className={geojsonClassName}
                key={'line-path-' + Math.random()}
                data={geojson}
                style={{ color: c, weight: options.weight + w, opacity: o }}
                line={data}
                ref={ref => {
                  if (ref?.leafletElement && (data.bringToback || ref?.line?.bringToBack)) {
                    ref.leafletElement.bringToBack()
                  }
                }}
              />
            )),
            <GeoJSON
              interactive={data.clickThrough ? true : false}
              className={geojsonClassName}
              key={'line-path-' + Math.random()}
              data={geojson}
              style={options}
              line={data}
              ref={ref => {
                if (ref?.leafletElement && (data.bringToback || ref?.line?.bringToBack)) {
                  ref.leafletElement.bringToBack()
                }
              }}
            />,
          ]
        }

        return (
          <GeoJSON
            interactive={data.clickThrough ? true : false}
            className={geojsonClassName}
            key={'line-path-' + Math.random()}
            data={geojson}
            style={options}
            line={data}
            ref={ref => {
              if (ref?.leafletElement && (data.bringToback || ref?.line?.bringToBack)) {
                ref.leafletElement.bringToBack()
              }
            }}
          />
        )
      } else {
        message({
          error: 'geojson_line_not_found',
          id: data.code,
          message: 'Line has no geojson',
        })
      }
    })
    .catch(e => {
      const error = e.response && e.response.data ? e.response.data.id : e

      console.warn(error)
    })
}

export const buildDecoratorsByLine = async line => {
  const markers = []

  try {
    const responseJalons = await axios.get(`/api/file?folder=map&ext=geojson&name=jalons`)

    if (responseJalons?.data?.features?.length > 0) {
      const jalons = responseJalons.data.features.filter(feature => feature.properties.id === line.id)

      jalons.forEach(jalon => {
        markers.push(
          <Marker
            key={`jalon-${Math.random()}`}
            interactive={false}
            position={[jalon.geometry.coordinates[1], jalon.geometry.coordinates[0]]}
            icon={L.icon({
              iconUrl: assetsPath('/assets/images/lines/entrance/') + jalon.properties.image + '.svg',
              iconSize: jalon.properties.size,
              iconAnchor: jalon.properties.anchor,
            })}
            zIndexOffset={50}
            zoom={jalon.properties.zoom}
            line={line}
          />,
        )
      })
    }
  } catch (e) {
    console.log('Error in get jalons file : ', e)
    message({
      error: 'geojson_jalons_not_found',
      id: line.code,
      message: 'Jalons not found',
    })
  }

  return markers
}

export const buildCustomMarkers = customMarkers => {
  const markers = []

  try {
    for (const m of customMarkers) {
      if (m.id === undefined) {
        console.warn('Custom marker has no id')
        message({ error: 'custom_marker_no_id', message: 'Custom marker must have an id' })
      } else {
        const splitName = m.title?.split(/[()]/)
        const hasSubtitle = splitName?.length > 1

        markers.push(
          <Marker
            key={'customMarker-' + m.id}
            name={m.title}
            position={m.position}
            icon={L.icon({
              iconUrl: m.icon.url,
              iconSize: m.icon.size || [26, 26],
              iconAnchor: m.icon.anchor || [13, 13],
              className: m.icon.className,
            })}
            ref={ref => {
              if (m.arrowAngle >= 0 && ref?.leafletElement) {
                ref.leafletElement.setRotationAngle(m.arrowAngle).setRotationOrigin('center center')
              }
            }}
            zIndexOffset={m.zIndexOffset}
            onMouseOver={e => {
              if (m.title) {
                if (!e.target.isPopupOpen()) {
                  e.target.openPopup()
                  setTimeout(() => {
                    updatePopupPosition(e.target)
                  })
                  appStore.dispatch(actionSetCustomMarkerEvent(m.id, 'mouseover'))
                }
              }
            }}
            onMouseOut={e => {
              if (m.title) {
                if (
                  appStore.getState().map.customMarkers.find(mf => mf.key === 'customMarker-' + m.id)?.lastEvent ===
                  'mouseover'
                ) {
                  e.target.closePopup()
                  appStore.dispatch(actionSetCustomMarkerEvent(m.id, ''))
                }
              }
            }}
            onClick={e => {
              if (m.title) {
                if (
                  appStore.getState().map.customMarkers.find(mf => mf.key === 'customMarker-' + m.id)?.lastEvent ===
                  'mouseover'
                ) {
                  message({ clicked: 'custom-marker', id: m.id })
                  appStore.dispatch(actionSetCustomMarkerEvent(m.id, 'click'))
                  setTimeout(() => {
                    e.target.openPopup()
                  })
                } else {
                  appStore.dispatch(actionSetCustomMarkerEvent(m.id, ''))
                }
              }
            }}>
            {m.title && (
              <Popup
                className={'lc-popup-leaflet'}
                closeButton={false}
                autoClose={false}
                autoPan={false}
                keepInView={true}>
                <div className="lc-infobox">
                  <div className="lc-infobox-title">
                    <span>
                      {splitName[0]}
                      {hasSubtitle && (
                        <em>
                          {m.name
                            .split(/[()]/)
                            .filter((i, index) => index > 0 && i.trim() !== '')
                            .map(i => `(${i})`)
                            .join(' ')}
                        </em>
                      )}
                    </span>
                  </div>
                  <div className="lc-infobox-content" dangerouslySetInnerHTML={{ __html: m.description }} />
                </div>
              </Popup>
            )}
          </Marker>,
        )
      }
    }
  } catch (e) {
    console.warn('Something went wrong while building custom markers')
    throw e
  }

  return markers
}

export const buildStreeviewMarker = () => {
  let coords = appStore.getState().app.streetviewPosition
  const configApp = appStore.getState().app.configApp

  return (
    <Marker
      id="streetview-marker"
      key="streetview-marker"
      options={{ zIndex: 999 }}
      icon={
        new L.icon({
          iconUrl: assetsPath('/assets/images/streetview.svg'),
          iconSize: [30, 30],
          iconAnchor: [15, 15],
        })
      }
      position={[coords.lat, coords.lng]}
      onMouseOver={e => {
        if (!e.target.isPopupOpen()) {
          e.target.openPopup()
          updatePopupPosition(e.target)
          appStore.dispatch(actionSetCustomMarkerEvent('streetview-marker', 'mouseover'))
        }
      }}
      onClick={() => {
        appStore.dispatch(
          actionSetCustomMarkers(appStore.getState().map.customMarkers.filter(m => m.key !== 'streetview-marker')),
        )
      }}>
      <Popup
        className={'lc-popup-leaflet lc-popup-streetview'}
        closeButton={false}
        autoClose={false}
        autoPan={false}
        keepInView={true}>
        <div className="lc-infobox">
          <span className="lc-infobox-title">
            {translate('google-streetview')}
            <div
              className="lc-infobox-title-tools"
              role="button"
              tabIndex="0"
              onClick={() =>
                appStore.dispatch(
                  actionSetCustomMarkers(
                    appStore.getState().map.customMarkers.filter(m => m.key !== 'streetview-marker'),
                  ),
                )
              }
              onKeyUp={e =>
                handleKeyUp(e, () => {
                  appStore.dispatch(
                    actionSetCustomMarkers(
                      appStore.getState().map.customMarkers.filter(m => m.key !== 'streetview-marker'),
                    ),
                  )
                })
              }>
              <div className="lc-close" />
            </div>
          </span>
          <div className="lc-infobox-content lc-streetview">
            <iframe
              title="streetview"
              style={{ border: 0 }}
              loading="lazy"
              height="350px"
              width="500px"
              //disableDefaultUI="true" // TO CHECK
              src={`https://www.google.com/maps/embed/v1/streetview?key=${configApp?.streetview}&location=${coords.lat},${coords.lng}`}></iframe>
          </div>
        </div>
      </Popup>
    </Marker>
  )
}

export const buildMapBikes = (state, bikes) => {
  const markers = []

  for (const bike of bikes) {
    markers.push(
      buildMarker(state, bike, {
        icon: L.icon({
          iconUrl: bike.code
            ? assetsPath('/assets/images/places/' + bike.code + '.svg')
            : assetsPath('/assets/images/menu/velo.svg'),
          className: buildPlaceIconClassName(bike.cat_id),
        }),
        bike,
        zIndexOffset: 200,
      }),
    )
  }

  appStore.dispatch(actionSetMapBikes(markers))
}

/**
 * Build a marker component from the given data
 * @param state
 * @param data
 * @param options
 * @returns Marker
 */
export const buildMarker = (state, data, options) => {
  let currentLine = state?.app?.component?.state?.currentLine
  const terminusStyle = state?.app?.terminusStyle
  const lines = state?.app?.lines
  const params = getURLSearchParams(history.location)

  if (!currentLine && lines && params.current) {
    currentLine = getLine(state.app.lines, {
      id: params.current.includes('_f')
        ? params.current.substring(0, params.current.lastIndexOf('_f'))
        : params.current.includes('_b')
        ? params.current.substring(0, params.current.lastIndexOf('_b'))
        : params.current,
    })
  }

  if (currentLine && params.current && currentLine.id !== params.current && lines) {
    currentLine = getLine(state.app.lines, {
      id: params.current.includes('_f')
        ? params.current.substring(0, params.current.lastIndexOf('_f'))
        : params.current.includes('_b')
        ? params.current.substring(0, params.current.lastIndexOf('_b'))
        : params.current,
    })
  }

  return (
    <Marker
      key={data.id}
      ref={ref => {
        data.ref = ref
        // appStore.dispatch(actionAddReduxRef(ref))
      }}
      overrideZoom={data.overrideZoom ? data.overrideZoom : false}
      name={data.name}
      onMouseOver={e => {
        appStore.dispatch(
          actionOverMarker(
            data,
            REACT_APP_MARKER_SELECTED_PICTO && JSON.parse(REACT_APP_MARKER_SELECTED_PICTO).includes(data.cat_id)
              ? e.originalEvent.target
              : false,
          ),
        )
      }}
      onMouseOut={e => {
        const target = e.originalEvent.target

        if (!target.classList.contains('leaflet-tooltip')) {
          setTimeout(() =>
            appStore.dispatch(
              actionOutMarker(
                data,
                REACT_APP_MARKER_SELECTED_PICTO && JSON.parse(REACT_APP_MARKER_SELECTED_PICTO).includes(data.cat_id)
                  ? target
                  : false,
              ),
            ),
          )
        }
      }}
      onClick={() => {
        message({ clicked: 'marker', id: data.id })
        appStore.dispatch(actionOpenMarker(data))
      }}
      position={[+data.coord.lat, +data.coord.lon]}
      {...options}>
      {options.terminus && data.terminus && (
        <Tooltip
          key={'terminus_' + data.id}
          direction={'right'}
          onClick={() => appStore.dispatch(actionOpenMarker(data, true))}
          className={'lc-tooltip-leaflet-terminus'}
          opacity={1}
          interactive
          permanent>
          {['imageAndCityName'].includes(terminusStyle) && currentLine ? (
            <div className={'lc-tooltip-leaflet-terminus-with-image'}>
              <UILine line={currentLine} image={REACT_APP_LINES_MAIN_TYPE.includes('image')} />
              <div className="lc-tooltip-leaflet-terminus-title">
                {data.town && <div className="lc-terminus-commune">{data.town}</div>}
                <div className={'lc-terminus-name' + (data.town ? ' has-town' : '')}>{data.name}</div>
              </div>
            </div>
          ) : (
            data.name
          )}
        </Tooltip>
      )}
      <Popup
        className={'lc-popup-leaflet'}
        closeButton={false}
        autoClose={false}
        autoPan={false}
        onClose={() => {
          if (
            REACT_APP_MARKER_SELECTED_PICTO &&
            (JSON.parse(REACT_APP_MARKER_SELECTED_PICTO).includes(data.cat_id) ||
              (data.id.startsWith('stop') && JSON.parse(REACT_APP_MARKER_SELECTED_PICTO).includes('stops')))
          ) {
            const target = data.ref?.leafletElement?._icon

            if (target && target.src) {
              if (appStore.getState()?.map?.openedMarker?.id === data.id) {
                appStore.dispatch(actionMarkerClick(null))
              }

              target.classList.remove('opened')
              target.src = target.src.replace('_selected.svg', '.svg')
            }
          }

          if (data.opened) {
            data.opened = false
          }
        }}>
        {buildPopup(state, data)}
      </Popup>
    </Marker>
  )
}

/**
 * Build a marker component from the given data
 * @param data
 * @param options
 * @returns Marker
 */
export const buildCustomMarker = (data, options) => {
  const { configApp } = appStore.getState().app
  const hasTown = configApp?.split_stops_name_and_town_by
  const splitName = hasTown ? data?.name?.split(configApp?.split_stops_name_and_town_by) : data?.name

  return (
    <Marker
      key={data.id}
      ref={ref => {
        data.ref = ref
      }}
      position={[+data.coord.lat, +data.coord.lon]}
      onClick={() => {
        message({
          clicked: options.postMessageEventName ? options.postMessageEventName : 'custom-marker',
          id: data.id,
        })
      }}
      {...options}>
      {data.terminus && (
        <Tooltip
          key={'terminus_' + data.id}
          direction={'right'}
          className={'lc-tooltip-leaflet-terminus'}
          opacity={1}
          permanent>
          {data.name}
        </Tooltip>
      )}
      <Popup
        className={'lc-popup-leaflet lc-custom-popup-leaflet'}
        closeButton={false}
        autoClose={false}
        autoPan={false}>
        <div className={'lc-infobox'}>
          <div className="lc-infobox-title">
            <div className={`lc-stop-and-town-name${hasTown ? ' lc-with-town' : ''}`}>
              {hasTown && <span className="lc-stop-town-town">{splitName[0]}</span>}
              <span className="lc-stop-town-name">
                {hasTown ? splitName[1] : splitName}
                {!REACT_APP_DISPLAY_ADDITIONAL_INFOS_ON_POPUP_CONTENT && REACT_APP_SHOW_PMR && (
                  <UIStopPmr pmr={data.pmr} />
                )}
              </span>
            </div>
          </div>
          {data.content && <div className="lc-infobox-content">{data.content}</div>}
        </div>
      </Popup>
    </Marker>
  )
}

/**
 * Build all places markers
 * @param state
 * @param places
 * @returns {Array}
 */
export const buildPlaces = (state, places) => {
  if (!places) {
    return
  }

  const flattenPlaces = Array.isArray(places) ? places : flattenObject(places)
  const markers = []
  const { markerMode } = state.map

  for (const place of flattenPlaces) {
    if (place.coord) {
      markers.push(
        buildMarker(state, place, {
          icon: place.divIcon
            ? L.divIcon({
                className: `lc-place-divicon lc-${place.color} lc-place-${place.cat_id
                  .replace('poi_type:', '')
                  .replace('+', '')
                  .toLowerCase()} lc-price-${place.isReducedPrice ? 'reduced' : 'normal'}`, // lc-price / lc-predict are only bordeaux-tbm classes...
                html:
                  markerMode === 'default'
                    ? `<img src="${assetsPath(`/assets/images/places/${place.code}.svg`)}"/>${
                        place.value !== null && !['closed', 'unavailable', 'maintenance'].includes(place.color)
                          ? `<span class="${place.value > 99 ? 'lc-long-value' : ''}">${
                              place.stand?.time && place.predictFilter ? '-' : place.value // TMP predict place.value
                            }</span>`
                          : ''
                      }`
                    : `<div class="lc-small-circle-marker"/>`,
                iconSize: [0, 0],
              })
            : L.icon({
                iconUrl: place.code
                  ? assetsPath(
                      `/assets/images/places/${place.code}${
                        state?.map?.openedMarker?.id === place.id &&
                        REACT_APP_MARKER_SELECTED_PICTO &&
                        JSON.parse(REACT_APP_MARKER_SELECTED_PICTO)?.includes(place.cat_id)
                          ? '_selected'
                          : ''
                      }.svg`,
                    )
                  : assetsPath('/assets/images/stop_point.svg'),
                className: buildPlaceIconClassName(place.cat_id) + (place.className ? ' ' + place.className : ''),
              }),
          place,
          zIndexOffset: isActiveModule('thematic') ? 200 : 40,
        }),
      )
    }
  }

  return markers
}

/**
 * Triggered when a line is selected.
 * The behavior can be different on each modules
 * @param state
 * @param line
 * @param data
 */
export const onLineSelected = (state, line, data) => {
  // If we have no routes on our line, just don't do anything
  if (line?.routes?.length === 0) {
    return
  }

  const { pathname } = history.location
  const params = getURLSearchParams(history.location)

  if (isActiveModule('around')) {
    const part =
      `line=${line.id}_${line.direction_id || 'f'}` +
      (data ? `&stop=${data.id}` : '') +
      (params.date ? `&date=${params.date}` : '') +
      (params.back ? `&back=${params.back}` : '')

    // TODO ! Remove insee from URL and use from
    if (params && (params.from || params.insee || params.place)) {
      history.push({
        pathname,
        search:
          (params.from ? '?from=' + params.from : params.place ? '?place=' + params.place : '?insee=' + params.insee) +
          '&' +
          part,
      })
    } else {
      history.push({
        pathname,
        search: '?' + part,
      })
    }
  } else if (isActiveModule('multimobilities')) {
    if (!data || !data.id) {
      const searchParam = addGetParam(params, { current: `${line.id}_${line.direction_id || 'f'}` })

      history.push({
        pathname,
        search: searchParam,
      })
    } else {
      const searchParam = addGetParam(params, { current: `${line.id}_${line.direction_id || 'f'}`, stop: data.id })

      history.push({
        pathname,
        search: searchParam,
      })
    }
  } else {
    if (!data || !data.id) {
      // ! Do not comment this : needed to switch direction on Lines component
      const searchParam = addGetParam(params, { current: `${line.id}_${line.direction_id || 'f'}` })

      history.push({
        pathname: '/lines',
        search: searchParam,
      })
    } else {
      const { modules } = state.app
      let urlpathname = '/lines'
      let searchParam

      if (modules.find(m => m.id === 'multimobilities')) {
        const findMob = modules.find(m => m.id === 'multimobilities').mobilities?.find(m => m.name === 'mob-tc')
        urlpathname = `/multimobilities`
        searchParam = addGetParam(params, {
          mob: findMob ? findMob.id : 0,
          current: `${line.id}_${line.direction_id || 'f'}`,
          stop: data.id,
        })
      } else if (
        isActiveModule('lines') &&
        state.app.configApp?.schedules_in_popup &&
        (params.current || params.stop || params.stop_area)
      ) {
        if (params.stop_area) {
          delete params.stop_area
        }
        const paramsToAdd = {
          current: `${line.id}_${line.direction_id || 'f'}`,
          stop: data.id,
          from: params.current ?? params.stop ?? params.stop_area ?? null,
        }

        searchParam = addGetParam(params, paramsToAdd)
      } else {
        searchParam = addGetParam(params, {
          current: `${line.id}_${line.direction_id || 'f'}`,
          stop: data.id,
        })
      }
      history.push({
        pathname: urlpathname,
        search: searchParam,
      })
    }
  }
}

/**
 * Triggered when user mouse leaves a marker
 * Retrieve the current openedMarker & test if its not equal to the given one.
 * If false, close the popup of the given marker
 * @param state
 * @param data
 */
export const onMarkerMouseOut = (state, data) => {
  const { openedMarker, reduxMarkers, customMarkers } = state.map

  if (!openedMarker || (openedMarker && openedMarker.id !== data.id)) {
    const ref = data.ref || getRef(data, reduxMarkers.length > 0 ? reduxMarkers : customMarkers)

    if (ref) {
      // Force close popup
      ref.leafletElement.closePopup()
    }
  }
}

/**
 * Triggered when user mouse enter on a marker
 * Retrieve the current openedMarker & test if its not equal to the given one or, if openedMarker is defined, if its popup is closed.
 * If one is true, open the popup of the given marker
 * @param state
 * @param data
 */
export const onMarkerMouseOver = (state, data) => {
  const { openedMarker, reduxMarkers, customMarkers } = state.map

  if (openedMarker !== data || (openedMarker && openedMarker.ref && !openedMarker.ref.leafletElement.isPopupOpen())) {
    const ref = data.ref || getRef(data, reduxMarkers.length > 0 ? reduxMarkers : customMarkers)

    if (ref) {
      // Open popup (delays it a bit to avoid position problem)
      const element = ref.leafletElement

      element.openPopup()
      setTimeout(() => {
        updatePopupPosition(element, data)
      })
    }
  }
}

/**
 * Open the popup of the given marker
 * @param state
 * @param data
 */
export const onOpenMarker = (state, data) => {
  const { pathname, search } = history.location
  const { component, linesModes } = state.app
  const { openedCollapse, thematicPlaces } = state.board
  const { cluster, mapPlaces, clusters } = state.map
  const { pois, tab } = component?.state ? component.state : { pois: [], tab: 0 }
  const params = getURLSearchParams(history.location)

  const needRequest = [
    'poi_type:amenity:bicycle_rental',
    'poi_type:amenity:bicycle_parking',
    'poi_type:amenity:car_rental',
    'poi_type:amenity:citiz',
    'poi_type:amenity:parking',
    'poi_type:stations',
  ]

  if (
    !component?.props?.moduleData?.groupBy &&
    !pathname.includes('/lines') &&
    data.cat_id &&
    openedCollapse !== data.cat_id
  ) {
    appStore.dispatch(actionSetOpenedCollapse(data.cat_id))
  }

  if (
    state.board.thematicPlaces &&
    !(data instanceof BikeInterface) &&
    !needRequest.includes(data.cat_id) &&
    !pathname.includes('/multimobilities')
  ) {
    const searchParam = addGetParam(params, {
      place: data.id,
      from:
        Object.keys(state.board.thematicPlaces).length === 1 &&
        state.board.thematicPlaces[Object.keys(state.board.thematicPlaces)[0]].length === 1
          ? 'home'
          : undefined,
    })

    history.push({
      pathname,
      search: searchParam,
    })
    appStore.dispatch(actionMarkerClick(data))
  } else if (!pois || pois.length === 0) {
    if (needRequest.includes(data.cat_id) && pathname.includes('/citiz')) {
      if (search !== '?place=' + data.id) {
        const searchParam = addGetParam(params, { place: data.id })

        history.push({
          ...history.location,
          search: searchParam,
        })
      }
    } else if (data instanceof BikeInterface && pathname.includes('/bike')) {
      if (search !== '?id=' + data.id) {
        const searchParam = addGetParam(params, { id: data.id })

        history.push({
          ...history.location,
          search: searchParam,
        })
      }
    } else {
      const places = mapPlaces.map(place => place.props.place)
      const place = places.find(place => place.id === data.id)

      if (place && needRequest.includes(place.cat_id)) {
        if (place.cat_id !== 'poi_type:stations') {
          const type = place.cat_id.includes('bicycle_rental')
            ? 'bss'
            : place.cat_id.includes('bicycle_parking')
            ? 'bike_parking'
            : place.cat_id.includes('car_rental')
            ? 'car_rental'
            : place.cat_id.includes('citiz')
            ? 'citiz'
            : 'parking'

          axios
            .get(`/api/availability?type=${type}&id=${place.id}`)
            .then(result => {
              place.stand = result.data
              appStore.dispatch(actionBuildMapPlaces(places))

              if (
                [
                  'poi_type:amenity:car_rental',
                  'poi_type:amenity:citiz',
                  'poi_type:amenity:parking',
                  'poi_type:amenity:bicycle_rental',
                ].includes(place.cat_id)
              ) {
                appStore.dispatch(actionSetPlaceClicked(place))
                if (isActiveModule('thematic') && Object.keys(state.board.thematicPlaces).length === 0) {
                  appStore.dispatch(actionBuildTransportPlaces(places))
                }

                if (!data.ref) {
                  data.ref = getRef(data, state.map.reduxMarkers)

                  if (!data.ref && mapPlaces.length > 0) {
                    data.ref = getRef(data, mapPlaces)
                  }
                }

                if (data.ref) {
                  const element = data.ref.leafletElement

                  setTimeout(() => {
                    updatePopupPosition(element, data)
                  })
                }
              }
            })
            .catch(e => {
              const error = e.response && e.response.data ? e.response.data.id : e

              console.warn(error)
            })
        } else {
          axios
            .get(`/api/stations?id=${place.id}`)
            .then(result => {
              place.stand = result.data
              appStore.dispatch(actionSetPlaceClicked(place))
            })
            .catch(e => {
              place.stand = {}
              const error = e.response && e.response.data ? e.response.data.id : e

              console.warn(error)
            })
        }
        if (isActiveModule('thematic')) {
          const searchParam = addGetParam(params, { place: place.id })

          history.push({
            pathname,
            search: searchParam,
          })
        }
      } else if (!data.id.includes('stop_point') && !data.id.includes('stop_area')) {
        const searchParam = addGetParam(params, { place: data.id })

        history.push({
          pathname,
          search: searchParam,
        })
      }
    }
  } else if (state.board.thematicPlaces && !pathname.includes('/multimobilities')) {
    history.push({
      pathname,
      search:
        '?place=' + data.id + (params.line ? '&line=' + params.line + (params.stop ? '&stop=' + params.stop : '') : ''),
    })
    appStore.dispatch(actionMarkerClick(data))
  } else {
    clickOnPlaceInList(data, component?.state?.pois, thematicPlaces)
  }

  if (data.id.startsWith('stop')) {
    data.ref = getRef(data, appStore.getState().map.reduxMarkers)
  }

  if (!data.ref && mapPlaces.length > 0) {
    data.ref = getRef(data, mapPlaces)
  }

  // Open popup, even if it's in a cluster
  if (
    (cluster && data.ref && cluster.hasLayer(data.ref.leafletElement)) ||
    (data.ref && clusters.length > 0 && clusters.filter(c => c.hasLayer(data.ref.leafletElement)).length > 0)
  ) {
    const clusterToUse = cluster.hasLayer(data.ref.leafletElement)
      ? cluster
      : clusters.find(c => c.hasLayer(data.ref.leafletElement))
    const element = data.ref.leafletElement

    // If we are on a cluster, let's zoom in
    clusterToUse.zoomToShowLayer(element, () => {
      setTimeout(() => {
        const visibleOne = clusterToUse.getVisibleParent(element)
        fitBounds(state.app.map, [visibleOne.getLatLng()])

        element.openPopup()
        updatePopupPosition(element)
      })
    })
  } else if (data.ref) {
    setTimeout(() => {
      if (data.ref?.leafletElement) {
        let element = data.ref.leafletElement

        if (
          state.app.isMobile &&
          isActiveModule('around') &&
          component.props.poiFitBoundsOnMobile &&
          data.id.startsWith('poi')
        ) {
          fitBounds(state.app.map, [element.getLatLng()])
        }

        if (!element._map) {
          element = appStore.getState().map.reduxMarkers.find(m => m.props.stop.id === data.id).props.stop
            .ref.leafletElement
        }
        element.openPopup()
        updatePopupPosition(element, data)
      }
    })
  } else {
    // On va chercher la ref dans le nouveau state et pas celui en paramètre !
    setTimeout(() => {
      if (!data.ref) {
        data.ref = getRef(data, appStore.getState().map.reduxMarkers)
      }
      if (data.ref?.leafletElement) {
        const element = data.ref.leafletElement

        element.openPopup()
        updatePopupPosition(element, data)
      }
    }, 200)
  }

  if (data.lines && component?.state?.groups) {
    const mainGroup = mostImportantGroup(component?.state?.groups, linesModes)

    if (mainGroup && mainGroup !== openedCollapse && tab === 0) {
      appStore.dispatch(actionSetOpenedCollapse(mainGroup))
    }
  }
}

/**
 * Render all map places at a minimum zoom level of 16, such as TCL places, Vélo'v & SNCF stations
 * @param mapReference
 * @param places
 * @returns {*[]}
 */
export const renderMapPlaces = (mapReference, places) => {
  const map = mapReference && mapReference.current && mapReference.current.leafletElement
  const placesRef = appStore.getState().app.placesRef
  const moreInformationsOnMap = appStore.getState().app.moreInformationsOnMap
  const placesRefBackground = placesRef ? placesRef.find(p => p.name === 'map-background') : false
  const params = getURLSearchParams(history.location)
  // placesRefBackground could be undefined
  const minZoomLevel = placesRefBackground?.minZoomLevel ? placesRefBackground.minZoomLevel : 15
  let placesFilteredByZoom = []

  if (map) {
    const currentZoom = map.getZoom()

    if (moreInformationsOnMap?.length) {
      places = places.filter(p => !p?.props?.overrideZoom)
      const toAdd = []

      for (const i of moreInformationsOnMap) {
        const { coord, id, max, min } = i.properties

        if (currentZoom >= +min && currentZoom <= +max) {
          const type = id.startsWith('stop_area:SNCF:')
            ? appStore.getState().app.stations
            : id.startsWith('stop_area:')
            ? appStore.getState().app.areas
            : id.startsWith('stop_point:')
            ? appStore.getState().app.stops
            : appStore.getState().app.places

          const data = type.find(p => p.id === id)

          if (data) {
            data.coord.lat = coord.split(',')[1]
            data.coord.lon = coord.split(',')[0]
            data.overrideZoom = true

            places = places.filter(p => p?.key !== id)

            toAdd.push(data)
          }
        }
      }

      if (toAdd.length) {
        const placesMarkers = buildPlaces(appStore.getState(), toAdd)

        for (const placeMarker of placesMarkers) {
          places.push(placeMarker)
        }
      }
    }

    placesFilteredByZoom = places.filter(p => {
      if (p?.props?.overrideZoom) {
        return true
      } else if (
        placesRefBackground.zoom &&
        placesRefBackground.zoom.ids &&
        placesRefBackground.zoom.ids[p.props.place.id] &&
        placesRefBackground.zoom.ids[p.props.place.id].min &&
        placesRefBackground.zoom.ids[p.props.place.id].max
      ) {
        return (
          currentZoom >= placesRefBackground.zoom.ids[p.props.place.id].min &&
          currentZoom <= placesRefBackground.zoom.ids[p.props.place.id].max
        )
      } else if (
        placesRefBackground &&
        placesRefBackground.zoom &&
        placesRefBackground.zoom[p.props.place.cat_id] &&
        placesRefBackground.zoom[p.props.place.cat_id].min &&
        placesRefBackground.zoom[p.props.place.cat_id].max
      ) {
        return (
          currentZoom >= placesRefBackground.zoom[p.props.place.cat_id].min &&
          currentZoom <= placesRefBackground.zoom[p.props.place.cat_id].max
        )
      } else {
        return currentZoom > minZoomLevel
      }
    })
  }

  if (
    (!history.location.pathname.includes('route-calculation') ||
      (history.location.pathname.includes('route-calculation') &&
        envVarToBool(REACT_APP_ALLOW_HEAVY_LINES_ON_ROUTECALCULATION) &&
        Object.keys(params).length < 3)) &&
    map &&
    places &&
    placesFilteredByZoom
  ) {
    return [
      <MarkerClusterGroup
        key="map-places"
        ref={ref => ref && appStore.dispatch(actionSetCluster(ref.leafletElement))}
        removeOutsideVisibleBounds
        showCoverageOnHover={false}
        iconCreateFunction={cluster => {
          return L.divIcon({
            html: cluster.getChildCount(),
            className: 'lc-cluster',
          })
        }}>
        {placesFilteredByZoom.filter(place => !isNotToClusterised(place.props.place))}
      </MarkerClusterGroup>,
      placesFilteredByZoom.filter(place => isNotToClusterised(place.props.place)),
    ]
  }
}

/**
 * Build the popup content of a Marker
 * @param state
 * @param data
 * @returns HTMLElement
 */
const buildPopup = (state, data) => {
  const { lock, modules, servicesStations, lines, component, isMobile, configApp } = state.app
  const { options } = component.props
  const params = getURLSearchParams(history.location)
  const servicesAtStation = []
  let showGoToRC = REACT_APP_GO_TO_RC_URL || !options || options?.features?.['route-calculation']

  if (configApp?.dont_display_goto_rc_if_modes) {
    if (data.lines.every(l => configApp.dont_display_goto_rc_if_modes?.split(',')?.includes(l.mode))) {
      showGoToRC = false
    }
  }

  if (showGoToRC && configApp?.hide_gotorc_button_if_one_mode && data?.lines?.length > 0) {
    const dataModes = getLinesModes(data)
    if (dataModes?.length === 1 && configApp.hide_gotorc_button_if_one_mode === dataModes[0]) {
      showGoToRC = false
    }
  }

  if (servicesStations) {
    const servicesList = servicesStations.find(s => s.id === data.id)

    if (servicesList && servicesList.services) {
      Object.keys(servicesList.services).map(serviceType => {
        return servicesAtStation.push({
          id: servicesList.services[serviceType][0].code,
          name: serviceType,
        })
      })
    }
  }

  const hasTown = configApp?.split_stops_name_and_town_by
  const splitName = hasTown ? data?.name?.split(configApp?.split_stops_name_and_town_by) : data?.name

  return (
    <div
      className={'lc-infobox' + (data.id ? '' : ' lc-no-arrow') + (data.divIcon ? ` lc-${data.color}` : '')}
      onMouseLeave={() => appStore.dispatch(actionOutMarker(data))}
      onClick={() => data.name && appStore.dispatch(actionOpenMarker(data))} // Avoid crash if there is no "real" data like sncf-ter entrance map popups
      onKeyUp={e => handleKeyUp(e, () => data.name && appStore.dispatch(actionOpenMarker(data)))}
      role="button"
      tabIndex="0">
      {data.name && (
        <div className="lc-infobox-title">
          <div
            className={
              'lc-infobox-title-name' +
              (data.picto ? ' with-picto' : '') +
              (REACT_APP_DISPLAY_STOP_ID && data.stop_number ? ' with-stop-number' : '')
            }>
            <div className={`lc-stop-and-town-name${hasTown ? ' lc-with-town' : ''}`}>
              {hasTown && <span className="lc-stop-town-town">{splitName[0]}</span>}
              <span className="lc-stop-town-name">
                {hasTown ? splitName[1] : splitName}
                {!REACT_APP_DISPLAY_ADDITIONAL_INFOS_ON_POPUP_CONTENT && REACT_APP_SHOW_PMR && (
                  <UIStopPmr pmr={data.pmr} />
                )}
              </span>
            </div>
            {data.picto && (
              <img
                className="lc-infobox-stop-picto"
                src={assetsPath('/assets/images/stops/' + data.picto + '.svg')}
                alt={data.name}
                aria-hidden="true"
              />
            )}
            {REACT_APP_DISPLAY_STOP_ID && data.stop_number && (
              <span className="lc-infobox-title-id">
                {translate('stop-number')}
                {data.stop_number}
              </span>
            )}
          </div>
          <div className="lc-infobox-title-tools">
            {REACT_APP_SHOW_ADDITIONAL_STOP_TOOL &&
              JSON.parse(REACT_APP_SHOW_ADDITIONAL_STOP_TOOL).map(tool => {
                if (data[tool] === true) {
                  return <div key={`${data.id}_${tool}`} className={`lc-is-${tool}`} />
                } else {
                  return false
                }
              })}
            <div className="lc-title-tools-right">
              {configApp?.go_to_stop_point &&
              modules.find(m => m.id === 'lines' && m.hide !== true) &&
              !lock &&
              data.id.startsWith('stop_') ? (
                <Tippy
                  theme={'latitude'}
                  touch={['hold', 500]}
                  placement={'right'}
                  boundary="window"
                  content={translate('title-go-to-stop-point')}>
                  <div
                    className="lc-tool-go-to-stop-point lc-toolSmall"
                    onClick={e => {
                      e.stopPropagation()
                      goToStopPoint(data)
                    }}
                    onKeyUp={e =>
                      handleKeyUp(e, () => {
                        goToStopPoint(data)
                      })
                    }
                    role="button"
                    tabIndex="0"
                  />
                </Tippy>
              ) : null}
              {modules.find(m => m.id === 'around' && m.hide !== true) && !lock && (
                <Tippy
                  theme={'latitude'}
                  touch={['hold', 500]}
                  placement={'right'}
                  boundary="window"
                  content={translate('title-go-to-around')}>
                  <div
                    className="lc-tool-around lc-toolSmall"
                    onClick={e => {
                      e.stopPropagation()
                      goToAround(data)
                    }}
                    onKeyUp={e =>
                      handleKeyUp(e, () => {
                        goToAround(data)
                      })
                    }
                    role="button"
                    tabIndex="0"
                  />
                </Tippy>
              )}
              {(REACT_APP_GO_TO_RC_URL || modules.find(m => m.id === 'route-calculation' && m.hide !== true)) &&
                !lock &&
                showGoToRC && (
                  <Tippy
                    theme={'latitude'}
                    touch={['hold', 500]}
                    placement={'right'}
                    boundary="window"
                    content={translate('title-go-to-route-calculation')}>
                    <div
                      className="lc-tool-route-calculation lc-toolSmall"
                      onClick={e => {
                        e.stopPropagation()
                        goToRouteCalculation(data)
                      }}
                      onKeyUp={e =>
                        handleKeyUp(e, () => {
                          goToRouteCalculation(data)
                        })
                      }
                      role="button"
                      tabIndex="0"
                    />
                  </Tippy>
                )}
              {!isMobile && configApp?.streetview && (
                <Tippy
                  theme={'latitude'}
                  touch={['hold', 500]}
                  placement={'right'}
                  boundary="window"
                  content={translate('title-streetview-link')}>
                  <div
                    className="lc-tool-streetview-link lc-toolSmall"
                    role="button"
                    tabIndex="0"
                    aria-label={translate('title-streetview-link')}
                    onClick={() => {
                      const displayStreetview = document.querySelector('.stop-infobox-streetview')

                      document
                        .querySelector('.stop-infobox-streetview iframe')
                        .setAttribute(
                          'src',
                          document.querySelector('.stop-infobox-streetview iframe').getAttribute('data-src'),
                        )

                      document.querySelector('.stop-infobox-streetview').style.display =
                        displayStreetview?.style?.display === 'block' ? 'none' : 'block'

                      document.querySelectorAll('.lc-infobox-lines').forEach(elem => {
                        if (displayStreetview?.style?.display === 'block') {
                          elem.classList.add('lc-active-streetview')
                        } else {
                          elem.classList.remove('lc-active-streetview')
                        }
                      })
                    }}
                    onKeyUp={e =>
                      handleKeyUp(e, () => {
                        const displayStreetview = document.querySelector('.stop-infobox-streetview')

                        document
                          .querySelector('.stop-infobox-streetview iframe')
                          .setAttribute(
                            'src',
                            document.querySelector('.stop-infobox-streetview iframe').getAttribute('data-src'),
                          )

                        document.querySelector('.stop-infobox-streetview').style.display =
                          displayStreetview?.style?.display === 'block' ? 'none' : 'block'

                        document.querySelectorAll('.lc-infobox-lines').forEach(elem => {
                          if (displayStreetview?.style?.display === 'block') {
                            elem.classList.add('lc-active-streetview')
                          } else {
                            elem.classList.remove('lc-active-streetview')
                          }
                        })
                      })
                    }
                  />
                </Tippy>
              )}
            </div>
          </div>
        </div>
      )}
      {data.severity && ['blocking', 'delays'].includes(data.severity) && (
        <div className={'lc-severity lc-' + data.severity}>
          <div className="lc-disruption-severity">
            <div className="lc-icon" />
            {JSON.parse(REACT_APP_DISRUPTION).titleInsteadOfSeverity === true && data.title
              ? data.title
              : data.severity === 'blocking'
              ? 'Perturbation majeure'
              : data.severity === 'delays'
              ? 'Perturbation'
              : 'Information'}
          </div>
          {data.severity === 'blocking'
            ? `${translate('severity-blocking-stop')} ${
                getLine(lines, {
                  id: params.current.substring(0, params.current.lastIndexOf('_')),
                })?.code
              }`
            : translate('severity-delays-stop', false, {
                key: 'line',
                value: getLine(lines, {
                  id: params.current.substring(0, params.current.lastIndexOf('_')),
                })?.code,
              })}
        </div>
      )}
      {servicesAtStation.length > 0 && (
        <div className="lc-infobox-services-station">
          <span>{translate('infobox-services-title')}</span>
          <div className="lc-services-list">
            {servicesAtStation.map(service => {
              return (
                <Tippy
                  key={service.id}
                  theme={'latitude'}
                  touch={['hold', 500]}
                  delay={[15, 0]}
                  placement={'right'}
                  boundary="window"
                  content={translate(service.name)}>
                  <img src={assetsPath('/assets/images/places/' + service.name + '.svg')} alt={service.name} />
                </Tippy>
              )
            })}
          </div>
        </div>
      )}
      {configApp?.streetview && !isMobile && (
        <div className="lc-streetview stop-infobox-streetview">
          <iframe
            title="streetview"
            style={{ border: 0 }}
            loading="lazy"
            height="350px"
            width="500px"
            data-src={`https://www.google.com/maps/embed/v1/streetview?key=${configApp?.streetview}&location=${data.coord.lat},${data.coord.lon}`}></iframe>
        </div>
      )}
      <div className="lc-infobox-content">
        <>
          {REACT_APP_DISPLAY_ADDITIONAL_INFOS_ON_POPUP_CONTENT &&
            data.id.includes('stop_point') &&
            ['pmr', 'both'].includes(REACT_APP_SHOW_PMR) && (
              <div className="lc-infobox-content-additional">
                <UIStopPmr pmr={data.pmr} displayon="popup" />
              </div>
            )}
          {data.cat_id || data instanceof BikeInterface
            ? buildPopupContent(state, data)
            : configApp?.schedules_in_popup !== true ||
              (configApp?.schedules_in_popup === true &&
                !data.id.startsWith('stop_point') &&
                !data.id.startsWith('stop_area'))
            ? buildLinesLabels(state, data, 'infobox')
            : null}
          {configApp?.schedules_in_popup === true && data.id.startsWith('stop_point') ? (
            <UIPopupLinesByStop stop={data} componentState={state} dataKey="infobox" />
          ) : null}
          {configApp?.schedules_in_popup === true && data.id.startsWith('stop_area') ? (
            <UIPopupLinesByArea area={data} componentState={state} dataKey="infobox" />
          ) : null}
        </>
      </div>
    </div>
  )
}

/**
 * Display popup content for places with a cat_id
 * @param state
 * @param data
 * @returns HTMLElement
 */
const buildPopupContent = (state, data) => {
  const { component } = state.app
  const { options } = component.props // TODO : retrieve options data directly from postMessage to, in case data is not passed at init

  return (
    <div className="lc-place">
      <UIPoiContent place={data} displayon="map" options={options} />
    </div>
  )
}

/**
 * Build lines labels in infobox / board
 * TODO : recode board side
 * @param state
 * @param data
 * @param key
 * @returns HTMLElement
 */
export const buildLinesLabels = (state, data, key, option = { onlyProximityLines: false }) => {
  let lines = data.lines
  const { areas, stops, lock, linesModes, size, component } = state.app
  const { options } = component.props
  const canChangeLine = options?.features?.['change-line'] === false ? false : true
  const stopArea = envVarToBool(REACT_APP_PROXIMITY_LINES_AT_STOP) ? areas.find(a => a.id === data.stop_area) : null
  const proximityLines = []
  const addCatPictoBeforeLines = envVarToBool(REACT_APP_POPUP_GROUP_LINES_BY_CAT) === true
  const params = getURLSearchParams(history.location)

  const mergeCatPictoBeforeLines =
    REACT_APP_POPUP_GROUP_LINES_BY_CAT_MERGE_CAT && JSON.parse(REACT_APP_POPUP_GROUP_LINES_BY_CAT_MERGE_CAT)
      ? JSON.parse(REACT_APP_POPUP_GROUP_LINES_BY_CAT_MERGE_CAT)
      : {}

  if (stopArea) {
    for (const l of stopArea.lines) {
      if (!lines.find(line => line.id === l.id)) {
        proximityLines.push(l)
      }
    }
  }

  // Avoid undefined lines...
  if (!lines) {
    // TODO Add custom info, like addresses for POI (https://latitude-cartagene.atlassian.net/browse/TCL-224)
    return null
  }

  // SNCF ??
  lines = lines.map(line => getLine(state.app.lines, line))
  let styleLine = REACT_APP_LINES_MAIN_TYPE ? REACT_APP_LINES_MAIN_TYPE : 'color'
  let prefixNetwork = false
  const canClickLine = !lock && canChangeLine

  const handleClickLine = (e, line, data, proximity) => {
    e.stopPropagation()

    let proximityStop = null

    if (proximity) {
      const stopsAtArea = stops.filter(s => s.stop_area === data.stop_area)

      if (stops) {
        proximityStop = stopsAtArea.find(s => s.lines.find(l => l.id === line.id))
      }
    }

    if (!lock && canChangeLine) {
      message({ clicked: 'line', id: line.id })
      // ! TODO DON'T GO IN LINES TAB
      appStore.dispatch(actionOnLineSelected(line, proximityStop ? proximityStop : data))
    }
  }

  // Use to know how many rows we should have on our popup
  const gridRows = Math.ceil(lines.length / 2)

  const div = (lines, proximity = false) => (
    <div
      key={key + Math.random()}
      className={
        (key === 'infobox' ? 'lc-infobox-' : 'lc-') +
        'lines lc-' +
        size +
        (styleLine.includes('WithDirection') ? ' lc-line-with-direction' : '') +
        (key === 'infobox' && data?.id?.startsWith('stop_point:') && styleLine.includes('WithRouteDirection')
          ? ' lc-line-with-route-direction'
          : '') +
        (addCatPictoBeforeLines ? ' lc-cat-picto' : '') +
        (canClickLine ? ' lc-interactive' : '')
      }
      style={
        styleLine.includes('WithRouteDirection') && data?.id?.startsWith('stop_point:') && key === 'infobox'
          ? {
              gridTemplateRows: `repeat(${gridRows}, 1fr)`,
            }
          : {}
      }>
      {Object.keys(lines).map(m =>
        unique(lines[m], 'id').map((line, lineindex, linesarray) => {
          // Retrieve the global line
          styleLine = REACT_APP_LINES_MAIN_TYPE ? REACT_APP_LINES_MAIN_TYPE : 'color'
          line = getLine(state.app.lines, line)

          let paramsLine = params.current || params.line
          paramsLine = paramsLine?.includes('_f')
            ? paramsLine?.split('_f')[0]
            : paramsLine?.includes('_b')
            ? paramsLine.split('_b')[0]
            : false

          if (REACT_APP_LINES_TYPE_EXCEPTIONS) {
            const exceptions = JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS)

            const foundExceptedLine = exceptions.find(
              e => e.lines?.includes(line.id) || e.networks?.includes(line.network),
            )

            if (foundExceptedLine) {
              styleLine = foundExceptedLine.type
              prefixNetwork = foundExceptedLine.prefixNetwork === true
            }
          }

          let element
          let pictomode

          if (addCatPictoBeforeLines && line.cat !== linesarray[lineindex - 1]?.cat) {
            pictomode = (
              <>
                <div className="break" />
                <img
                  key={`line-cat-${line.cat}`}
                  className="lc-line-cat-picto"
                  src={assetsPath(`/assets/images/route-calculation/modes/${line.cat}.svg`)}
                  alt={line.cat}
                />
              </>
            )
          }

          if (lineindex > 0 && mergeCatPictoBeforeLines[line.cat] && pictomode) {
            pictomode = null
          }

          switch (styleLine) {
            case 'modeWithDirection': {
              const lineMode = linesModes.find(mode => mode.modes.includes(line.mode))

              element = (
                <div
                  className="lc-attribute-line"
                  key={line.id}
                  onClick={e => handleClickLine(e, line, data, proximity)}
                  onKeyUp={e => handleKeyUp(e, () => handleClickLine(e, line, data, proximity))}
                  role={canClickLine ? 'button' : 'img'}
                  tabIndex="0"
                  aria-label={translate('aria-line', false, { key: 'code', value: line.code })}>
                  <UIDisruptedLineOverlay line={line} styleLine={styleLine} inpopup={true}>
                    <div
                      className="lc-line lc-mode"
                      style={{
                        background: '#' + line.color,
                        color: luminance('#' + line.color) > 0.5 ? '#333' : '#fff',
                      }}>
                      {lineMode.name}
                    </div>
                    <div className="lc-name">{line.name}</div>
                  </UIDisruptedLineOverlay>
                </div>
              )
              break
            }
            case 'codeWithDirection':
              element = (
                <div
                  className="lc-attribute-line"
                  key={line.id}
                  onClick={e => handleClickLine(e, line, data, proximity)}
                  onKeyUp={e => handleKeyUp(e, () => handleClickLine(e, line, data, proximity))}
                  role={canClickLine ? 'button' : 'img'}
                  tabIndex="0"
                  aria-label={translate('aria-line', false, { key: 'code', value: line.code })}>
                  <UIDisruptedLineOverlay line={line} styleLine={styleLine} inpopup={true}>
                    <div
                      className="lc-line lc-code"
                      style={{
                        background: '#' + line.color,
                        color: luminance('#' + line.color) > 0.5 ? '#333' : '#fff',
                      }}>
                      {line.code}
                    </div>
                  </UIDisruptedLineOverlay>
                  <div className="lc-name">{line.name}</div>
                </div>
              )
              break
            case 'imageWithRouteDirection':
            case 'image':
              element = (
                <div
                  className={`lc-line${paramsLine === line.id ? ' no-line-module' : ''}`}
                  key={line.id}
                  onClick={e => handleClickLine(e, line, data, proximity)}
                  onKeyUp={e => handleKeyUp(e, () => handleClickLine(e, line, data, proximity))}
                  role={canClickLine ? 'button' : 'img'}
                  tabIndex="0"
                  aria-label={translate('aria-line', false, { key: 'code', value: line.code })}>
                  <UIDisruptedLineOverlay line={line} styleLine={styleLine} inpopup={true}>
                    <img
                      src={assetsPath(
                        '/assets/images/lines/' + (prefixNetwork ? line.network + '-' : '') + line.code + '.svg',
                      )}
                      alt={line.code}
                      aria-hidden="true"
                    />
                  </UIDisruptedLineOverlay>
                  {styleLine === 'imageWithRouteDirection' && line.direction && (
                    <div className="lc-name">{line.direction}</div>
                  )}
                </div>
              )
              break
            case 'color':
              element = (
                <div
                  key={line.id}
                  className="lc-line"
                  onClick={e => handleClickLine(e, line, data)}
                  onKeyUp={e => handleKeyUp(e, () => handleClickLine(e, line, data))}
                  role={canClickLine ? 'button' : 'img'}
                  tabIndex="0"
                  aria-label={translate('aria-line', false, { key: 'code', value: line.code })}>
                  <div className="lc-tools-at-line">
                    {REACT_APP_SHOW_ADDITIONAL_STOP_TOOL &&
                      JSON.parse(REACT_APP_SHOW_ADDITIONAL_STOP_TOOL).map(tool => {
                        if (data[tool] && data[tool].length && data[tool].includes(`${line.code}_${line.network}`)) {
                          return <div key={`${data.id}_${tool}`} className={`lc-is-${tool}`} />
                        } else {
                          return false
                        }
                      })}
                  </div>
                  <UIDisruptedLineOverlay line={line} styleLine={styleLine} inpopup={true}>
                    <div
                      className="lc-line-code"
                      style={{
                        background: '#' + line.color,
                        color: luminance(line.color) > 0.5 ? '#333' : '#fff',
                      }}>
                      {line.code}
                    </div>
                  </UIDisruptedLineOverlay>
                </div>
              )
              break
            default:
              element = ''
              break
          }

          return (
            <Fragment key={`f-l-p-${line.id}`}>
              {pictomode}
              {element}
            </Fragment>
          )
        }),
      )}
    </div>
  )

  if (option.onlyProximityLines) {
    if (proximityLines.length > 0) {
      return (
        <>
          {Object.keys(groupLinesByMode(proximityLines, linesModes)).length > 0 ? (
            <div className="lc-connections-at-stop">{translate('proximity-at-stop')}</div>
          ) : null}
          {div(groupLinesByMode(proximityLines, linesModes), true)}
        </>
      )
    } else {
      return null
    }
  } else {
    return (
      <>
        {envVarToBool(REACT_APP_CONNECTIONS_TEXT) && (
          <div className="lc-connections-at-stop">{translate('connections-at-stop')}</div>
        )}
        {div(groupLinesByMode(lines, linesModes))}
        {option.buttonSeeMoreSchedulesStopPoint ? option.buttonSeeMoreSchedulesStopPoint : null}
        {proximityLines.length > 0 && (
          <>
            {Object.keys(groupLinesByMode(proximityLines, linesModes)).length > 0 ? (
              <div className="lc-connections-at-stop">{translate('proximity-at-stop')}</div>
            ) : null}
            {div(groupLinesByMode(proximityLines, linesModes), true)}
            {option.buttonSeeMoreSchedulesStopArea ? option.buttonSeeMoreSchedulesStopArea : null}
          </>
        )}
      </>
    )
  }
}

/**
 * Try to bring all features clicked when we clicked an element on a certain feature
 * Ex: FeatureGroup on Leaflet.js for heavyLines
 */
export const clickThrough = (map, e, callback) => {
  const point = [e.latlng.lng, e.latlng.lat]
  const featuresClickedThrough = []

  const metresPerPixel =
    (40075016.686 * Math.abs(Math.cos((map.getCenter().lat / 180) * Math.PI))) / Math.pow(2, map.getZoom() + 8)

  const BUFFER_SIZE = metresPerPixel * 7

  map.eachLayer(function (overlay) {
    if (overlay.options?.interactive) {
      if (overlay._layers) {
        overlay.eachLayer(function (feature) {
          if (feature.options.className.includes('lc-geojson-click-through')) {
            // if the clicked point is in the buffer around the given line (feature), save it

            if (feature.feature.geometry.type === 'LineString') {
              const d = ptld(point, feature.feature, { units: 'meters' })

              if (d <= BUFFER_SIZE) {
                featuresClickedThrough.push(feature)
              }
            } else if (feature.feature.geometry.type === 'MultiLineString') {
              feature.feature.geometry.coordinates.forEach(coordinate => {
                const linestring = {
                  type: 'Feature',
                  geometry: {
                    type: 'LineString',
                    coordinates: coordinate,
                  },
                }

                const d = ptld(point, linestring, { units: 'meters' })

                if (d <= BUFFER_SIZE) {
                  featuresClickedThrough.push(feature)
                }
              })
            }
          }
        })
      }
    }
  })

  callback(featuresClickedThrough)
}

/*
 * Build popup content with lines through the clicked point on the map
 */
export const buildLinesIntersecPopup = (map, lines, latlng) => {
  const popupcontent = document.createElement('div')

  const popup = new L.popup({
    className: 'lc-popup-leaflet lc-popup-clickthrough',
    closeButton: false,
    autoClose: false,
    autoPan: false,
  })

  ReactDOM.render(
    <Provider store={appStore}>
      <UIIntersecPopup lines={lines} popup={popup} />
    </Provider>,
    popupcontent,
  )

  popup.setContent(popupcontent).setLatLng(latlng).openOn(map)

  const element = popup.getElement()

  popup.options.offset = new L.Point(element.offsetWidth / 2 + 17, element.offsetHeight - 2.5)

  popup.update()
}
