import axios from '../middlewares/axios'
import { unique } from '../services/tools'
import { formatDate } from '../utils/tools'

const JourneysInterface = async (data, stop_points, project, component) => {
  const journeys = []
  switch (project) {
    case 'bordeaux-tbm-azure': {
      let tbmjourneysIds = []
      if (data?.bike?.journeys?.length > 0) {
        tbmjourneysIds.push(...data.bike.journeys.map(j => j.id))
      }
      if (data?.car?.carParkJourneys?.length > 0) {
        tbmjourneysIds.push(...data.car.carParkJourneys.map(j => j.id))
      }
      if (data?.car?.parkAndRideJourneys?.length > 0) {
        tbmjourneysIds.push(...data.car.parkAndRideJourneys.map(j => j.id))
      }
      if (data?.walking?.journeys?.length > 0) {
        tbmjourneysIds.push(...data.walking.journeys.map(j => j.id))
      }
      if (data?.publicTransport?.onDemandTransportJourneys?.length > 0) {
        tbmjourneysIds.push(...data.publicTransport.onDemandTransportJourneys.map(j => j.id))
      }
      if (data?.publicTransport?.publicTransportJourneys?.length > 0) {
        tbmjourneysIds.push(...data.publicTransport.publicTransportJourneys.map(j => j.id))
      }

      tbmjourneysIds = tbmjourneysIds.filter(id => component?.state?.journeys?.find(j => j.id === id) === undefined)
      if (component?.state?.journeys?.length > 0) {
        journeys.push(...component.state.journeys)
      }
      if (tbmjourneysIds.length > 0) {
        const tbmjourneysResponses = await Promise.all(
          unique(tbmjourneysIds).map(id => axios(`${process.env.REACT_APP_KBM_GATEWAY_MAAS}/web/v2/journeys/${id}`)),
        )
        if (tbmjourneysResponses.length > 0) {
          for (const tbmjourneyresponse of tbmjourneysResponses) {
            if (tbmjourneyresponse.data) {
              const tbmjourney = tbmjourneyresponse.data
              const distances = {
                taxi: 0,
                car: 0,
                walking: 0,
                bike: 0,
                ridesharing: 0,
              }
              const durations = {
                taxi: 0,
                walking: 0,
                car: 0,
                ridesharing: 0,
                bike: 0,
                total: 0,
              }
              const formatedJourney = {
                id: tbmjourney.id,
                arrival_date_time: formatDate(new Date(tbmjourney.arrivalDateTime), 'ymdThms'),
                departure_date_time: formatDate(new Date(tbmjourney.departureDateTime), 'ymdThms'),
                distances: distances,
                durations: durations,
                co2_emission: {
                  value: tbmjourney.geqCo2Emission,
                  unit: 'gEC',
                },
                duration: tbmjourney.duration,
                sections: tbmjourney.steps
                  .map((step, stepindex) =>
                    formatedTbmSection(
                      step,
                      tbmjourney.steps[stepindex - 1],
                      tbmjourney.steps[stepindex + 1],
                      stop_points,
                    ),
                  )
                  .flat(),
                disruptions: [],
                links: [],
                tags: [],
                fare: {},
                requested_date_time: '',
                nb_transfers: 0,
                status: '',
                type: 'rapid',
                calendars: [],
              }

              journeys.push(formatedJourney)
            }
          }
        }
      }

      break
    }
    default:
      return data
  }
  return journeys
}

const formatedTbmSection = (tbmsection, prevsection, nextsection, stop_points) => {
  const formatedFromTo = obj => {
    switch (obj.type) {
      case 'ADDRESS':
        return {
          embedded_type: 'address',
          id: obj.id,
          name: obj.location,
          address: {
            name: obj.location,
            house_number: 0,
            coord: {
              lat: obj.coordinates.latitude,
              lon: obj.coordinates.longitude,
            },
            label: obj.location,
            administrative_regions: [],
            id: obj.id,
          },
        }
      case 'ADMINISTRATIVE_REGION':
        return {
          embedded_type: 'administrative_region',
          id: obj.id,
          name: obj.location,
          administrative_region: {
            id: obj.id,
            name: obj.location,
            label: obj.location,
            coord: {
              lat: obj.coordinates.latitude,
              lon: obj.coordinates.longitude,
            },
            administrative_regions: [],
          },
        }
      case 'POI':
        return {
          embedded_type: 'poi',
          id: obj.id,
          name: obj.location,
          poi: {
            id: obj.id,
            name: obj.location,
            label: obj.location,
            coord: {
              lat: obj.coordinates.latitude,
              lon: obj.coordinates.longitude,
            },
            administrative_regions: [],
            poi_type: {
              id: '',
              name: '',
            },
          },
        }
      case 'STOP_AREA':
        return {
          embedded_type: 'stop_area',
          id: obj.id,
          name: obj.location,
          stop_area: {
            id: obj.id,
            name: obj.location,
            label: obj.location,
            coord: {
              lat: obj.coordinates.latitude,
              lon: obj.coordinates.longitude,
            },
          },
        }
      case 'STOP_POINT':
        return {
          embedded_type: 'stop_point',
          id: obj.id,
          name: obj.location,
          stop_point: {
            id: obj.id,
            name: obj.location,
            label: obj.location,
            coord: {
              lat: obj.coordinates.latitude,
              lon: obj.coordinates.longitude,
            },
          },
        }
      default:
        return {}
    }
  }

  const formatedSection = {
    id: `section_${Math.random()}`,
    from: formatedFromTo(tbmsection.departure),
    to: formatedFromTo(tbmsection.arrival),
    departure_date_time: formatDate(new Date(tbmsection.departure.dateTime), 'ymdThms'),
    arrival_date_time: formatDate(new Date(tbmsection.arrival.dateTime), 'ymdThms'),
    duration: tbmsection.duration,
    links: [],
    disruptions: [],
  }

  if (tbmsection.path?.length > 0) {
    formatedSection.geojson = {
      type: 'LineString',
      coordinates: tbmsection.path.map(p => [p.longitude, p.latitude]),
      properties: [
        {
          length: tbmsection.distance,
        },
      ],
    }
  }

  let formatedBssRent = false
  let formatedBssPutBack = false
  let formatedParkSection = false

  switch (tbmsection.mode) {
    case 'BIKE':
      formatedSection.mode = 'bike'
      formatedSection.type = 'street_network'
      break
    case 'BSS': {
      formatedSection.mode = 'bike'
      formatedSection.type = 'street_network'

      formatedBssRent = {
        from: formatedFromTo(prevsection.arrival),
        to: formatedFromTo(tbmsection.departure),
        arrival_date_time: formatDate(new Date(tbmsection.departure.dateTime), 'ymdThms'),
        departure_date_time: formatDate(new Date(tbmsection.departure.dateTime), 'ymdThms'),
        duration: 0,
        type: 'bss_rent',
        mode: 'bike',
        id: `section_${Math.random()}`,
        realtime: { available: 0, available_bikes: 0, available_places: 0 },
      }
      formatedBssRent.to.poi.poi_type = {
        id: 'poi_type:amenity:bicycle_rental',
        name: 'amenity:bicycle_rental',
      }
      formatedBssPutBack = {
        from: formatedFromTo(tbmsection.arrival),
        to: formatedFromTo(nextsection.departure),
        arrival_date_time: formatDate(new Date(tbmsection.arrival.dateTime), 'ymdThms'),
        departure_date_time: formatDate(new Date(tbmsection.arrival.dateTime), 'ymdThms'),
        duration: 0,
        type: 'bss_put_back',
        mode: 'bike',
        id: `section_${Math.random()}`,
        realtime: { available: 0, available_bikes: 0, available_places: 0 },
      }
      formatedBssPutBack.from.poi.poi_type = {
        id: 'poi_type:amenity:bicycle_rental',
        name: 'amenity:bicycle_rental',
      }
      formatedSection.from.poi.poi_type = {
        id: 'poi_type:amenity:bicycle_rental',
        name: 'amenity:bicycle_rental',
      }
      formatedSection.to.poi.poi_type = {
        id: 'poi_type:amenity:bicycle_rental',
        name: 'amenity:bicycle_rental',
      }
      break
    }
    case 'CAR':
      formatedSection.mode = 'car'
      formatedSection.type = 'street_network'
      formatedSection.to.poi.poi_type = {
        id: 'poi_type:amenity:parking',
        name: 'Parc Relais',
      }
      formatedParkSection = {
        from: formatedFromTo(tbmsection.arrival),
        to: formatedFromTo(nextsection.departure),
        departure_date_time: formatDate(new Date(tbmsection.departure.dateTime), 'ymdThms'),
        arrival_date_time: formatDate(new Date(tbmsection.arrival.dateTime), 'ymdThms'),
        duration: 0,
        type: 'park',
        id: `section_${Math.random()}`,
      }
      formatedParkSection.from.poi.poi_type = {
        id: 'poi_type:amenity:parking',
        name: 'Parc Relais',
      }
      formatedParkSection.to.poi.poi_type = {
        id: 'poi_type:amenity:parking',
        name: 'Parc Relais',
      }
      break
    case 'WALKING':
      formatedSection.mode = 'walking'
      formatedSection.type = 'street_network'
      break
    default:
      {
        const PTmodes = {
          TRAMWAY: 'TRAMWAY',
          BUS: 'BUS',
          ON_DEMAND_TRANSPORT: 'BUS',
          FERRY: 'FERRY',
          SCHOOL_BUS: 'BUSSCOLAIRE',
          TRAIN: 'TER',
          REGIONAL_BUS: 'AUTOCAR',
        }
        if (PTmodes[tbmsection.mode] !== undefined) {
          formatedSection.type = 'public_transport'
          formatedSection.display_informations = {
            direction: tbmsection.route?.terminus ? tbmsection.route.terminus : '',
            code: tbmsection.line?.code ? tbmsection.line.code : '',
            color: tbmsection.line?.style?.color ? tbmsection.line.style.color.replace('#', '') : '',
            text_color: tbmsection.line?.style?.textColor ? tbmsection.line.style.textColor.replace('#', '') : '',
            commercial_mode: tbmsection.line?.mode ? 'commercial_mode:' + PTmodes[tbmsection.line.mode] : '',
            physical_mode: tbmsection.line?.mode ? 'physical_mode:' + PTmodes[tbmsection.line.mode] : '',
            network: 'bordeaux-tbm',
          }

          const fromStopPoint = stop_points.find(sp =>
            formatedSection.from.stop_point
              ? formatedSection.from.stop_point.id === sp.id
              : formatedSection.from.stop_area
              ? formatedSection.from.stop_area.id === sp.stop_area
              : {
                  id: tbmsection.departure.id,
                  name: tbmsection.departure.location,
                  label: tbmsection.departure.location,
                  coord: {
                    lat: tbmsection.departure.coordinates.latitude,
                    lon: tbmsection.departure.coordinates.longitude,
                  },
                },
          )
          const toStopPoint = stop_points.find(sp =>
            formatedSection.to.stop_point
              ? formatedSection.to.stop_point.id === sp.id
              : formatedSection.to.stop_area
              ? formatedSection.to.stop_area.id === sp.stop_area
              : {
                  id: tbmsection.arrival.id,
                  name: tbmsection.arrival.location,
                  label: tbmsection.arrival.location,
                  coord: {
                    lat: tbmsection.arrival.coordinates.latitude,
                    lon: tbmsection.arrival.coordinates.longitude,
                  },
                },
          )
          if (fromStopPoint && toStopPoint) {
            formatedSection.stop_date_times = [
              { stop_point: fromStopPoint, links: [] },
              { stop_point: toStopPoint, links: [] },
            ]
          } else {
            formatedSection.stop_date_times = []
          }
        }
      }
      break
  }

  return formatedBssRent && formatedBssPutBack
    ? [formatedBssRent, formatedSection, formatedBssPutBack]
    : formatedParkSection
    ? [formatedSection, formatedParkSection]
    : [formatedSection]
}

export default JourneysInterface
