import axios from '../../middlewares/axios'
import { luminance } from 'luminance-js'
import { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import history from '../../history'
import Collapse from '@kunukn/react-collapse'
import { Circle, Marker, Polygon, Polyline, Popup, Tooltip } from 'react-leaflet'
import L from 'leaflet'
import PolylineDecorator from './PolylineDecorator'
import Tippy from '@tippy.js/react'
import {
  getColorGroup,
  groupLinesByMode,
  sortBy,
  substringCoords,
  timetableDataIsEmpty,
  unique,
  buildTimetableDatas,
  sortAlphabetic,
} from './tools'
import { dateIsAtLeastTomorrow, formatDate, navitiaDateToDate } from '../tools'
import { appStore } from '../../store'
import {
  actionBuildPlacesByCatInList,
  actionOnLineSelected,
  actionOpenMarker,
  actionOutMarker,
  actionOverMarker,
} from '../../actions/withRedux'
import { actionSetAroundPin, actionSetLinesToDisplay } from '../../actions/map'
import {
  addHistoricItem,
  getURLSearchParams,
  disruptionsDatetime,
  resize,
  storageAvailable,
  assetsPath,
  handleKeyUp,
  translate,
  mostImportantGroup,
  isActiveModule,
  addGetParam,
  envVarToBool,
  updatePopupPosition,
  showFavorites,
  initDateTimePicker,
  isDefinedAsTAD,
  displayDisruptionsForOneStop,
} from '../../services/tools'
import {
  actionSetLineInformation,
  actionSetOpenedCollapse,
  actionSetPlaceClicked,
  actionSetDisruptionInLine,
  actionSetTimetableData,
  actionSetLinesSchedules,
} from '../../actions/board'
import { updateDataLayer } from '../../tracking'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { message } from './../../services/message'
import UICalendar from '../../components/styled/UICalendar'
import { actionHideHeavyLines } from '../../actions/map'
import ReactTooltip from 'react-tooltip'
import { updateFavorite } from '../../services/board'
import UIDisruptedLineOverlay from '../../components/styled/UIDisruptedLineOverlay'
import UIStopScheduleTime from '../../components/styled/UIStopScheduleTime'
import UIStopPmr from '../../components/styled/UIStopPmr'
import { buildCompleteLines } from '../../services/map'
import { actionSetCalendarDate } from '../../actions/app'
import UILinesByArea from '../../components/styled/UILinesByArea'
import { UIArrow } from '../../components/styled/UIArrow'
import exportedSCSS from '../../scss/app.scss'
import { UILine } from '../../components/styled/UILine'
import UITerminusShape from '../../components/styled/UITerminusShape'
import { useSelector } from 'react-redux'

const {
  REACT_APP_LINES_MAIN_TYPE,
  REACT_APP_LINES_TYPE_EXCEPTIONS,
  REACT_APP_MODES_LINES_STYLE,
  REACT_APP_TIMETABLES,
  REACT_APP_TIMETABLES_DISABLE_ON,
  REACT_APP_NEXT_SCHEDULES_RESULTS,
  REACT_APP_NIGHT_LINES,
  REACT_APP_SHOW_PMR,
  REACT_APP_SHOW_ADDITIONAL_STOP_TOOL,
  REACT_APP_DISRUPTION,
  REACT_APP_SCHEDULES_EXTENDS,
  REACT_APP_API_PROXY_URL,
  REACT_APP_FAVORITES,
  REACT_APP_ALL_POPUP_ON_TOP,
  REACT_APP_SORT_LINES_BY_CODE,
} = process.env
const { primarycolor, secondaryjourney, recommendedRoadsColor, normalRoadsColor, discouragedRoadsColor } = exportedSCSS

const nextSchedules = REACT_APP_NEXT_SCHEDULES_RESULTS ? REACT_APP_NEXT_SCHEDULES_RESULTS : 2

const Timetable = props => {
  const { configApp } = useSelector(state => state.app)
  let step = 'morning'
  const now = new Date().getHours()
  const { line, timetableStop, print, data, isMobile } = props
  const nightLines = REACT_APP_NIGHT_LINES ? JSON.parse(REACT_APP_NIGHT_LINES) : { departure: '00', lines: [] }
  const isNightline = nightLines.lines.includes(line.id)
  const departure = +nightLines.departure
  const cellSizes = { large: 46.25, small: 31.25, mobile: (window.innerWidth - 60) / 8 }
  const boardSize = appStore.getState().app.size
  const cellSize = isMobile ? cellSizes['mobile'] : cellSizes[boardSize] ? cellSizes[boardSize] : cellSizes['large']

  const hours = props.hours
    ? props.hours
    : {
        morning: Array.from(new Array(8), (_, i) => i + (isNightline ? departure : 4)).map(x => (x >= 24 ? x - 24 : x)),
        afternoon: Array.from(new Array(8), (_, i) => i + (isNightline ? departure + 8 : 12)).map(x =>
          x >= 24 ? x - 24 : x,
        ),
        evening: Array.from(new Array(8), (_, i) => i + (isNightline ? departure + 16 : 20)).map(x =>
          x >= 24 ? x - 24 : x,
        ),
      }

  for (const key of Object.keys(hours)) {
    if (hours[key].includes(now)) {
      step = key
    }
  }

  const Arrow = ({ className, style, onClick, arrow, hours }) => {
    const next = arrow === 'next'
    const previous = next === false
    let label = ''

    if (currentStep === 'morning' && next) {
      label = `${hours['afternoon'][0]}h - ${hours['afternoon'][hours['afternoon'].length - 1]}h`
    }

    if (currentStep === 'afternoon') {
      const step = next ? 'evening' : 'morning'

      if (hours[step].length > 0) {
        label = `${hours[step][0]}h - ${hours[step][hours[step].length - 1]}h`
      }
    }

    if (currentStep === 'evening' && previous) {
      label = `${hours['afternoon'][0]}h - ${hours['afternoon'][hours['afternoon'].length - 1]}h`
    }

    return (
      <div
        className={className}
        style={{ ...style }}
        onClick={onClick}
        onKeyUp={e => handleKeyUp(e, onClick)}
        role="button"
        tabIndex="0"
        aria-label={translate('aria-lines-timetable-see-hours', false, { key: 'hours', value: label })}>
        <span>{label}</span>
      </div>
    )
  }

  const [body, setBody] = useState([])
  const [currentStep, setCurrentStep] = useState(String(step))
  let minutes = []

  const settings = {
    adaptiveHeight: true,
    className: 'lc-timetable',
    dots: true,
    infinite: false,
    initialSlide: Object.keys(hours)
      .filter(h => hours[h].length > 0)
      .indexOf(step),
    speed: 200,
    beforeChange: (_, next) =>
      setTimeout(() => setCurrentStep(next === 0 ? 'morning' : next === 1 ? 'afternoon' : 'evening')),
    nextArrow: <Arrow arrow="next" hours={hours} />,
    prevArrow: <Arrow arrow="previous" hours={hours} />,
  }

  const Slide = ({ data, otherDirections, step }) => {
    // Others directions
    minutes = []

    return (
      <div>
        <div style={{ marginRight: (8 - hours[step].length) * cellSize + 'px' }}>
          <table key={step}>
            <thead>
              <tr>
                {hours[step].map(hour => (
                  <th key={hour} tabIndex="0">
                    {hour}h
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{buildMinutesLine(step, data, otherDirections, [])}</tbody>
          </table>
        </div>
        {otherDirections.length > 0 && (
          <div className="lc-otherDirections" data-lc-other-directions>
            <div className="lc-otherdirectionsContent">{translate('timetable-others-destinations')} :</div>
            {otherDirections.map((direction, i) => (
              <div key={direction} className="lc-otherdirectionsContent">
                <span className="lc-otherdirectionsContentLetter">{String.fromCharCode(i + 97)}</span> : {direction}
              </div>
            ))}
          </div>
        )}
        {!print && translate('notes-timetable') && (
          <div className="lc-notes-timetable" data-lc-notes-timetable>
            <img src={assetsPath('/assets/images/disruptions/notice.svg')} alt={translate('notice')} />
            <div
              dangerouslySetInnerHTML={{
                __html: translate('notes-timetable'),
              }}
            />
          </div>
        )}
      </div>
    )
  }

  // Data is already stepped
  const buildMinutesLine = (step, data, otherDirections, acc, line = []) => {
    for (const i of hours[step]) {
      const time = data.find(d => +d.date_time.split('T')[1].substring(0, 2) === i && acc.indexOf(d) < 0)

      acc.push(time)
      line.push(time)
    }

    minutes.push(
      <tr key={step + '-' + minutes.length}>
        {line.map((a, i) => {
          const dirs = []

          if (a) {
            for (const direction of a.directions) {
              if (dirs.indexOf(direction) < 0) {
                dirs.push(direction)
              }
            }
          }

          return (
            <td key={i}>
              <div tabIndex="0" role="button">
                {a && a.date_time.split('T')[1].substring(2, 4)}{' '}
                {dirs.map(dir => {
                  const realDir = otherDirections.indexOf(dir)

                  if (realDir >= 0) {
                    return <span key={realDir}>{String.fromCharCode(realDir + 97)}</span>
                  } else {
                    return null
                  }
                })}
              </div>
            </td>
          )
        })}
      </tr>,
    )

    while (acc.filter(a => a).length < data.length) {
      buildMinutesLine(step, data, otherDirections, acc)
    }

    return minutes
  }

  const buildNightlineData = data => {
    let times = []

    // Flatten all times into one array
    for (const key of Object.keys(data)) {
      times = times.concat(...data[key])
    }

    // Rebuild the correct array of times depeding on each day step
    return {
      morning: times.filter(time => hours['morning'].includes(+time.date_time.split('T')[1].substring(0, 2))),
      afternoon: times.filter(time => hours['afternoon'].includes(+time.date_time.split('T')[1].substring(0, 2))),
      evening: times.filter(time => hours['evening'].includes(+time.date_time.split('T')[1].substring(0, 2))),
    }
  }

  useEffect(() => {
    const times = isNightline ? buildNightlineData(data) : data
    const otherDirections = []
    // Try if there is no data
    let isEmpty = true

    for (const step of Object.keys(times)) {
      if (times[step].length > 0) {
        isEmpty = false

        for (const item of times[step]) {
          if (item) {
            for (const direction of item.directions) {
              if (otherDirections.indexOf(direction) < 0 && !otherDirections.includes(direction)) {
                otherDirections.push(direction)
              }
            }
          }
        }
      }
    }

    setBody(
      isEmpty
        ? []
        : Object.keys(times)
            .filter(step => hours[step].length > 0)
            .map(step => <Slide key={step} data={times[step]} otherDirections={otherDirections} step={step} />),
    )

    // eslint-disable-next-line
  }, [data])

  useLayoutEffect(() => {
    const timetable = document.querySelector('.lc-timetable')
    const arrow = timetable?.querySelector('.slick-arrow')

    if (arrow) {
      timetable.classList.add('lc-timetable-with-margin')
    }
  }, [body])

  return body.length > 0 ? (
    <>
      {!print && <Slider {...settings}>{body}</Slider>}
      {print && <div className="lc-timetable print">{body}</div>}
      {print && translate('notes-timetable', false) !== undefined && !configApp.new_print_design && (
        <div className="lc-notes-timetable" data-lc-notes-timetable>
          <img src={assetsPath('/assets/images/disruptions/notice.svg')} alt={translate('notice')} />
          <div
            dangerouslySetInnerHTML={{
              __html: translate('notes-timetable'),
            }}
          />
        </div>
      )}
    </>
  ) : (
    <div className="lc-no-schedules">{translate('stop-timetable-no-schedules') + ' ' + timetableStop}</div>
  )
}

/**
 * Retrieve all transport information around a position
 * @param component
 * @param position
 * @area stop_area id
 */
export const around = async (component, position, forceNotFitBounds, area = null) => {
  const { map, openedMarker, touchscreenSelected, linesModes, currentRadius, searchIn, openedCollapse } =
    component.props

  const { pathname } = history.location
  const params = getURLSearchParams(history.location)
  let refCircle

  try {
    appStore.dispatch(actionSetAroundPin({}))
    const aroundParams = {
      lat: position[0],
      lon: position[1],
      radius: currentRadius,
      area: area,
    }

    if (searchIn) {
      aroundParams['type'] = searchIn
    }

    const response = await axios({
      url: '/api/around',
      params: aroundParams,
    })

    const hidePin = params.from.startsWith('stop_') || params.from.startsWith('poi:')
    let pinIcon = {
      iconUrl: assetsPath('/assets/images/pin.svg'),
      iconSize: hidePin ? [0, 0] : [50, 50],
      iconAnchor: hidePin ? [0, 0] : [25, 25],
    }
    const testBorne = touchscreenSelected ? [...position].reverse().join(';') : null

    if (testBorne && touchscreenSelected.coords === testBorne) {
      pinIcon = {
        iconUrl: assetsPath('/assets/images/you_are_here.svg'),
        iconSize: [70, 70],
        iconAnchor: [35, 70],
      }
    }

    // Pin marker
    const pin = (
      <Marker
        draggable
        onDragstart={component.removePinCircle}
        onDragend={event => {
          history.push({
            pathname,
            search: '?from=' + substringCoords(event.target.getLatLng()),
          })
        }}
        options={{ zIndex: 999 }}
        icon={L.icon(pinIcon)}
        position={position}
      />
    )

    const walkRadiusPinIcon = {
      iconUrl: assetsPath('/assets/images/around/walk-radius-' + currentRadius + '.svg'),
      iconSize: [70, 20],
      iconAnchor: [35, 10],
    }

    // ADD by Jérémy 7/02/2023 : add around pin walk marker and transform circle from <Circle> to Array
    const circle = [
      <Circle
        key="circle-around-radius"
        center={position}
        ref={r => (refCircle = r)}
        radius={currentRadius}
        fillColor="rgba(0, 0, 0)"
        fillOpacity="0.10"
        color="transparent"
      />,
      <Marker
        key="around-pin-walk-top"
        options={{ zIndex: 999 }}
        icon={L.icon(walkRadiusPinIcon)}
        position={[addMetersToLatitude(currentRadius, position[0]), position[1]]}
      />,
      <Marker
        key="around-pin-walk-bottom"
        options={{ zIndex: 999 }}
        icon={L.icon(walkRadiusPinIcon)}
        position={[addMetersToLatitude(-currentRadius, position[0]), position[1]]}
      />,
    ]

    // Retrieve lines
    const lines = response.data.shift()
    // Retrieve transport pois
    const pois = lines.pop()
    const groups = groupLinesByMode(unique(lines, 'id'), linesModes, 'mode') // Make the array unique by the lines ID and order them by group
    const dataPlaces = response.data.shift()

    const places = Object.keys(dataPlaces).reduce(function (item, key) {
      item[key] = dataPlaces[key]
      return item
    }, {})

    // Google Tag Manager
    // TODO Optimize
    if (Object.keys(params).length === 1) {
      const type = params.from.includes('stop_area:')
        ? 'Arrêt'
        : params.from.includes('poi:company')
        ? 'Entreprise'
        : params.from.includes('poi:')
        ? 'Lieu'
        : 'Adresse'

      // TODO Optimize
      if (component.state.latestMapSearchEvent !== component.props.inputValue) {
        // Google Tag Manager
        updateDataLayer({
          event: 'map-searchMap',
          searchType: type,
          searchTerm: component.props.inputValue,
        })
        component.setState({ latestMapSearchEvent: component.props.inputValue })
      }
    }

    // Retrieve the most important group displayed to select it if it's not already done
    // Choose witch tab to be onpened
    // Get selected poi or place or stop
    const group = mostImportantGroup(groups, linesModes)
    let selected = null
    let tab = 0

    if (params.from.startsWith('poi:')) {
      if (Object.keys(places).length) {
        for (const type of Object.keys(places)) {
          const place = places[type].find(p => p.id === params.from)

          if (place) {
            selected = place
            tab = 1
            break
          }
        }
      }

      // We have some pois put in transport tab
      if (!selected && Object.keys(pois).length) {
        for (const type of Object.keys(pois)) {
          for (const cat of Object.keys(pois[type])) {
            const poi = pois[type][cat].find(p => p.id === params.from)

            if (poi) {
              selected = poi
              break
            }
          }
        }
      }

      // dans le cas d'un poi ajouter via la /api/get-places qui n'est pas dans le state
      if (!selected) {
        const poiFind = appStore.getState().app.places.find(p => p.id === params.from)

        if (poiFind) {
          const placesRefName = component.props.placesRef.find(pr => pr.places.find(p => p === poiFind.cat_id))

          if (placesRefName?.name) {
            if (component.props.aroundTabs.places.includes(placesRefName.name)) {
              tab = 1
              if (!places[poiFind.cat_id]) {
                places[poiFind.cat_id] = []
              }
              places[poiFind.cat_id].push(poiFind)
            } else {
              if (!pois[placesRefName.name]) {
                pois[placesRefName.name] = {}
                pois[placesRefName.name][poiFind.cat_id] = [poiFind]
              } else {
                pois[placesRefName.name][poiFind.cat_id].push(poiFind)
              }
            }
          }

          selected = poiFind
        }
      }
    } else if (params.from.startsWith('stop_')) {
      if (params.from.startsWith('stop_area:')) {
        selected = component.props.areas.find(m => m.id === params.from)
      } else if (params.from.startsWith('stop_point:')) {
        selected = component.props.stops.find(m => m.id === params.from)
      }
    }

    component.setState(
      {
        groups,
        pois,
        places,
        tab,
      },
      () => {
        openedCollapse !== group && appStore.dispatch(actionSetOpenedCollapse(group))

        if (selected) {
          setTimeout(() => {
            appStore.dispatch(actionOpenMarker(selected))
          })
        }

        const markers = map.state.markers

        const state = {
          pin: isActiveModule('around') ? pin : null,
          circle,
          markers: [...markers],
          selectedInfobox: null,
          infoboxs: [],
        }

        map.setState({ ...state }, async () => {
          if (refCircle && (!openedMarker || !params.line) && !params.stop && !forceNotFitBounds) {
            fitBounds(map, null, -1, refCircle.leafletElement.getBounds())
          }

          resize(map.props.isMobile)

          // Scroll to the top of the content if we are on mobile
          if (map.props.isMobile) {
            setTimeout(() => {
              const element = document.querySelector('[data-lc-board]')

              element && (element.parentNode.scrollTop = element.offsetTop - element.parentNode.offsetTop)
            }, 250)
          }
        })
      },
    )
  } catch (e) {
    console.warn('Error : ', e.response && e.response.data && e.response.data.id, e)
    component.setState({
      error:
        e.response && e.response.data.id === 'no-places'
          ? translate('around-error-no-places')
          : translate('around-error-unknow'),
    })
  }
}

export const displayLineDecorator = (
  polyline,
  section,
  modes,
  repeatPolylineDecorator,
  displayBikeInsteedOfBssOnMap,
  displayCustomPolylineIcon,
  forceLinesPictosOverMode,
  walkingSpeed,
) => {
  const lineLink = section.links.find(link => link.type === 'line')
  let lineStyle = REACT_APP_LINES_MAIN_TYPE ? REACT_APP_LINES_MAIN_TYPE : 'color'
  let prefixNetwork = false

  if (REACT_APP_LINES_TYPE_EXCEPTIONS) {
    const exceptions = JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS)

    let foundExceptedLine
    if (lineLink) {
      foundExceptedLine = exceptions.find(e => e.lines?.includes(lineLink.id) || e.networks?.includes(lineLink.network))
    }
    if (section.display_informations) {
      foundExceptedLine = exceptions.find(e => e.networks?.includes(section.display_informations.network))
    }

    if (foundExceptedLine) {
      lineStyle = foundExceptedLine.type
      prefixNetwork = foundExceptedLine.prefixNetwork === true
    }
  }

  return (
    <PolylineDecorator
      key={section.id + Math.random() + '_decorator'}
      modes={modes}
      section={section}
      path={polyline.props.positions}
      lineStyle={lineStyle}
      prefixNetwork={prefixNetwork}
      displayBikeInsteedOfBssOnMap={displayBikeInsteedOfBssOnMap}
      repeatPolylineDecorator={repeatPolylineDecorator}
      displayCustomPolylineIcon={displayCustomPolylineIcon}
      forceLinesPictosOverMode={forceLinesPictosOverMode}
      walkingSpeed={walkingSpeed}
    />
  )
}

/**
 * Display polylines for a line
 * @param line
 * @param map
 * @param args
 */
export const displayLinePath = async (line, map, ...args) => {
  const { pathname, search } = history.location
  const params = getURLSearchParams(history.location)
  const [isSection, main, index, component, journey] = args
  const { moduleData } = component?.props || {}
  const infos = isSection ? line.display_informations : null

  // TODO waiting sections
  if (isSection && line.type === 'waiting') {
    return
  }

  try {
    if (!isSection) {
      await axios
        .get(`/api/file?folder=routes&ext=geojson&name=${line.code}_${line.network}_${line.direction_id}`)
        .then(result => {
          line.geojson = result.data
        })
        .catch(e => {
          const error = e.response && e.response.data ? e.response.data.id : e

          console.warn(error)
          line.geojson = null
        })
    }

    // Save others polylines not from the line selected
    let oldPolylines = pathname.includes('lines')
      ? map.state.polylines.filter(oldPolyline => line.code !== oldPolyline.key.split('_').shift())
      : []
    const newPolylines = []

    const lineOptions = {
      color: '#' + (infos ? (infos.color.length ? infos.color : '000') : line.color),
      opacity: 1,
      weight: 6,
      zIndex: 7,
      main,
    }

    if (line.geovelo && line.path) {
      let polyline

      if (main) {
        newPolylines.push(
          <Polyline
            key={'clone_' + line.id}
            positions={getCoordinates(line.geojson).pop()}
            color={'white'}
            weight={11}
            zIndex={0}
          />,
        )
      }

      // We loop on each instruction to know wich geometry index use until next instruction to trace it with the right color based on facility type
      line.path.forEach((instruction, i) => {
        let path = getCoordinates(line.geojson).pop()
        const isLast = i === line.path.length - 1

        if (!isLast) {
          // We trace from the instruction to the geometryIndex from the next one
          path = path.slice(instruction.geometryIndex, line.path[i + 1].geometryIndex + 1)
        } else {
          // For the last instruction we trace from the instruction geometryIndex to the end of path
          path = path.slice(instruction.geometryIndex)
        }

        if (main) {
          // We put color on facilityType only if trace is selected
          lineOptions.color =
            instruction.facilityType === 'recommendedRoads'
              ? recommendedRoadsColor
              : instruction.facilityType === 'normalRoads'
              ? normalRoadsColor
              : discouragedRoadsColor
        } else {
          // if not selected it'll use secondaryjourney
          lineOptions.color = secondaryjourney
          lineOptions.opacity = 0.6
          lineOptions.zIndex = 5
          lineOptions.weight = 4
        }

        polyline = (
          <Polyline
            ref={poly => !main && poly && poly.leafletElement.bringToBack()}
            key={line.id + (infos ? '_' + infos.code : '') + Math.random()}
            positions={path}
            {...lineOptions}
            onMouseOver={() => {
              if (component.state.interactiveMap && !params.journey && component.state.indexDisplayLinePath !== index) {
                component.displayJourneys(
                  journey?.tab && component.state.tabs[journey.tab]
                    ? component.state.tabs[journey.tab]
                    : component.state.journeys,
                  map,
                  index,
                  true,
                )
              }
            }}
            onClick={() => {
              !search.includes('journey') &&
                component.state.interactiveMap &&
                history.push({
                  pathname,
                  search: search + '&journey=' + index,
                })
            }}
          />
        )

        newPolylines.push(polyline)
      })
    } else {
      // Street network or transfer, black dashed
      if (isSection && (line.type === 'street_network' || line.type === 'transfer' || line.type === 'crow_fly')) {
        lineOptions.color = '#333'
        lineOptions.opacity = 1
        lineOptions.weight = 4
        lineOptions.dashArray = '5 12'
      }

      if (line.mode === 'ridesharing') {
        lineOptions.dashArray = '0 0'
      }

      if (moduleData?.overrideLineOptionsMode) {
        const overrideModes = moduleData.overrideLineOptionsMode

        for (const mode of Object.keys(overrideModes)) {
          let sectionMode = line.mode

          if (
            line.mode === 'bike' &&
            ((line.from.poi && line.from.poi.poi_type.id.includes('bicycle_rental')) ||
              (line.to.poi && line.to.poi.poi_type.id.includes('bicycle_rental'))) &&
            line.mode_display !== 'personal_bike'
          ) {
            sectionMode = 'bss'
          }

          if (line.mode_display === 'personal_bike') {
            sectionMode = 'bike'
          }

          if (isSection && sectionMode === mode) {
            const overrideMode = overrideModes[mode]

            for (const style of Object.keys(overrideMode)) {
              lineOptions[style] = overrideMode[style]
            }
          }
        }
      }

      if (isSection && !main) {
        lineOptions.color = secondaryjourney
        lineOptions.opacity = 0.6
        lineOptions.zIndex = 5
        lineOptions.weight = 4
      }

      let polyline

      if (line.geojson) {
        if (!isSection) {
          let index = 0

          for (const feature of line.geojson.features) {
            let zoom = feature.properties.zoom
            const length = feature.geometry.coordinates.length
            const path = length === 1 ? getCoordinates(feature).pop() : getCoordinates(feature)

            // Push line
            polyline = (
              <Polyline
                key={(isSection ? line.id + '_aaa' : line.code) + (infos ? '_' + infos.code : '') + '_' + index}
                positions={path}
                zoom={zoom}
                {...lineOptions}
              />
            )
            newPolylines.push(polyline)

            index++
          }
        } else {
          const paths = getCoordinates(line.geojson)

          paths.forEach(path => {
            // Push line
            polyline = (
              <Polyline
                ref={poly => !main && poly && poly.leafletElement.bringToBack()}
                key={line.id + (infos ? '_' + infos.code : '') + Math.random()}
                positions={path}
                {...lineOptions}
                onMouseOver={() => {
                  if (
                    component.state.interactiveMap &&
                    !params.journey &&
                    component.state.indexDisplayLinePath !== index
                  ) {
                    component.displayJourneys(
                      journey?.tab && component.state.tabs[journey.tab]
                        ? component.state.tabs[journey.tab]
                        : component.state.journeys,
                      map,
                      index,
                      true,
                    )
                  }
                }}
                onClick={() => {
                  !search.includes('journey') &&
                    component.state.interactiveMap &&
                    history.push({
                      pathname,
                      search: search + '&journey=' + index,
                    })
                }}
              />
            )
            newPolylines.push(polyline)
          })
        }
      }
    }

    if (isSection) {
      return newPolylines
    }

    // Concat selected lines with old lines
    oldPolylines = oldPolylines.concat(newPolylines)

    return oldPolylines
  } catch (error) {
    console.log(error)
    throw error
  }
}

/**
 * Display places in toDisplay array in base map
 * @param component
 * @param toDisplay
 */
export const displayBaseMapPlaces = (component, toDisplay) => {
  const { map } = component.props
  const markers = []

  axios
    .get('/api/file?name=places')
    .then(result => {
      for (const place of result.data) {
        if (toDisplay.includes(place.cat_id.split('poi_type:')[0])) {
          const code = place.code.split('_')[0]

          const marker = {
            id: place.id,
            coord: place.coord,
            name: place.name,
            code,
          }

          const markerRendered = renderMarker(component, marker, {
            icon: L.icon({
              iconUrl: assetsPath('/assets/images/places/') + code + '.svg',
              iconSize: [50, 50],
              iconAnchor: [25, 18],
            }),
            marker,
            zIndex: 30,
          })

          markers.push(markerRendered)
        }
      }

      map.setState({ markersMap: markers })
    })
    .catch(e => {
      const error = e.response && e.response.data ? e.response.data.id : e

      console.warn(error)
    })
}

export const getFitbounds = (objects, bounds = L.latLngBounds()) => {
  if (objects) {
    for (const object of objects) {
      if (object === undefined) {
        continue
      }

      if (!object.props) {
        if (object.coord) {
          bounds.extend(object.coord)
        } else if (object.geometry) {
          if (object.geometry.type === 'LineString') {
            object.geometry.coordinates.map(c => bounds.extend([c[1], c[0]]))
          } else if (object.geometry.type === 'MultiLineString') {
            for (const coords of object.geometry.coordinates) {
              bounds.extend(coords.map(c => [c[1], c[0]]))
            }
          } else if (object.geometry.type === 'MultiPolygon') {
            for (const coords of object.geometry.coordinates) {
              for (const coords2 of coords) {
                bounds.extend(coords2.map(c => [c[1], c[0]]))
              }
            }
          }
        } else {
          bounds.extend(object)
        }
      } else if (object.props.data) {
        // GeoJSON
        // ! TODO need to be tested on all case posssible
        if (object.props.data.features.length > 1) {
          for (const feature of object.props.data.features) {
            if (feature.geometry.type === 'LineString') {
              feature.geometry.coordinates.map(c => bounds.extend([c[1], c[0]]))
            } else if (feature.geometry.type === 'MultiLineString') {
              for (const coords of feature.geometry.coordinates) {
                bounds.extend(coords.map(c => [c[1], c[0]]))
              }
            } else if (feature.geometry.type === 'GeometryCollection') {
              for (const geometry of feature.geometry.geometries) {
                if (geometry.type === 'LineString') {
                  geometry.coordinates.map(c => bounds.extend([c[1], c[0]]))
                }
              }
            }
          }
        } else {
          const trace = object.props.data.features[0].geometry

          if (trace.type === 'LineString') {
            trace.coordinates.map(c => bounds.extend([c[1], c[0]]))
          } else if (trace.type === 'MultiLineString') {
            for (const coords of trace.coordinates) {
              bounds.extend(coords.map(c => [c[1], c[0]]))
            }
          }
        }
      } else if (object.props.positions) {
        // Polylines or Polygon
        bounds.extend(object.props.positions)
      } else if (object.props.position) {
        // Marker
        bounds.extend(object.props.position)
      } else if (object.props.center) {
        // Circle
        bounds.extend(object.props.center)
      } else {
        console.warn('You shall not pass')
      }
    }
  }

  return bounds
}

/**
 * Fit the bound of map with given objects (Polylines or Markers)
 * @param map
 * @param objects
 * @param pad // extra padding on each side
 * @param bounds
 */
export const fitBounds = (map, objects, pad = 0, bounds = L.latLngBounds()) => {
  if (objects) {
    for (const object of objects) {
      if (object === undefined) {
        continue
      }

      if (!object.props) {
        if (object.coord) {
          bounds.extend(object.coord)
        } else {
          bounds.extend(object)
        }
      } else if (object.props.data) {
        // GeoJSON
        // ! TODO need to be tested on all case posssible
        if (object.props.data.features.length > 1) {
          for (const feature of object.props.data.features) {
            if (feature.geometry.type === 'LineString') {
              feature.geometry.coordinates.map(c => bounds.extend([c[1], c[0]]))
            } else if (feature.geometry.type === 'MultiLineString') {
              for (const coords of feature.geometry.coordinates) {
                bounds.extend(coords.map(c => [c[1], c[0]]))
              }
            } else if (feature.geometry.type === 'GeometryCollection') {
              for (const geometry of feature.geometry.geometries) {
                if (geometry.type === 'LineString') {
                  geometry.coordinates.map(c => bounds.extend([c[1], c[0]]))
                } else if (geometry.type === 'MultiLineString') {
                  for (const coords of geometry.coordinates) {
                    bounds.extend(coords.map(c => [c[1], c[0]]))
                  }
                }
              }
            } else if (feature.geometry.type === 'Polygon') {
              for (const coords of feature.geometry.coordinates) {
                bounds.extend(coords.map(c => [c[1], c[0]]))
              }
            }
          }
        } else {
          const trace = object.props.data.features[0].geometry

          if (trace.type === 'LineString') {
            trace.coordinates.map(c => bounds.extend([c[1], c[0]]))
          } else if (trace.type === 'MultiLineString') {
            for (const coords of trace.coordinates) {
              bounds.extend(coords.map(c => [c[1], c[0]]))
            }
          } else if (trace.type === 'GeometryCollection') {
            for (const geometry of trace.geometries) {
              if (geometry.type === 'LineString') {
                geometry.coordinates.map(c => bounds.extend([c[1], c[0]]))
              } else if (geometry.type === 'MultiLineString') {
                for (const coords of geometry.coordinates) {
                  bounds.extend(coords.map(c => [c[1], c[0]]))
                }
              }
            }
          } else if (trace.type === 'Polygon') {
            for (const coords of trace.coordinates) {
              bounds.extend(coords.map(c => [c[1], c[0]]))
            }
          }
        }
      } else if (object.props.positions) {
        // Polylines or Polygon
        bounds.extend(object.props.positions)
      } else if (object.props.position) {
        // Marker
        bounds.extend(object.props.position)
      } else if (object.props.center) {
        // Circle
        bounds.extend(object.props.center)
      } else {
        console.warn('You shall not pass')
      }
    }
  }

  setTimeout(() => {
    // Detect if we have no board, if no, no left offset
    const noBoard = document.querySelector('.lc-no-board')
    // ! TODO Change for not large
    let topLeft = L.point(noBoard ? 0 : 460, pad < 0 ? 0 : 50)
    let bottomRight = L.point(0, pad < 0 ? 0 : 50)

    if (map.props.isMobile && !envVarToBool(REACT_APP_ALL_POPUP_ON_TOP)) {
      const popup = document.querySelector('.leaflet-popup')

      topLeft = L.point(popup ? -popup.offsetWidth : 0, popup ? -popup.offsetHeight / 2 : 0)
      //bottomRight = L.point(0, document.querySelector(appStore.getState().app.domElement).scrollTop);
    } else if (map.props.isMobile && envVarToBool(REACT_APP_ALL_POPUP_ON_TOP)) {
      topLeft = L.point(0, 0)
      bottomRight = L.point(0, 0)
    }

    if (pad > 0) {
      bounds.extend(bounds.pad(pad))
    }

    // Fit the map component with the calculated bounds
    if (bounds.isValid()) {
      map.setState({
        bounds,
        boundsOptions: {
          paddingTopLeft: topLeft,
          paddingBottomRight: bottomRight,
          animate: false,
        },
      })
    }
  })
}

/**
 *
 * @param feature
 * @returns {Array}
 */
export const getCoordinates = feature => {
  const paths = []
  let type = feature.type

  if (!type || type.includes('Feature')) {
    feature = feature.geometry
    type = feature.type
  }

  switch (type) {
    case 'LineString': {
      const linePath = []

      for (const coord of feature.coordinates) {
        linePath.push([coord[1], coord[0]])
      }

      paths.push(linePath)
      break
    }
    case 'MultiLineString':
      for (const line of feature.coordinates) {
        const linePath = []

        for (const coord of line) {
          linePath.push([coord[1], coord[0]])
        }

        paths.push(linePath)
      }

      break

    default:
      console.warn('Geometry type not found')
  }

  return paths
}

/**
 * Retrieve a line object from it's id
 * @returns Object
 * @param component
 * @param object
 */
export const getLine = (component, object) => {
  const { lines } = component.props

  return {
    ...lines.find(line => line.id === object.id),
    ...object,
  }
}

export const goRouteCalculation = (component, object) => {
  const { map } = component.props

  history.push(`/route-calculation?to=${object}`)
  map.setState({
    markers: [],
    markersPlaces: [],
    infoboxs: [],
  })
}

/**
 * Retrieve a stop object from it's id
 * @returns Object
 * @param component
 * @param object
 * @param line
 */
export const getStop = (component, object, line) => {
  const { stops } = component.props
  let res = object.id

  if (object.id.includes('stop_area')) {
    res = stops.find(
      s =>
        s.stop_area === object.id.replace('-pole-exchange', '') &&
        s.lines.find(l => l.id === line.id && l.direction_id === line.direction_id),
    ).id
  }

  // TODO if id is switch with stop_point instead of stop_area, look at here :D
  return {
    ...object,
    ...stops.find(s => s.id === res),
  }
}

/**
 * Invert coord
 * @param array
 * @returns {*}
 */
export const invertCoords = array => {
  return array.geometry.coordinates[0].map(coord => [coord[1], coord[0]])
}

/**
 * Remove the current line from a given component
 * @param component
 * @param line
 * @param lineList
 */
export const removeLine = (component, line = null, lineList = false) => {
  const { map } = component.props
  const { pathname, search } = history.location
  const params = getURLSearchParams(history.location)

  map.setState({
    terminus: false,
    infoboxsTerminus: [],
    polylineDecorators: [],
  })

  if (!line) {
    map.setState({
      selectedInfobox: null,
      infoboxs: [],
      polylines: [],
    })

    component.setState({
      currentLine: null,
    })
  } else {
    if (pathname !== '/lines') {
      history.push({
        pathname,
        search: search.replace('&line=' + params.line, ''),
      })
    } else {
      const { currentLine, selectedLines } = component.state

      if (params.current === line.id + '_' + line.direction_id) {
        if (params.selected) {
          const newSelectedLines = selectedLines.filter(selectLine => selectLine.id !== line.id)
          const newCurrent = newSelectedLines[0]
          const current = newCurrent.id + '_' + newCurrent.direction_id
          let selection = ''

          for (const stateLine of newSelectedLines) {
            if (stateLine.id !== newCurrent.id || lineList) {
              selection += stateLine.id + '_' + stateLine.direction_id + ','
            }
          }

          if (lineList) {
            history.push({ pathname, search: '?selected=' + selection })
          } else {
            history.push({
              pathname,
              search: '?current=' + current + '&selected=' + selection,
            })
          }
        } else {
          history.push({ pathname })
        }
      } else {
        let current = null

        if (currentLine) {
          current = currentLine.id + '_' + currentLine.direction_id
        }

        let selection = current ? '&selected=' : '?selected='
        const linesSelectedToKeep = selectedLines.filter(selectLine => selectLine.id !== line.id)

        if ((linesSelectedToKeep.length === 1 && current) || linesSelectedToKeep.length === 0) {
          selection = ''
        } else {
          for (const lineToKeep of linesSelectedToKeep) {
            if (current) {
              if (lineToKeep.id !== currentLine.id) {
                selection += lineToKeep.id + '_' + lineToKeep.direction_id + ','
              }
            } else {
              selection += lineToKeep.id + '_' + lineToKeep.direction_id + ','
            }
          }
        }

        if (!lineList && current) {
          history.push({ pathname, search: '?current=' + current + selection })
        } else {
          history.push({ pathname, search: selection })
        }
      }
    }
  }
}

/**
 * Remove map state event
 * @param map
 */
export const removeMapEvents = map => {
  if (!map) {
    return
  }

  map.setState({ events: {} })
}

/**
 * Render an infobox on a specific marker
 * If onLineSelected, the infobox will stay open onClick
 * @param component   used in board
 * @param stop
 * @param selected
 */
// TODO bug fitbounds
export const renderInfobox = (component, stop, selected) => {
  if (!stop.id || (stop.id && !stop.id.startsWith('stop_'))) {
    return
  }

  // Get lines by stop_area
  const area = stop.id.includes('stop_area') ? stop : getStop(component, { id: stop.id })
  const { map } = component?.props || {}
  const { isMobile, configApp } = map?.props || {}

  if (!area.lines) {
    // case area with id containing only coords o_O
    return
  }

  area.lines = area?.lines.filter(
    (a, i, self) => i === self.findIndex(t => t.id.replace('_R', '') === a.id.replace('_R', '')),
  )

  // Display tooltip
  if (selected) {
    stop.ref && (stop.ref.clicked = true)

    /* if (component.state.markerRef && component.state.markerRef !== stop.ref) {
      component.state.markerRef.leafletElement.setTooltipContent(component.state.selectedMarker.name)
    } */

    component.setState({
      selectedMarker: stop,
    })
  }

  return (
    <div className="lc-infobox">
      <div className="lc-infobox-title">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          {stop.name}
        </div>
        <>
          <UIStopPmr pmr={stop.pmr} />
          {REACT_APP_SHOW_ADDITIONAL_STOP_TOOL &&
            JSON.parse(REACT_APP_SHOW_ADDITIONAL_STOP_TOOL).map(tool => {
              if (stop[tool]) {
                return <div key={`${stop.id}_${tool}`} className={`lc-is-${tool}`} />
              } else {
                return false
              }
            })}
          <div className="lc-infobox-title-tools">
            {!isMobile && configApp?.streetview && (
              <Tippy
                theme={'latitude'}
                touch={['hold', 500]}
                placement={'right'}
                boundary="window"
                content={translate('title-streetview-link')}>
                <div
                  className="lc-tool-streetview-link lc-toolSmall"
                  onClick={() => {
                    const popup = document.querySelector('.leaflet-popup')
                    const initialHeight = popup.offsetHeight
                    const displayStreetview = document.querySelector('.stop-infobox-streetview')

                    document
                      .querySelector('.stop-infobox-streetview iframe')
                      .setAttribute(
                        'src',
                        document.querySelector('.stop-infobox-streetview iframe').getAttribute('data-src'),
                      )

                    document.querySelector('.stop-infobox-streetview').style.display =
                      displayStreetview?.style?.display === 'block' ? 'none' : 'block'

                    document.querySelectorAll('.lc-infobox-lines').forEach(elem => {
                      if (displayStreetview?.style?.display === 'block') {
                        elem.classList.add('lc-active-streetview')
                      } else {
                        elem.classList.remove('lc-active-streetview')
                      }
                    })

                    const heightDiff = popup.offsetHeight - initialHeight
                    const transform = popup.style.transform.split(',')
                    const y = Number(transform[1].replace(/px$/, ''))

                    popup.style.transform = `${transform[0]}, ${y + heightDiff}px, ${transform[2]}`
                  }}
                  onKeyUp={e =>
                    handleKeyUp(e, () => {
                      const popup = document.querySelector('.leaflet-popup')
                      const initialHeight = popup.offsetHeight
                      const displayStreetview = document.querySelector('.stop-infobox-streetview')

                      document
                        .querySelector('.stop-infobox-streetview iframe')
                        .setAttribute(
                          'src',
                          document.querySelector('.stop-infobox-streetview iframe').getAttribute('data-src'),
                        )

                      document.querySelector('.stop-infobox-streetview').style.display =
                        displayStreetview?.style?.display === 'block' ? 'none' : 'block'

                      document.querySelectorAll('.lc-infobox-lines').forEach(elem => {
                        if (displayStreetview?.style?.display === 'block') {
                          elem.classList.add('lc-active-streetview')
                        } else {
                          elem.classList.remove('lc-active-streetview')
                        }
                      })

                      const heightDiff = popup.offsetHeight - initialHeight
                      const transform = popup.style.transform.split(',')
                      const y = Number(transform[1].replace(/px$/, ''))

                      popup.style.transform = `${transform[0]}, ${y + heightDiff}px, ${transform[2]}`
                    })
                  }
                  tabIndex="0"
                  role="button"
                  aria-label={translate('title-streetview-link')}
                />
              </Tippy>
            )}
            {selected && !stop.id.includes('TAD') && (
              <Tippy
                theme={'latitude'}
                touch={['hold', 500]}
                placement={'right'}
                boundary="window"
                content={translate('title-go-to-route-calculation')}>
                <span
                  className="lc-is-tool-route-calculation lc-is-toolSmall"
                  onClick={e => {
                    e.stopPropagation()
                    const coord = stop.coord.lon + ';' + stop.coord.lat

                    stop.id.includes('stop')
                      ? goRouteCalculation(component, stop.id)
                      : goRouteCalculation(component, coord)
                  }}
                  onKeyUp={e =>
                    handleKeyUp(e, () => {
                      const coord = stop.coord.lon + ';' + stop.coord.lat

                      stop.id.includes('stop')
                        ? goRouteCalculation(component, stop.id)
                        : goRouteCalculation(component, coord)
                    })
                  }
                  role="button"
                  tabIndex="0"
                  aria-label={translate('title-go-to-route-calculation', false)}>
                  <img
                    src={assetsPath('/assets/images/menu/route-calculation.svg')}
                    alt={translate('route-calculation-board-title')}
                    aria-hidden="true"
                  />
                </span>
              </Tippy>
            )}
          </div>
        </>
      </div>
      {!isMobile && configApp?.streetview && (
        <div className="lc-streetview stop-infobox-streetview">
          <iframe
            title="streetview"
            style={{ border: 0 }}
            loading="lazy"
            height="350px"
            width="500px"
            data-src={`https://www.google.com/maps/embed/v1/streetview?key=${configApp?.streetview}&location=${stop.coord.lat},${stop.coord.lon}`}></iframe>
        </div>
      )}
      {area && (
        <div className="lc-infobox-content">
          {stop.stand ? (
            <div className="lc-place">
              <div>{stop.address.label}</div>
              {stop.data && stop.data.stand && (
                <div className="lc-bss">
                  <span className="lc-bikes">
                    {stop.stand.available_bikes}
                    <img src={assetsPath('/assets/images/modes/bss.svg')} alt={translate('bss-bikes-available')} />
                  </span>
                  <span className="lc-seats">
                    {stop.stand.available_places}
                    <img src={assetsPath('/assets/images/bss-seat.svg')} alt={translate('available-places')} />
                  </span>
                </div>
              )}
            </div>
          ) : stop.poi_type ? (
            <div className="lc-place">
              <div>{stop.address.label}</div>
            </div>
          ) : (
            renderLinesLabels(component, area.lines, 'infobox', stop)
          )}
        </div>
      )}
    </div>
  )
}

/**
 * Render a line on a given coponent
 * @param component
 * @param line
 */
export const renderLine = (component, line, print = false, printData) => {
  !line && (line = component.state.currentLine)
  const { pathname, search } = history.location
  const params = getURLSearchParams(history.location)
  const { stopsList, timetable, loadingTimetable } = component.state

  const {
    options,
    terminusStyle,
    token,
    clientId,
    favoriteLine,
    favoriteStop,
    dontShow,
    displayError,
    disruptionsInLine,
    map,
    language,
    touchscreenSelected,
    linesModes,
    openedCollapse,
    reduxMarkers,
    configApp,
  } = component.props

  const lineMode = linesModes.find(mode => mode.modes.includes(line.mode))
  const noThermo = lineMode?.noThermo === true || (line.tad && !line.tad.thermo) || false
  const { isMobile } = map.props
  const showSchedules = options?.features?.['schedules'] === false ? false : true
  let showTimetable = options?.features?.['timetable-button'] === false ? false : true

  const moduleDataDontShowTimetable = component.props.moduleData
    ? component.props.moduleData.dontShowTimetable
    : component.props.dontShowTimetable

  const moduleDataDisplayScheduleDestination = component.props.moduleData
    ? component.props.moduleData.displayScheduleDestination
    : component.props.displayScheduleDestination

  const notScheduleDestinationIfOneDirection = component.props.moduleData
    ? component.props.moduleData.notScheduleDestinationIfOneDirection
    : component.props.notScheduleDestinationIfOneDirection

  const noScheduleTodayLabel = configApp?.no_schedule_today_label === true

  const timetableRescrictDate = component.props.moduleData
    ? component.props.moduleData.timetableRescrictDate
    : component.props.timetableRescrictDate

  const timetableButtonNextToStopName = configApp?.timetable_button_nexto_stopname === true
  const timetablePrintButtonNextToDate = configApp?.timetable_print_button_nextto_date === true

  let maxDate = false
  let minDate = false

  if (component.props.moduleData?.maxDate || component.props.maxDate) {
    maxDate = new Date(component.props.moduleData?.maxDate || component.props.maxDate)
  }

  if (component.props.moduleData?.minDate || component.props.minDate) {
    minDate = new Date(component.props.moduleData?.minDate || component.props.minDate)
  }

  if (timetableRescrictDate && timetableRescrictDate > 0) {
    maxDate = new Date()

    maxDate.setDate(maxDate.getDate() + timetableRescrictDate)
  }

  // Set if the line is tad or not
  line.isTad = isDefinedAsTAD(linesModes, line)

  if (noThermo || line.cat === 'TAD-markers-only') {
    sortBy(stopsList, 'name')
  }

  const pickerStyle = {
    calendar: {
      top: map && !map.props.isMobile ? '30px' : '',
      bottom: map && map.props.isMobile ? '28%' : '',
      left: map && map.props.isMobile ? '0' : '',
      right: map && map.props.isMobile ? '0' : '',
      boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.15)',
    },
    colon: {
      padding: '0 5px 0 0 !important',
    },
    control: {
      boxShadow: 'none',
      cursor: 'pointer',
    },
    first: '#005e86',
    menu: {
      marginLeft: -5,
      position: 'fixed',
      bottom: map && map.props.isMobile ? '25%' : '',
      top: '',
    },
    weekDays: {
      padding: '5px 0',
    },
    monthSelected: {
      fontWeight: 600,
    },
    calendarButtonStyle: {
      fontSize: '.875em',
    },
    inputsHours: {
      fontSize: '1em',
    },
    today: {
      background: '#f4f4f4',
      color: '#333',
      fontWeight: '500',
    },
  }

  pickerStyle.first = primarycolor
  pickerStyle.calendarButtonStyle = {
    ...pickerStyle.calendarButtonStyle,
    margin: 10,
    border: '2px solid rgba(0, 0, 0, 0.08)',
    borderRadius: 5,
    marginRight: 10,
    padding: 8,
  }
  pickerStyle.calendar = {
    ...pickerStyle.calendar,
    padding: 15,
    background: '#f1f5f5',
  }
  pickerStyle.week = {
    ...pickerStyle.week,
    background: '#fff',
  }

  // Color terminus tooltip
  // TODO find a better way :-)
  document.querySelectorAll('.lc-tooltip-leaflet-terminus').forEach(div => {
    div.style.borderColor = '#' + line.color

    if (!terminusStyle) {
      div.style.backgroundColor = '#' + line.color
      div.style.color = luminance(line.color) > 0.5 ? '#333' : '#fff'
      div.style.padding = '2px 8px'
    } else if (['imageAndCityName'].includes(terminusStyle)) {
      div.style.padding = '2px 8px 2px 2px'
    }
  })

  let styleLine = REACT_APP_LINES_MAIN_TYPE ? REACT_APP_LINES_MAIN_TYPE : 'color'
  let prefixNetwork = false

  if (REACT_APP_LINES_TYPE_EXCEPTIONS) {
    const exceptions = JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS)
    const foundExceptedLine = exceptions.find(e => e.lines?.includes(line.id) || e.networks?.includes(line.network))

    if (foundExceptedLine) {
      styleLine = foundExceptedLine.type
      prefixNetwork = foundExceptedLine.prefixNetwork === true
    }
  }

  // Filter all disruptions and get only the one impacted by this route
  const disruptionsInLineForThatRoute = []
  const route = line.routes.find(r => r.direction_id === line.direction_id)
  const impacted_objects = []

  // Do we have disruptions ?
  if (disruptionsInLine) {
    for (const disruption of disruptionsInLine) {
      for (const impacted of disruption.impacted_objects) {
        // If the disruption has already been stored, skip it
        if (disruptionsInLineForThatRoute.indexOf(disruption) === -1) {
          if (impacted.routes && impacted.routes.find(r => r.id === route.route_id)) {
            // Section disruptions
            disruptionsInLineForThatRoute.push(disruption)
            impacted_objects.push(...disruption.impacted_objects.filter(impacted => impacted.line === line.id))
          } else if (impacted.id) {
            // Stop disrutpions
            if (stopsList.find(s => s[impacted.type === 'stop_area' ? 'stop_area' : 'id'] === impacted.id)) {
              disruptionsInLineForThatRoute.push(disruption)
              impacted_objects.push(impacted)
            }
          } else if (impacted.type === 'line') {
            // Line disruptions
            disruptionsInLineForThatRoute.push(disruption)
          }
        }
      }
    }
  }

  // Retrieve disruptions in line sorted by begin date
  const disruptions = disruptionsInLineForThatRoute
    ? sortBy(sortBy(disruptionsInLineForThatRoute, 'begin'), 'severity').map(disruption => (
        <div key={disruption.id} className={'lc-' + disruption.severity}>
          {JSON.parse(REACT_APP_DISRUPTION).severityTitle !== false && (
            <div className="lc-disruption-severity">
              <div className="lc-icon" />
              {JSON.parse(REACT_APP_DISRUPTION).titleInsteadOfSeverity === true && disruption.title
                ? disruption.title
                : disruption.severity === 'blocking'
                ? 'Perturbation majeure'
                : disruption.severity === 'delays'
                ? 'Perturbation'
                : 'Information'}
            </div>
          )}
          <div
            className="lc-disruption"
            //tabIndex="0"
            aria-label={
              disruptionsDatetime(disruption.begin, disruption.end, language) +
              ' : ' +
              disruption.message?.replace(/<[^>]+>/g, ' ')
            }>
            {disruption.title && !JSON.parse(REACT_APP_DISRUPTION).titleInsteadOfSeverity && (
              <div className="lc-disruption-title">{disruption.title}</div>
            )}
            {disruption.message ? (
              <div className="lc-disruption-content" dangerouslySetInnerHTML={{ __html: disruption.message }} />
            ) : (
              <div className="lc-disruption-content empty">Aucun motif renseigné</div>
            )}
            {JSON.parse(REACT_APP_DISRUPTION).date !== false && (
              <div className="lc-disruption-dates">
                {disruptionsDatetime(disruption.begin, disruption.end, language)}
                {disruption.periods.length > 1 && (
                  <>
                    <div
                      className="lc-disruption-dates-more"
                      onClick={() => {
                        const disruptions = Array.from(disruptionsInLine)
                        const current = disruptions.find(d => d.id === disruption.id)

                        current.opened = !current.opened

                        appStore.dispatch(actionSetDisruptionInLine(disruptions))
                      }}
                      onKeyUp={e =>
                        handleKeyUp(e, () => {
                          const disruptions = Array.from(disruptionsInLine)
                          const current = disruptions.find(d => d.id === disruption.id)

                          current.opened = !current.opened

                          appStore.dispatch(actionSetDisruptionInLine(disruptions))
                        })
                      }
                      role="button"
                      tabIndex="0">
                      {translate('display-disruptions-more-dates')}
                    </div>
                    <Collapse isOpen={!!disruption.opened}>
                      <div className="lc-collapsed">
                        {Array.from(disruption.periods)
                          .splice(1)
                          .map(period => (
                            <div key={period.begin}>{disruptionsDatetime(period.begin, period.end, language)}</div>
                          ))}
                      </div>
                    </Collapse>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      ))
    : []

  const clickSwap = line => {
    const { areas, stops } = component.props
    let { openedMarker } = component.props

    line.direction_id = line.direction_id === 'f' ? 'b' : 'f'

    if (openedMarker && areas) {
      const area = areas.find(area => area.id === openedMarker.stop_area)

      const found = area.lines.find(
        itemLine =>
          itemLine.id === line.id &&
          (itemLine.stop_id !== openedMarker.id || line.direction_id === itemLine.direction_id),
      )

      if (!found) {
        appStore.dispatch(
          actionSetLineInformation(
            `${translate('stop-timetable-direction-not-deserved')} (<strong>${openedMarker.name}</strong>)`,
          ),
        )
        appStore.dispatch(actionOnLineSelected(line))
        return
      }

      const route = line.routes.find(r => r.direction_id === line.direction_id)

      const stop = stops.find(s => s.stop_area === area.id && s.lines.map(l => l.route_id).includes(route.route_id))

      appStore.dispatch(actionOnLineSelected(line, stop))
    } else {
      appStore.dispatch(actionOnLineSelected(line))
    }

    message({
      clicked: 'line-direction-swap',
      line: line.id,
      line_direction: line.direction_id,
    })
  }

  const clickDownloadTimetable = timetable => {
    // Google Tag Manager
    updateDataLayer({
      event: 'map-downloadTimetable',
      line: component.state.currentLine.code,
    })

    if (REACT_APP_TIMETABLES === 'local') {
      window.open('/' + timetable.file)
    } else if (REACT_APP_TIMETABLES === 'api') {
      window.open(timetable.file)
    }
  }

  const clickPrintTimetable = async () => {
    const searchParams = addGetParam(params, {
      lang: language,
      type: 'timetable',
      size: 'large',
    })

    component.setState({
      printing: true,
    })

    const response = await axios.get(`/api/print${searchParams}`)

    if (response && response.data && response.data.status === 'done') {
      const element = document.createElement('a')

      element.setAttribute(
        'href',
        `${
          REACT_APP_API_PROXY_URL
            ? REACT_APP_API_PROXY_URL.slice(-1) === '/'
              ? REACT_APP_API_PROXY_URL
              : REACT_APP_API_PROXY_URL + '/'
            : `${window.location.origin}/`
        }api/file?name=${response.data.filename}&folder=${response.data.dir}&ext=pdf`,
      )
      element.click()
      element.onclick = () => element.remove()
    }

    component.setState({
      printing: false,
    })
  }

  const renderLineHeader = () => {
    switch (styleLine) {
      case 'modeWithDirection':
        return (
          <>
            <div
              className="lc-line lc-mode lc-with-direction"
              style={{
                background: '#' + line.color,
                color: luminance(line.color) > 0.5 ? '#333' : '#fff',
              }}
              aria-hidden="true"
              tabIndex="-1">
              {lineMode.name}
            </div>
            <span
              className={`lc-direction ${
                line?.tad?.thermo === 'hide' || line?.tad?.noLineDirection === true ? 'lc-tad-hide-direction' : ''
              }`}
              role="button"
              tabIndex="0"
              aria-label={translate(
                'aria-line-header',
                false,
                { key: 'line', value: translate('aria-line', false, { key: 'code', value: line.code }) },
                { key: 'direction', value: line.name },
              )}>
              {line.name}
            </span>
          </>
        )
      case 'codeWithDirection':
        return (
          <>
            <div
              className="lc-line lc-code lc-with-direction"
              style={{
                background: '#' + line.color,
                color: luminance(line.color) > 0.5 ? '#333' : '#fff',
              }}
              aria-hidden="true"
              tabIndex="-1">
              {line.code}
            </div>
            <span
              className={`lc-direction ${
                line?.tad?.thermo === 'hide' || line?.tad?.noLineDirection === true ? 'lc-tad-hide-direction' : ''
              }`}
              role="button"
              tabIndex="0"
              aria-label={translate(
                'aria-line-header',
                false,
                { key: 'line', value: translate('aria-line', false, { key: 'code', value: line.code }) },
                { key: 'direction', value: line.name },
              )}>
              {line.name}
            </span>
          </>
        )
      case 'imageWithRouteDirection':
      case 'image':
        return (
          <>
            <div
              className={`lc-line${configApp.split_stops_name_and_town_by ? ' lc-with-split-town' : ''}`}
              style={{ padding: '10px' }}
              aria-hidden="true"
              tabIndex="-1">
              {configApp?.line_website_url_information ? (
                <Tippy
                  theme={'latitude'}
                  touch={['hold', 500]}
                  placement={'top'}
                  boundary="window"
                  content={translate('line-website-information', false)}>
                  <a
                    className="lc-line-website-information"
                    href={
                      configApp?.line_website_url_information?.replace('#code#', line.code)?.replace('#id#', line.id) ||
                      '#'
                    }
                    target="_blank"
                    rel="noopener noreferrer">
                    <UIDisruptedLineOverlay line={line} styleLine={process.env.REACT_APP_LINES_MAIN_TYPE}>
                      <img
                        src={assetsPath(
                          '/assets/images/lines/' + (prefixNetwork ? line.network + '-' : '') + line.code + '.svg',
                        )}
                        alt={line.code}
                      />
                    </UIDisruptedLineOverlay>
                  </a>
                </Tippy>
              ) : (
                <UIDisruptedLineOverlay line={line} styleLine={process.env.REACT_APP_LINES_MAIN_TYPE}>
                  <img
                    src={assetsPath(
                      '/assets/images/lines/' + (prefixNetwork ? line.network + '-' : '') + line.code + '.svg',
                    )}
                    alt={line.code}
                  />
                </UIDisruptedLineOverlay>
              )}
            </div>
            <div
              className={`lc-direction ${
                line?.tad?.thermo === 'hide' || line?.tad?.noLineDirection === true ? 'lc-tad-hide-direction' : ''
              }`}
              role="button"
              tabIndex="0"
              aria-label={translate(
                'aria-line-header',
                false,
                { key: 'line', value: translate('aria-line', false, { key: 'code', value: line.code }) },
                { key: 'direction', value: line.routes.find(r => r.direction_id === line.direction_id).name },
              )}>
              {translate('line-direction')} :<br />
              {configApp.split_stops_name_and_town_by ? (
                <div className="lc-stop-and-town-name">
                  <span className="lc-stop-town-town">
                    {
                      line.routes
                        .find(r => r.direction_id === line.direction_id)
                        .name.split(configApp.split_stops_name_and_town_by)[0]
                    }
                  </span>
                  {line.routes
                    .find(r => r.direction_id === line.direction_id)
                    .name.split(configApp.split_stops_name_and_town_by)[1] && (
                    <span className="lc-stop-town-name">
                      {
                        line.routes
                          .find(r => r.direction_id === line.direction_id)
                          .name.split(configApp.split_stops_name_and_town_by)[1]
                      }
                    </span>
                  )}
                </div>
              ) : (
                <strong>{line.routes.find(r => r.direction_id === line.direction_id).name}</strong>
              )}
            </div>
          </>
        )
      case 'color':
        return (
          <>
            <div
              className={`lc-line${configApp.split_stops_name_and_town_by ? ' lc-with-split-town' : ''}`}
              aria-hidden="true"
              tabIndex="-1">
              <UIDisruptedLineOverlay line={line} styleLine={process.env.REACT_APP_LINES_MAIN_TYPE}>
                <span
                  className={'lc-line-code'}
                  style={{
                    background: `#${line.color}`,
                    color: `#${line.text}`,
                  }}>
                  {line.code}
                </span>
              </UIDisruptedLineOverlay>
            </div>
            <span
              className={`lc-direction${
                line?.tad?.thermo === 'hide' || line?.tad?.noLineDirection === true ? ' lc-tad-hide-direction' : ''
              }`}
              role="button"
              tabIndex="0"
              aria-label={translate(
                'aria-line-header',
                false,
                { key: 'line', value: translate('aria-line', false, { key: 'code', value: line.code }) },
                { key: 'direction', value: line.routes.find(r => r.direction_id === line.direction_id).name },
              )}>
              {translate('line-direction')} :<br />
              {configApp.split_stops_name_and_town_by ? (
                <div className="lc-stop-and-town-name">
                  <span className="lc-stop-town-town">
                    {
                      line.routes
                        .find(r => r.direction_id === line.direction_id)
                        .name.split(configApp.split_stops_name_and_town_by)[0]
                    }
                  </span>
                  {line.routes
                    .find(r => r.direction_id === line.direction_id)
                    .name.split(configApp.split_stops_name_and_town_by)[1] && (
                    <span className="lc-stop-town-name">
                      {
                        line.routes
                          .find(r => r.direction_id === line.direction_id)
                          .name.split(configApp.split_stops_name_and_town_by)[1]
                          .split('(')?.[0]
                      }
                    </span>
                  )}
                </div>
              ) : (
                <strong>{line.routes.find(r => r.direction_id === line.direction_id).name}</strong>
              )}
            </span>
          </>
        )
      default:
        return ''
    }
  }

  const renderStop = (stop, index) => {
    const hasTerminusLinesThrough = line.tad?.terminusLinesThrough && stop.terminus
    let terminusLinesThroughNumber = 0

    if (hasTerminusLinesThrough) {
      terminusLinesThroughNumber = unique(
        component.props.areas.find(a => a.id === stop.stop_area).lines?.filter(l => l.id !== line.id),
        'id',
      ).length
    }

    return (
      <li
        key={stop.index}
        data-stop-index={stop.index}
        className={'lc-stop' + (stop.opened && touchscreenSelected ? ' selected' : '')}>
        {line.cat !== 'TAD-markers-only' && (
          <>
            {(!line.tad || (line.tad && line.tad.thermo)) && !noThermo && (
              <div
                className={'lc-border' + (index === 0 ? ' lc-first-border' : '')}
                style={{ borderLeftColor: '#' + line.color }}
              />
            )}
            {REACT_APP_DISRUPTION &&
            reduxMarkers &&
            reduxMarkers.find(m => m.key === line.code + '_' + stop.index + '_disrupted') ? (
              <div
                style={{
                  border: '2px solid #' + line.color,
                  background: stop.severity === 'blocking' ? 'red' : 'orange',
                }}
                className={'lc-point' + (stop.terminus ? ' lc-stop-terminus' : '')}>
                <div
                  className={
                    stop.severity === 'blocking' && REACT_APP_DISRUPTION
                      ? 'lc-' + JSON.parse(REACT_APP_DISRUPTION).map.blocking
                      : stop.severity === 'delays' && REACT_APP_DISRUPTION
                      ? 'lc-' + JSON.parse(REACT_APP_DISRUPTION).map.delays
                      : ''
                  }
                  style={{ borderColor: '#' + line.color }}></div>
              </div>
            ) : line?.tad?.terminusShape && stop.terminus ? (
              <UITerminusShape line={line} />
            ) : (
              <div
                style={{ border: '2px solid #' + line.color }}
                className={
                  'lc-point' +
                  (stop.terminus ? ' lc-stop-terminus' : '') +
                  (noThermo && !line.tad?.separateStopAndTerminus ? ' lc-point-no-thermo' : '')
                }
              />
            )}
          </>
        )}
        <div className={'lc-stop-name' + (stop.opened ? ' lc-selected' : '') + (noThermo ? ' lc-tad-no-thermo' : '')}>
          <div
            className={`lc-stop-name-content${stop.opened ? ' lc-selectedStop' : ''} ${
              stop.terminus ? ' lc-terminus-bold' : ''
            }`}
            role="button"
            tabIndex="0"
            aria-expanded={stop.opened ? 'true' : 'false'}
            aria-controls="lc-schedules"
            aria-label={translate(
              'aria-lines-stop',
              false,
              { key: 'stop', value: stop.name },
              {
                key: 'pmr',
                value:
                  REACT_APP_SHOW_PMR && stop.pmr && ['pmr', 'both'].includes(REACT_APP_SHOW_PMR)
                    ? '(' + translate('aria-lines-stop-pmr') + ')'
                    : '',
              },
            )}
            onClick={() => {
              if (stop.opened) {
                history.push({
                  pathname,
                  search: search.split('&stop=')[0],
                })
              } else {
                const searchParam = addGetParam(params, { stop: stop.id })

                message({ clicked: 'stop', id: stop.id })

                history.push({
                  pathname,
                  search: searchParam,
                })
                setTimeout(() => {
                  if (document.querySelector('.lc-selectedStop')) {
                    document.querySelector('.lc-selectedStop').focus()
                  }
                }, 100)
              }
            }}
            onKeyUp={e =>
              handleKeyUp(e, () => {
                if (stop.opened) {
                  history.push({
                    pathname,
                    search: search.split('&stop=')[0],
                  })
                } else {
                  const searchParam = addGetParam(params, { stop: stop.id })

                  message({ clicked: 'stop', id: stop.id })
                  history.push({
                    pathname,
                    search: searchParam,
                  })
                  setTimeout(() => {
                    document.querySelector('.lc-selectedStop').focus()
                  }, 100)
                }
              })
            }
            onMouseEnter={() => {
              appStore.dispatch(actionOverMarker(stop))
            }}
            onMouseLeave={() => {
              setTimeout(() => appStore.dispatch(actionOutMarker(stop)))
            }}>
            <div
              className={`lc-stop-and-tools${
                timetableButtonNextToStopName && (stop.opened || stop.id === params.stop) ? ' lc-stop-opened' : ''
              }${terminusLinesThroughNumber > 3 ? ' lc-big-terminus-lines-through' : ''}`}
              style={
                timetableButtonNextToStopName && (stop.opened || stop.id === params.stop)
                  ? {
                      backgroundColor: `#${line.color}`,
                      color: luminance(line.color) > 0.5 ? '#333' : '#fff',
                    }
                  : {}
              }>
              <div
                className={`${configApp.split_stops_name_and_town_by ? 'lc-stop-and-town-name' : 'lc-stop-name-only'}${
                  !timetableButtonNextToStopName && (stop.opened || stop.id === params.stop) ? ' lc-stop-opened' : ''
                }`}
                style={
                  !timetableButtonNextToStopName && (stop.opened || stop.id === params.stop)
                    ? {
                        backgroundColor: `#${line.color}`,
                        color: luminance(line.color) > 0.5 ? '#333' : '#fff',
                      }
                    : {}
                }>
                {configApp.split_stops_name_and_town_by ? (
                  <>
                    <span className="lc-stop-town-town">
                      {stop.name.split(configApp.split_stops_name_and_town_by)[0]}
                    </span>
                    {stop.name.split(configApp.split_stops_name_and_town_by)[1] && (
                      <span className="lc-stop-town-name">
                        {stop.name.split(configApp.split_stops_name_and_town_by)[1]}
                        <UIStopPmr
                          pmr={stop.pmr}
                          whitePicto={
                            (stop.opened || stop.id === params.stop) && luminance(line.color) <= 0.5 ? true : false
                          }
                        />
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    <span>{stop.name}</span>
                    <UIStopPmr
                      pmr={stop.pmr}
                      whitePicto={
                        (stop.opened || stop.id === params.stop) && luminance(line.color) <= 0.5 ? true : false
                      }
                    />
                  </>
                )}
              </div>

              {REACT_APP_SHOW_ADDITIONAL_STOP_TOOL &&
                JSON.parse(REACT_APP_SHOW_ADDITIONAL_STOP_TOOL).map(tool => {
                  if (
                    stop[tool] &&
                    (stop[tool] === true || (stop[tool].length && stop[tool].includes(`${line.code}_${line.network}`)))
                  ) {
                    return <div key={`${stop.id}_${tool}`} className={`lc-is-${tool}`} />
                  } else {
                    return false
                  }
                })}
              {hasTerminusLinesThrough ? (
                <div className="lc-stop-lines-through">
                  {unique(
                    component.props.areas.find(a => a.id === stop.stop_area).lines?.filter(l => l.id !== line.id),
                    'id',
                  ).map((l, li) => (
                    <UILine key={li} line={l} image={process.env.REACT_APP_LINES_MAIN_TYPE?.includes('image')} />
                  ))}
                </div>
              ) : null}
            </div>

            {timetableButtonNextToStopName &&
              (stop.opened || stop.id === params.stop) &&
              !(stop.terminus && stop.id === stopsList[stopsList.length - 1]?.id) &&
              showTimetable &&
              moduleDataDontShowTimetable !== true && (
                <div
                  className={`lc-seeTimetable${
                    moduleDataDisplayScheduleDestination ? ' lc-with-schedule-destinations' : ''
                  }`}
                  onClick={e => {
                    e.stopPropagation()
                    let date = formatDate(new Date(), 'ymd')

                    if (component.props?.moduleData?.defaultDateTime || component.props.defaultDateTime) {
                      const { minDate, defaultDateTime } = component.props?.moduleData || component.props

                      // Check if current date is before min
                      if (defaultDateTime && minDate) {
                        const minD = new Date(minDate),
                          now = new Date()

                        if (now < minD) {
                          date = formatDate(new Date(defaultDateTime), 'ymd')
                        }
                      }
                    }

                    history.push({
                      pathname,
                      search: search + '&date=' + date,
                    })
                  }}
                  onKeyUp={e =>
                    handleKeyUp(e, () => {
                      let date = formatDate(new Date(), 'ymd')

                      if (component.props?.moduleData?.defaultDateTime || component.props.defaultDateTime) {
                        const { minDate, defaultDateTime } = component.props?.moduleData || component.props

                        // Check if current date is between min and max
                        if (defaultDateTime && minDate) {
                          const minD = new Date(minDate),
                            now = new Date()

                          if (now < minD) {
                            date = formatDate(new Date(defaultDateTime), 'ymd')
                          }
                        }
                      }

                      history.push({
                        pathname,
                        search: search + '&date=' + date,
                      })
                    })
                  }
                  role="button"
                  tabIndex="0"
                  aria-label={translate('aria-lines-see-timetable', false, {
                    key: 'stop',
                    value: stop.name,
                  })}>
                  <img src={assetsPath('/assets/images/timetable.svg')} alt={translate('timetable-button')} />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: translate('timetable-button'),
                    }}
                  />
                </div>
              )}

            {showFavorites('stops', token) && stop.opened && (
              <Tippy
                theme={'latitude'}
                touch={['hold', 500]}
                placement={'right'}
                boundary="window"
                content={translate('title-favorite')}>
                <span
                  className={
                    'lc-tool-favorite stop' +
                    (favoriteStop &&
                    stop?.stop_area === favoriteStop?.id &&
                    line?.routes?.find(r => r.direction_id === line.direction_id)?.route_id === favoriteStop?.route
                      ? ' active'
                      : '')
                  }
                  data-tip
                  data-for="lc-login-stops"
                  onClick={e => {
                    e.stopPropagation()

                    if (token) {
                      stop.route_id = line.routes.find(r => r.direction_id === line.direction_id).route_id
                      updateFavorite(stop, token, clientId)
                    }
                  }}
                  onKeyUp={e =>
                    handleKeyUp(e, () => {
                      e.stopPropagation()

                      if (token) {
                        stop.route_id = line.routes.find(r => r.direction_id === line.direction_id).route_id
                        updateFavorite(stop, token, clientId)
                      }
                    })
                  }
                  role="button"
                  tabIndex="0"
                  aria-label={translate('aria-favorite', false)}>
                  <ReactTooltip
                    id="lc-login-stops"
                    disable={token !== undefined}
                    isCapture
                    place={isMobile ? 'left' : 'right'}
                    offset={isMobile ? { top: 0, left: -10 } : {}}
                    type="light"
                    effect="solid"
                    event="click"
                    globalEventOff="click"
                    clickable>
                    {translate('favorite-not-connected')}
                    <br />
                    {REACT_APP_FAVORITES && JSON.parse(REACT_APP_FAVORITES)?.login && (
                      <a
                        className="lc-button lc-login"
                        href={JSON.parse(REACT_APP_FAVORITES)?.login || ''}
                        target="_parent">
                        {translate('favorite-login')}
                      </a>
                    )}
                  </ReactTooltip>
                </span>
              </Tippy>
            )}
          </div>
          {nextSchedules > 0 && (!line.tad || (line.tad && line.tad.schedules)) && (
            <Collapse isOpen={!!stop.opened} id="lc-schedules">
              {displayError.filter(e => e?.type === 'stop' && e.id.includes(stop.id)).length > 0 && (
                <div className="lc-display-error lc-stop-error">
                  {displayError
                    .filter(e => e?.type === 'stop' && e.id.includes(stop.id))
                    .map(e => (
                      <span key={e.id}>{e.message}</span>
                    ))}
                </div>
              )}
              {stop.severity && stop.severity === 'blocking' ? (
                <>
                  <div className={'lc-severity lc-schedules lc-blocking'}>
                    <div className="lc-disruption-severity">
                      <div className="lc-icon" />
                      Perturbation majeure
                    </div>
                    {translate('severity-blocking-stop')} {line.code}
                  </div>
                </>
              ) : stop.opened ? (
                !stop.rer ? (
                  <>
                    {REACT_APP_DISRUPTION && JSON.parse(REACT_APP_DISRUPTION)?.onStop === true
                      ? displayDisruptionsForOneStop(line, stop, disruptionsInLine, language)
                      : null}
                    {configApp?.display_address_for?.includes(line.mode) && stop.address && (
                      <div className="lc-stop-address">{stop.address}</div>
                    )}
                    {noScheduleTodayLabel &&
                    stop?.schedules?.filter(
                      s => s && s.time && new Date(navitiaDateToDate(s.time)).getDate() === new Date().getDate(),
                    )?.length === 0 ? (
                      <div className="lc-no-schedule-today" tabIndex="0" role="button">
                        <img src={assetsPath('/assets/images/informations.svg')} alt="information" aria-hidden="true" />
                        {translate('no-schedule-today')}
                      </div>
                    ) : null}
                    <div className={'lc-selectedContent' + (!showSchedules ? ' lc-no-schedules' : '')}>
                      {showSchedules &&
                        (stop.schedules ? (
                          <div
                            key={stop.index + '_schedules'}
                            className="lc-schedules"
                            tabIndex="0"
                            role="button"
                            aria-label={translate(
                              'aria-lines-next-schedules',
                              false,
                              { key: 'code', value: line.code },
                              { key: 'stop', value: stop.name },
                              {
                                key: 'direction',
                                value: line.routes.find(r => r.direction_id === line.direction_id).name,
                              },
                              {
                                key: 'schedules',
                                value: stop.schedules
                                  .map(schedule =>
                                    formatDate(new Date(navitiaDateToDate(schedule.time)), 'full-with-time', language),
                                  )
                                  .join(', '),
                              },
                            )}>
                            {moduleDataDisplayScheduleDestination && (
                              <div
                                className={`lc-schedule lc-schedule-destinations ${
                                  notScheduleDestinationIfOneDirection &&
                                  stop.schedules
                                    .map(schedule => schedule.destination)
                                    .filter((v, i, a) => a.indexOf(v) === i).length === 1
                                    ? 'lc-one-direction'
                                    : ''
                                }`}>
                                {stop.schedules.length === 0 &&
                                !(configApp?.display_stop_is_terminus === true && stop.terminus === true) ? (
                                  <div
                                    className="lc-schedule-destination"
                                    style={
                                      notScheduleDestinationIfOneDirection ||
                                      configApp?.schedules_destinations_style !== 'borderLeftLineColor'
                                        ? {}
                                        : configApp?.schedules_destinations_style === 'borderLeftLineColor'
                                        ? {
                                            borderLeft: `solid ${'#' + line.color} 2px`,
                                            paddingLeft: '5px',
                                          }
                                        : {}
                                    }>
                                    <div
                                      className="lc-destination-name"
                                      style={
                                        configApp?.schedules_destinations_style === 'destinationLineColor'
                                          ? { color: '#' + line.color }
                                          : {}
                                      }>
                                      {translate('schedules-to')}{' '}
                                      {line.routes.find(r => r.direction_id === line.direction_id).name}
                                    </div>
                                    <div className="lc-destination-schedules">
                                      <div className="lc-no-schedules">{translate('no-schedules')}</div>
                                    </div>
                                  </div>
                                ) : null}
                                {stop.schedules
                                  .map(schedule => schedule.destination)
                                  .filter((v, i, a) => a.indexOf(v) === i)
                                  .map((dest, i) => (
                                    <div
                                      className="lc-schedule-destination"
                                      key={`schedule-destination-${i}`}
                                      style={
                                        (notScheduleDestinationIfOneDirection &&
                                          stop.schedules
                                            .map(schedule => schedule.destination)
                                            .filter((v, i, a) => a.indexOf(v) === i).length === 1) ||
                                        configApp?.schedules_destinations_style !== 'borderLeftLineColor'
                                          ? {}
                                          : configApp?.schedules_destinations_style === 'borderLeftLineColor'
                                          ? {
                                              borderLeft: `solid ${'#' + line.color} 2px`,
                                              paddingLeft: '5px',
                                            }
                                          : {}
                                      }>
                                      <div
                                        className="lc-destination-name"
                                        style={
                                          configApp?.schedules_destinations_style === 'destinationLineColor'
                                            ? { color: '#' + line.color }
                                            : {}
                                        }>
                                        {translate('schedules-to')} {dest}
                                      </div>
                                      <div className="lc-destination-schedules">
                                        {stop.schedules
                                          .filter(schedule => schedule.destination === dest)
                                          .filter((schedule, ii) => ii < nextSchedules)
                                          .map((schedule, ii) => (
                                            <UIStopScheduleTime
                                              configApp={configApp}
                                              key={`schedule-${ii}`}
                                              schedule={schedule}
                                              language={language}
                                            />
                                          ))}
                                        {stop.schedules.filter(schedule => schedule.destination === dest).length >
                                        nextSchedules ? (
                                          <>
                                            <Collapse
                                              isOpen={
                                                component.state.seeMoreSchedule &&
                                                component.state.seeMoreSchedule[`${stop.id}-${dest}`] === true
                                              }>
                                              {stop.schedules
                                                .filter(schedule => schedule.destination === dest)
                                                .filter((schedule, ii) => ii >= nextSchedules && ii < nextSchedules * 2)
                                                .map((schedule, ii) => (
                                                  <UIStopScheduleTime
                                                    configApp={configApp}
                                                    key={`schedule-${ii}`}
                                                    schedule={schedule}
                                                    language={language}
                                                  />
                                                ))}
                                            </Collapse>
                                            <div
                                              className="lc-destination-schedules-see-more"
                                              tabIndex="0"
                                              role="button"
                                              onClick={() => {
                                                if (!component.state.seeMoreSchedule) {
                                                  component.setState({
                                                    seeMoreSchedule: { [`${stop.id}-${dest}`]: true },
                                                  })
                                                } else {
                                                  component.setState({
                                                    seeMoreSchedule: {
                                                      ...component.state.seeMoreSchedule,
                                                      [`${stop.id}-${dest}`]: component.state.seeMoreSchedule[
                                                        `${stop.id}-${dest}`
                                                      ]
                                                        ? false
                                                        : true,
                                                    },
                                                  })
                                                }
                                              }}
                                              onKeyUp={e =>
                                                handleKeyUp(e, () => {
                                                  if (!component.state.seeMoreSchedule) {
                                                    component.setState({
                                                      seeMoreSchedule: { [`${stop.id}-${dest}`]: true },
                                                    })
                                                  } else {
                                                    component.setState({
                                                      seeMoreSchedule: {
                                                        ...component.state.seeMoreSchedule,
                                                        [`${stop.id}-${dest}`]: component.state.seeMoreSchedule[
                                                          `${stop.id}-${dest}`
                                                        ]
                                                          ? false
                                                          : true,
                                                      },
                                                    })
                                                  }
                                                })
                                              }>
                                              {component.state.seeMoreSchedule &&
                                              component.state.seeMoreSchedule[`${stop.id}-${dest}`] ? (
                                                translate('see-less')
                                              ) : (
                                                <div className="lc-see-more-container">{translate('see-more')}</div>
                                              )}{' '}
                                              <UIArrow
                                                className={`lc-destination-schedules-see-more-arrow ${
                                                  component.state.seeMoreSchedule &&
                                                  component.state.seeMoreSchedule[`${stop.id}-${dest}`]
                                                    ? 'lc-is-open'
                                                    : ''
                                                }`}
                                                isOpen={
                                                  component.state.seeMoreSchedule &&
                                                  component.state.seeMoreSchedule[`${stop.id}-${dest}`]
                                                }
                                              />
                                            </div>
                                          </>
                                        ) : null}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            )}
                            {!moduleDataDisplayScheduleDestination && stop.schedules.length > 0
                              ? stop.schedules.map((schedule, index) => (
                                  <UIStopScheduleTime
                                    configApp={configApp}
                                    key={`schedule-${index}`}
                                    schedule={schedule}
                                    language={language}
                                  />
                                ))
                              : null}
                            {configApp?.display_stop_is_terminus &&
                            stop.terminus &&
                            stop.schedules?.length === 0 &&
                            translate('stop-terminus', false) ? (
                              <div className="lc-no-schedules lc-stop-is-terminus">{translate('stop-terminus')}</div>
                            ) : !moduleDataDisplayScheduleDestination && stop.schedules.length === 0 ? (
                              <div className="lc-no-schedules">{translate('no-schedules')}</div>
                            ) : null}
                          </div>
                        ) : stop.terminus && stop.id === stopsList[stopsList.length - 1]?.id ? null : (
                          <img
                            className="lc-loader-gif"
                            src={assetsPath('/assets/images/loading.gif')}
                            width={30}
                            alt={translate('loading')}
                          />
                        ))}
                      {!timetableButtonNextToStopName &&
                        (stop.opened || stop.id === params.stop) &&
                        !(stop.terminus && stop.id === stopsList[stopsList.length - 1]?.id) &&
                        !(configApp?.display_stop_is_terminus && stop.terminus) &&
                        showTimetable &&
                        moduleDataDontShowTimetable !== true && (
                          <div
                            className={`lc-seeTimetable ${
                              moduleDataDisplayScheduleDestination ? 'lc-with-schedule-destinations' : ''
                            }`}
                            onClick={e => {
                              e.stopPropagation()
                              let date = formatDate(new Date(), 'ymd')

                              if (component.props?.moduleData?.defaultDateTime || component.props.defaultDateTime) {
                                const { minDate, defaultDateTime } = component.props?.moduleData || component.props

                                // Check if current date is before min
                                if (defaultDateTime && minDate) {
                                  const minD = new Date(minDate),
                                    now = new Date()

                                  if (now < minD) {
                                    date = formatDate(new Date(defaultDateTime), 'ymd')
                                  }
                                }
                              }

                              history.push({
                                pathname,
                                search: search + '&date=' + date,
                              })
                            }}
                            onKeyUp={e =>
                              handleKeyUp(e, () => {
                                let date = formatDate(new Date(), 'ymd')

                                if (component.props?.moduleData?.defaultDateTime || component.props.defaultDateTime) {
                                  const { minDate, defaultDateTime } = component.props?.moduleData || component.props

                                  // Check if current date is between min and max
                                  if (defaultDateTime && minDate) {
                                    const minD = new Date(minDate),
                                      now = new Date()

                                    if (now < minD) {
                                      date = formatDate(new Date(defaultDateTime), 'ymd')
                                    }
                                  }
                                }

                                history.push({
                                  pathname,
                                  search: search + '&date=' + date,
                                })
                              })
                            }
                            role="button"
                            tabIndex="0"
                            aria-label={translate('aria-lines-see-timetable', false, {
                              key: 'stop',
                              value: stop.name,
                            })}>
                            <img src={assetsPath('/assets/images/timetable.svg')} alt={translate('timetable-button')} />
                            <div
                              dangerouslySetInnerHTML={{
                                __html: translate('timetable-button'),
                              }}
                            />
                          </div>
                        )}
                    </div>
                  </>
                ) : (
                  <div style={{ paddingLeft: 10 }}>
                    Pour afficher les horaires, se référer au calcul d&apos;itinéraire
                  </div>
                )
              ) : (
                <div />
              )}
            </Collapse>
          )}
        </div>
      </li>
    )
  }

  return (
    <>
      <div className="lc-elevation">
        <div className="lc-active-line">
          <div
            className={`lc-line-header${
              REACT_APP_DISRUPTION && JSON.parse(REACT_APP_DISRUPTION).collapse && disruptions.length > 0
                ? ' lc-with-disruptions'
                : ''
            }`}
            data-lc-line-header
            key={line.id}>
            {renderLineHeader()}
            {!print && (
              <div className="lc-tools">
                <>
                  {line.routes.length > 1 && (
                    <Tippy
                      theme={'latitude'}
                      touch={['hold', 500]}
                      placement={'right'}
                      boundary="window"
                      content={translate('title-swap')}>
                      <span
                        className="lc-tool-swap"
                        onClick={() => clickSwap(line)}
                        onKeyUp={e => handleKeyUp(e, () => clickSwap(line))}
                        role="button"
                        tabIndex="0"
                        aria-label={translate('aria-line-swap-direction')}
                      />
                    </Tippy>
                  )}
                  {!component.state.timetable &&
                    !touchscreenSelected &&
                    (REACT_APP_TIMETABLES || configApp?.timetables) &&
                    (!REACT_APP_TIMETABLES_DISABLE_ON ||
                      (REACT_APP_TIMETABLES_DISABLE_ON &&
                        !JSON.parse(REACT_APP_TIMETABLES_DISABLE_ON)?.includes(line.mode))) && (
                      <Tippy
                        theme={'latitude'}
                        touch={['hold', 500]}
                        placement={'right'}
                        boundary="window"
                        content={
                          line?.tad?.overrideTransTimetable
                            ? translate(line.tad.overrideTransTimetable)
                            : translate('title-download-timetable')
                        }>
                        <span
                          className={'lc-tool-timetable'}
                          onClick={() => {
                            // Wil be better when REACT_APP_XXX will die
                            if (REACT_APP_TIMETABLES?.startsWith('{') || configApp?.timetables) {
                              const timetable = REACT_APP_TIMETABLES
                                ? JSON.parse(REACT_APP_TIMETABLES)
                                : configApp.timetables

                              switch (timetable.type) {
                                case 'urlWithLineCode':
                                  window.open(timetable.url.replace(/<LCCODELIGNE>/, line.code))
                                  break

                                default:
                                  break
                              }
                            } else if (REACT_APP_TIMETABLES?.startsWith('https://')) {
                              window.open(REACT_APP_TIMETABLES)
                            } else {
                              collapseTimetableOptions(component)
                            }
                          }}
                          onKeyUp={e =>
                            handleKeyUp(e, () => {
                              // Wil be better when REACT_APP_XXX will die
                              if (REACT_APP_TIMETABLES?.startsWith('{') || configApp?.timetables) {
                                const timetable = REACT_APP_TIMETABLES
                                  ? JSON.parse(REACT_APP_TIMETABLES)
                                  : configApp.timetables

                                switch (timetable.type) {
                                  case 'urlWithLineCode':
                                    window.open(timetable.url.replace(/<LCCODELIGNE>/, line.code))
                                    break

                                  default:
                                    break
                                }
                              } else if (REACT_APP_TIMETABLES?.startsWith('https://')) {
                                window.open(REACT_APP_TIMETABLES)
                              } else {
                                collapseTimetableOptions(component)
                              }
                            })
                          }
                          role="button"
                          tabIndex="0"
                          aria-label={
                            line?.tad?.overrideTransTimetable
                              ? translate(line.tad.overrideTransTimetable)
                              : translate('title-download-timetable')
                          }
                        />
                      </Tippy>
                    )}
                  {showFavorites('lines', token) && (
                    <Tippy
                      theme={'latitude'}
                      touch={['hold', 500]}
                      placement={'right'}
                      boundary="window"
                      content={translate('title-favorite')}>
                      <span
                        className={'lc-tool-favorite' + (favoriteLine?.id === line?.id ? ' active' : '')}
                        data-tip
                        data-for="lc-login"
                        onClick={() => {
                          if (token) {
                            updateFavorite(line, token, clientId)
                          }
                        }}
                        onKeyUp={e =>
                          handleKeyUp(e, () => {
                            if (token) {
                              updateFavorite(line, token, clientId)
                            }
                          })
                        }
                        role="button"
                        tabIndex="0"
                        aria-label={translate('aria-favorite', false)}>
                        <ReactTooltip
                          id="lc-login"
                          disable={token !== undefined}
                          isCapture
                          place={isMobile ? 'left' : 'right'}
                          type="light"
                          effect="solid"
                          event="click"
                          globalEventOff="click"
                          clickable>
                          {translate('favorite-not-connected')}
                          <br />
                          {REACT_APP_FAVORITES && JSON.parse(REACT_APP_FAVORITES)?.login && (
                            <a
                              className="lc-button lc-login"
                              href={JSON.parse(REACT_APP_FAVORITES)?.login || ''}
                              target="_parent">
                              {translate('favorite-login')}
                            </a>
                          )}
                        </ReactTooltip>
                      </span>
                    </Tippy>
                  )}
                </>
              </div>
            )}
            <div className={'lc-timetableOptions'} data-lc-timetable-options>
              <Collapse isOpen={!!component.state.timetableOptions}>
                {component.state.timetableLineData ? (
                  component.state.timetableLineData.length > 0 ? (
                    <>
                      {component.state.timetableLineData.map(timetable => (
                        <div
                          key={timetable.id}
                          className="lc-timetableOptionsItem"
                          target="_blank"
                          onClick={() => clickDownloadTimetable(timetable)}
                          onKeyUp={e => handleKeyUp(e, () => clickDownloadTimetable(timetable))}
                          role="button"
                          tabIndex="0">
                          {timetable.name}
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="lc-timetableOptionsItem">{translate('no-timetable')}</div>
                  )
                ) : (
                  !component.state.directDownload && (
                    <div className="lc-loading" data-lc-loading>
                      <img src={assetsPath('/assets/images/loading.gif')} width={15} alt={translate('loading')} />
                    </div>
                  )
                )}
              </Collapse>
            </div>
          </div>
          {REACT_APP_SHOW_ADDITIONAL_STOP_TOOL && !timetable && (
            <div className="lc-line-stop-informations">
              {JSON.parse(REACT_APP_SHOW_ADDITIONAL_STOP_TOOL).map(tool => {
                if (
                  stopsList.find(
                    s =>
                      s[tool] && (s[tool].length ? s[tool].includes(`${line.code}_${line.network}`) : s[tool] === true),
                  )
                ) {
                  const phoneReg =
                    /(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0[\s.-]|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})/gm

                  const linkReg = /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w-]*)?(\?[^\s]*)?/gim
                  let text = translate(`stop-info-${tool}`)

                  text = text.replace(phoneReg, `<a href="tel:$&">$&</a>`)
                  text = text.replace(linkReg, `<a href="$&" target="_blank">$&</a>`)

                  return (
                    <div key={`${tool}`} className="lc-line-stop-information">
                      <div className={`lc-is-${tool}`} />
                      <div dangerouslySetInnerHTML={{ __html: text }} />
                    </div>
                  )
                }

                return null
              })}
            </div>
          )}
          {line.tad && line.tad.booking && (
            <>
              <div className="lc-line-tad-header">{translate('tad-booking')}</div>
              <div
                className={`lc-line-tad-informations${
                  line?.tad?.thermo === 'hide' || line?.tad?.noLineDirection === true ? ' lc-tad-hide-thermo' : ''
                }`}>
                {line.tad.booking.info && typeof line.tad.booking.info === 'string' && (
                  <div className="lc-line-tad-info">
                    <img src={assetsPath('/assets/images/disruptions/informations.svg')} alt={translate('tad-info')} />
                    {line.tad.booking.info_type === 'html' ? (
                      <span dangerouslySetInnerHTML={{ __html: line.tad.booking.info }}></span>
                    ) : (
                      <span>{line.tad.booking.info}</span>
                    )}
                  </div>
                )}
                {line.tad.booking.phone && (
                  <a className="lc-line-tad-phone" href={'tel:' + line.tad.booking.phone}>
                    <img src={assetsPath('/assets/images/phone.svg')} alt={translate('tad-phone-booking')} />
                    {line.tad.booking.phone}
                  </a>
                )}
                {line.tad.booking.website && (
                  <a
                    className="lc-line-tad-website"
                    href={line.tad.booking.website.url}
                    target="_blank"
                    rel="noopener noreferrer">
                    <img src={assetsPath('/assets/images/website.svg')} alt={translate('tad-web-booking')} />
                    {line.tad.booking.website.name}
                  </a>
                )}
              </div>
            </>
          )}
          {line.errorPath && (
            <div className="lc-error" role="alert">
              <img src={assetsPath('/assets/images/error.svg')} alt={translate('severity-error')} />
              {translate('error-cant-open-line-geojson')} {line.code}
            </div>
          )}
          {displayError.filter(e => e?.type === 'line').length > 0 && (
            <div
              className={`lc-display-error ${
                REACT_APP_DISRUPTION && JSON.parse(REACT_APP_DISRUPTION).collapse && disruptions.length > 0
                  ? 'lc-space-below-error'
                  : ''
              }`}>
              {displayError
                .filter(e => e?.type === 'line')
                .map(e => (
                  <span key={e.id}>{e.message}</span>
                ))}
            </div>
          )}
          {component?.props?.moduleData && component?.props?.moduleData['thermo-label-information'] && (
            <div className="lc-thermo-label-information">{translate('lines-thermo-label-information')}</div>
          )}
          {timetable ? (
            <>
              <div className="lc-timetable-stop">
                <div className={`${configApp.split_stops_name_and_town_by ? 'lc-timetable-stop-prefix' : ''}`}>
                  {translate('stop')} :
                </div>
                <div
                  className={`lc-timetable-stop-name${configApp.split_stops_name_and_town_by ? ' lc-split-stop' : ''}`}>
                  {configApp.split_stops_name_and_town_by ? (
                    <>
                      <span className="lc-stop-town-town">
                        {component.state.timetableStop.split(configApp.split_stops_name_and_town_by)[0]}
                      </span>
                      {component.state.timetableStop.split(configApp.split_stops_name_and_town_by)[1] && (
                        <span className="lc-stop-town-name">
                          {component.state.timetableStop.split(configApp.split_stops_name_and_town_by)[1]}
                          <UIStopPmr pmr={stopsList.find(s => s.id === params.stop)?.pmr} />
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      <span>{component.state.timetableStop}</span>
                      <UIStopPmr pmr={stopsList.find(s => s.id === params.stop)?.pmr} />
                    </>
                  )}
                </div>
                {!timetablePrintButtonNextToDate ? (
                  <>
                    {!dontShow.includes('print') &&
                      !timetableDataIsEmpty(component.state.timetableData) &&
                      !touchscreenSelected &&
                      !print &&
                      !component.state.printing && (
                        <Tippy
                          theme={'latitude'}
                          touch={['hold', 500]}
                          placement={'right'}
                          boundary="window"
                          content={translate('title-print')}>
                          <div
                            className="lc-print"
                            onClick={() => clickPrintTimetable()}
                            onKeyUp={e => handleKeyUp(e, () => clickPrintTimetable())}
                            role="button"
                            tabIndex="0"
                            title={translate('title-print')}
                            aria-label={translate('aria-lines-print-timetable', false, {
                              key: 'stop',
                              value: component.state.timetableStop,
                            })}
                          />
                        </Tippy>
                      )}
                    {component.state.printing && (
                      <img src={assetsPath('/assets/images/loading.gif')} width={30} alt={translate('loading')} />
                    )}
                  </>
                ) : null}
              </div>
              <div
                className="lc-scroll"
                data-lc-scroll="scroll"
                onScroll={e => {
                  const hours = Array.from(document.querySelectorAll('.lc-timetable th'))

                  for (const hour of hours) {
                    hour.style.top = e.target.scrollTop - 95 + 'px'
                  }
                }}>
                <div className="lc-stop-timetable-and-print">
                  {!print && (
                    <div>
                      <UICalendar
                        buttonClassName="lc-stop-timetable-date"
                        button={true}
                        dateLabel={configApp?.timetable_calendar_date_label}
                        buttonBorder={true}
                        margin={10}
                        onChangeDate={dateSelected => {
                          const date = formatDate(dateSelected, 'ymd')
                          const params = getURLSearchParams(history.location)

                          if (!params.date || params.date !== date) {
                            history.push({
                              pathname,
                              search: search.split('&date=')[0] + '&date=' + date,
                            })
                          }
                        }}
                        maxDate={maxDate ? maxDate : undefined}
                        minDate={minDate ? minDate : undefined}
                      />
                    </div>
                  )}
                  {timetablePrintButtonNextToDate ? (
                    <>
                      {!dontShow.includes('print') &&
                        !timetableDataIsEmpty(component.state.timetableData) &&
                        !touchscreenSelected &&
                        !print &&
                        !component.state.printing && (
                          <Tippy
                            theme={'latitude'}
                            touch={['hold', 500]}
                            placement={'right'}
                            boundary="window"
                            content={translate('title-print')}>
                            <div
                              className="lc-print"
                              onClick={() => clickPrintTimetable()}
                              onKeyUp={e => handleKeyUp(e, () => clickPrintTimetable())}
                              role="button"
                              tabIndex="0"
                              title={translate('title-print')}
                              aria-label={translate('aria-lines-print-timetable', false, {
                                key: 'stop',
                                value: component.state.timetableStop,
                              })}
                            />
                          </Tippy>
                        )}
                      {component.state.printing && (
                        <img src={assetsPath('/assets/images/loading.gif')} width={30} alt={translate('loading')} />
                      )}
                    </>
                  ) : null}
                </div>

                {loadingTimetable && !print ? (
                  <img src={assetsPath('/assets/images/loading.gif')} width={30} alt={translate('loading')} />
                ) : (
                  <Timetable
                    data={print ? printData.timetableData : component.state.timetableData}
                    hours={print ? printData.timetableHours : component.state.timetableHours}
                    line={component.state.currentLine}
                    timetableStop={component.state.timetableStop}
                    print={print}
                    isMobile={isMobile}
                  />
                )}
              </div>
            </>
          ) : (
            <div
              className={`lc-stops lc-scroll${noThermo ? ' lc-tad-no-thermo' : ''}${
                line?.tad?.thermo === 'hide' ? ' lc-tad-hide-thermo' : ''
              }`}
              data-lc-stops
              data-lc-scroll="scroll">
              {component.props.lineInformation && (
                <div className="lc-delays">
                  <div
                    className="lc-disruption"
                    dangerouslySetInnerHTML={{
                      __html: component.props.lineInformation,
                    }}
                  />
                </div>
              )}
              {REACT_APP_DISRUPTION && JSON.parse(REACT_APP_DISRUPTION).collapse && disruptions.length > 0 ? (
                <div className="lc-disruptions" data-lc-disruptions>
                  <div
                    className={
                      `lc-disruptions-head` +
                      (disruptionsInLineForThatRoute.every(d => d.severity === 'informations')
                        ? ' with-only-infos'
                        : disruptionsInLineForThatRoute.every(d => ['delays', 'informations'].includes(d.severity))
                        ? ' with-only-delays'
                        : '')
                    }
                    onClick={() => {
                      appStore.dispatch(actionSetOpenedCollapse('disruptions-line'))
                    }}
                    onKeyUp={e =>
                      handleKeyUp(e, () => {
                        appStore.dispatch(actionSetOpenedCollapse('disruptions-line'))
                      })
                    }
                    role="button"
                    tabIndex="0"
                    aria-expanded={openedCollapse === 'disruptions-line' ? 'true' : 'false'}
                    aria-controls="lc-disruptions-line"
                    aria-label={translate(
                      disruptionsInLineForThatRoute.every(d => d.severity === 'informations')
                        ? 'display-informations'
                        : 'display-disruptions',
                    )}>
                    <div className="lc-icon"></div>
                    <span>
                      {translate(
                        disruptionsInLineForThatRoute.every(d => d.severity === 'informations')
                          ? 'display-informations'
                          : 'display-disruptions',
                      )}
                    </span>
                    <div className={`lc-caret${openedCollapse !== 'disruptions-line' ? ' lc-closed' : ''}`} />
                  </div>
                  <Collapse isOpen={openedCollapse === 'disruptions-line'} id="lc-disruptions-line">
                    {disruptions}
                  </Collapse>
                </div>
              ) : (
                disruptions.length > 0 && disruptions
              )}
              {line.tad && line.tad.separateStopAndTerminus ? (
                <div className="lc-stops-tad-separate-stop-terminus">
                  <div className="lc-title lc-title-terminus-stops">
                    {stopsList.filter(stop => stop.terminus).length > 1
                      ? translate('line-tad-stops-terminus')
                      : translate('line-tad-stop-terminus')}
                  </div>
                  <ol className="lc-ul-stops lc-ul-stops-tad-terminus">
                    {stopsList.filter(stop => stop.terminus).map((stop, index) => renderStop(stop, index))}
                  </ol>
                  <div className="lc-title lc-title-departure-stops">
                    {stopsList.filter(stop => !stop.terminus).length > 1
                      ? translate('line-tad-departure-stops')
                      : translate('line-tad-departure-stop')}
                  </div>
                  <ol className="lc-ul-stops">
                    {stopsList.filter(stop => !stop.terminus).map((stop, index) => renderStop(stop, index))}
                  </ol>
                </div>
              ) : (
                <ol className="lc-ul-stops">{stopsList.map((stop, index) => renderStop(stop, index))}</ol>
              )}
            </div>
          )}
        </div>
      </div>
      {component.state.schedulesIntervalId ? (
        <div className="lc-lines-lasted-updated-schedules">
          {translate('lines-date-update-schedules', false, {
            key: 'date',
            value: formatDate(
              component.state.schedulesLastestUpdate ? component.state.schedulesLastestUpdate : new Date(),
              'full-with-time',
              component.props.language,
            ),
          })}
        </div>
      ) : null}
    </>
  )
}

/**
 * Render lines passing by an area
 * @param component
 */
export const renderLinesByArea = component => {
  const { linesList, linesListStop } = component.state
  const { configApp } = component.props

  return (
    <div className="lc-elevation lc-lines-by-area">
      <div className={`lc-group ${configApp?.schedules_in_popup === true ? 'for-lines' : ''}`}>
        <div className="lc-group-name">
          {configApp?.schedules_in_popup === true ? (
            <div className="lc-group-mode">
              <div>
                {linesList.length > 0 ? translate('lines-by-stoparea') : translate('no-line-by-stoparea')}{' '}
                {translate('lines-going-through-stoparea')}{' '}
              </div>
              <div className="lc-stop-name-in-bold">{linesListStop}</div>
            </div>
          ) : (
            <div className="lc-group-mode">
              {linesList.length > 0 ? translate('lines-by-stoparea') : translate('no-line-by-stoparea')}{' '}
              {translate('lines-going-through-stoparea')} {linesListStop}
            </div>
          )}
        </div>
        {configApp?.schedules_in_popup !== true && linesList.length > 0 && (
          <div className="lc-group-offset-bottom">{renderLinesLabels(component, linesList, 'line')} </div>
        )}
      </div>
      {configApp?.schedules_in_popup === true && linesList.length > 0 && (
        <UILinesByArea linesList={linesList} linesListStop={linesListStop} />
      )}
    </div>
  )
}

/**
 * Display lines sorted by group (collapsable)
 * @param component
 * @returns {any[]}
 */
export const renderLinesGroup = component => {
  const { openedCollapse, linesModes } = component.props
  const { groups, pois } = component.state
  const params = getURLSearchParams(history.location)

  if (linesModes.find(lm => lm.drawOnOpen === true) && (isActiveModule('lines') || isActiveModule('multimobilities'))) {
    const thisLineMode = linesModes.find(lm => lm.name === openedCollapse)
    if (!openedCollapse) {
      appStore.dispatch(actionHideHeavyLines(false))
    }

    if (thisLineMode?.drawOnOpen === true && !params.current && params.stop_area) {
      appStore.dispatch(actionHideHeavyLines(false))
      appStore.dispatch(actionSetLinesToDisplay([]))
    } else if (thisLineMode?.drawOnOpen === true && !params.current) {
      if (thisLineMode?.hideHeavyLines === true) {
        appStore.dispatch(actionHideHeavyLines(true))
      }

      let reactLines = component.props?.map?.props?.reactLines
        ?.map(l => (l?.key?.startsWith('terminus') ? l?.props?.lines : l?.props?.line?.id))
        .flat()
        .filter((value, index, self) => self.indexOf(value) === index)

      if (!groups[openedCollapse]?.every(l => reactLines?.includes(l.id))) {
        let linesToDisplay = groups[openedCollapse]
        if (thisLineMode?.specificLines) {
          linesToDisplay = groups[openedCollapse].filter(l => thisLineMode.specificLines.includes(l.id))
        }
        if (linesToDisplay?.length > 0) {
          // sort lines to display with position and reverse it to get lines per order
          const requests = buildCompleteLines(sortBy(linesToDisplay, 'position').reverse(), thisLineMode)
          Promise.all(requests)
            .then(polylines => {
              const newParams = getURLSearchParams(history.location)

              if (
                (thisLineMode.name === appStore.getState().board.openedCollapse && isActiveModule('lines')) ||
                (isActiveModule('multimobilities') && !newParams.current)
              ) {
                appStore.dispatch(actionSetLinesToDisplay(polylines.flat()))
                if (thisLineMode.fitBoundsOnOpen) {
                  fitBounds(component.props.map, polylines.flat())
                }
              } else {
                appStore.dispatch(actionSetLinesToDisplay([]))
              }
            })
            .catch(e => {
              console.log('Error in get lines : ', e)
            })
        }
      }
    } else {
      appStore.dispatch(actionSetLinesToDisplay([]))
    }
  }

  if ((!groups || Object.keys(groups).length === 0) && (!pois || Object.keys(pois).length === 0)) {
    return <div className="lc-empty">{translate('no-lines-around')}</div>
  }

  const jsx = Object.keys(groups).map((group, index) => (
    <div key={group} className={`lc-group lc-${group}`} id={'lc-group-' + index}>
      <div
        className="lc-group-name"
        onClick={() => appStore.dispatch(actionSetOpenedCollapse(group))}
        onKeyUp={e => handleKeyUp(e, () => appStore.dispatch(actionSetOpenedCollapse(group)))}
        role="button"
        tabIndex="0"
        aria-expanded={group === openedCollapse ? 'true' : 'false'}
        aria-controls={'lc-group-section-' + index}
        aria-label={translate(group)}>
        {
          {
            modesTitles: (
              <div
                className="lc-group-mode"
                dangerouslySetInnerHTML={{
                  __html: translate(group),
                }}
              />
            ),
            modesTitlesIconsAndText: (
              <>
                <img
                  className={'lc-group-mode-logo lc-' + group}
                  src={assetsPath(`/assets/images/${group}.svg`)}
                  alt={group}
                />
                <div className="lc-mode">
                  <div className={'lc-' + group} style={getColorGroup(linesModes, group)}>
                    {translate(group)}
                  </div>
                  <div className="lc-text">{translate('mode-' + group + '-text')}</div>
                </div>
              </>
            ),
          }[REACT_APP_MODES_LINES_STYLE ? REACT_APP_MODES_LINES_STYLE : 'modesTitles']
        }
        <div className="lc-arrow-group">
          <img
            className={group !== component.props.openedCollapse ? 'lc-closed' : ''}
            src={assetsPath('/assets/images/v.svg')}
            alt={translate('collapse-arrow')}
          />
        </div>
      </div>
      <div id={'lc-group-section' + index} className={group === openedCollapse ? 'lc-open' : ''}>
        <Collapse isOpen={group === openedCollapse}>{renderLinesLabels(component, groups[group], group)}</Collapse>
      </div>
    </div>
  ))

  if (pois && Object.keys(pois).length > 0) {
    const pois = renderPlacesLabels(component)

    return [jsx, pois]
  } else {
    return jsx
  }
}

export const renderPlacesGroup = component => {
  const { openedCollapse } = component.props
  const { places } = component.state

  if (!places) {
    return
  }

  if (Object.keys(places).length === 0) {
    return <div className="lc-empty">{translate('no-places-around')}</div>
  }

  return Object.keys(places).map(place => (
    <div
      key={place}
      className="lc-group"
      onClick={() => appStore.dispatch(actionSetOpenedCollapse(place))}
      onKeyUp={e => handleKeyUp(e, () => appStore.dispatch(actionSetOpenedCollapse(place)))}
      role="button"
      tabIndex="0"
      aria-label={translate(place)}>
      <div className="lc-group-name">
        <div className="lc-group-header">
          <img src={assetsPath(`/assets/images/places/${places[place][0].code}.svg`)} alt={place} />
          <span>{translate(place)}</span>
        </div>
        <div className="lc-arrow-group">
          <img
            className={place !== openedCollapse ? 'lc-closed' : ''}
            src={assetsPath('/assets/images/v.svg')}
            alt={translate('collapse-arrow')}
          />
        </div>
      </div>
      <Collapse isOpen={place === openedCollapse}>
        {places[place].map(item => (
          <div
            key={item.id}
            className="lc-place"
            onMouseEnter={() => {
              appStore.dispatch(actionOverMarker(item))
            }}
            onMouseLeave={() => {
              appStore.dispatch(actionOutMarker(item))
            }}
            onClick={() => appStore.dispatch(actionOpenMarker(item, false))}
            onKeyUp={e => handleKeyUp(e, () => appStore.dispatch(actionOpenMarker(item, false)))}
            role="button"
            tabIndex="0">
            &bull;
            <div>{item.name}</div>
          </div>
        ))}
      </Collapse>
    </div>
  ))
}

/**
 * Render labels for given lines
 * @param component
 * @param lines
 * @param key
 * @param marker
 */
export const renderLinesLabels = (component, lines, key, marker) => {
  const { linesModes, size, moduleData } = component.props
  const onLineSelected = component.onLineSelected
  const info_type = moduleData?.info_type || component.props.info_type
  const info = moduleData?.info || component.props.info
  let addCatPictoBeforeLines =
    moduleData?.addCatPictoBeforeLines !== undefined
      ? moduleData.addCatPictoBeforeLines
      : component.props.addCatPictoBeforeLines !== undefined
      ? component.props.addCatPictoBeforeLines
      : []

  addCatPictoBeforeLines =
    addCatPictoBeforeLines?.length > 0 && unique(lines, 'cat').every(l => addCatPictoBeforeLines.includes(l.cat))

  // Avoid undefined lines...
  if (!lines) {
    return
  }

  let styleLine = REACT_APP_LINES_MAIN_TYPE ? REACT_APP_LINES_MAIN_TYPE : 'color'
  let prefixNetwork = false
  // Use to know how many rows we should have on our popup
  const gridRows = Math.ceil(lines.length / 2)
  const mode = lines[0]?.mode?.replace('commercial_mode:', '')?.toLowerCase()

  if (REACT_APP_SORT_LINES_BY_CODE) {
    sortAlphabetic(lines, 'code')
  } else {
    lines = sortBy(lines, 'position')
  }

  const div = data => (
    <div
      key={key}
      className={
        (key === 'infobox' ? 'lc-infobox-' : 'lc-') +
        'lines lc-' +
        size +
        ' lc-' +
        mode +
        (styleLine.includes('WithDirection') ? ' lc-line-with-direction' : '') +
        (key === 'infobox' && styleLine.includes('WithRouteDirection') ? ' lc-line-with-route-direction' : '') +
        (addCatPictoBeforeLines ? ' lc-cat-picto' : '')
      }
      style={
        styleLine.includes('WithRouteDirection') && key === 'infobox'
          ? {
              gridTemplateRows: `repeat(${gridRows}, 1fr)`,
            }
          : {}
      }>
      {data.map((line, lineindex, linesarray) => {
        // Retrieve the global line
        line = getLine(component, line)

        if (REACT_APP_LINES_TYPE_EXCEPTIONS) {
          const exceptions = JSON.parse(REACT_APP_LINES_TYPE_EXCEPTIONS)

          const foundExceptedLine = exceptions.find(
            e => e.lines?.includes(line.id) || e.networks?.includes(line.network),
          )

          if (foundExceptedLine) {
            styleLine = foundExceptedLine.type
            prefixNetwork = foundExceptedLine.prefixNetwork === true
          }
        }

        let element
        let pictomode

        if (addCatPictoBeforeLines && line.cat !== linesarray[lineindex - 1]?.cat) {
          pictomode = (
            <img
              key={`line-cat-${line.cat}`}
              className="lc-line-cat-picto"
              src={assetsPath(`/assets/images/route-calculation/modes/${line.cat}.svg`)}
              alt={line.cat}
            />
          )
        }

        switch (styleLine) {
          case 'modeWithDirection': {
            const lineMode = linesModes.find(mode => mode.modes.includes(line.mode))

            element = (
              <div
                className={'lc-attribute-line' + (line?.routes?.length === 0 ? ' lc-without-routes' : '')}
                key={line.id}
                onClick={e => {
                  e.stopPropagation()

                  // Add line to historic
                  if (storageAvailable('localStorage')) {
                    addHistoricItem(line)
                  }

                  !history.location.pathname.includes('route-calculation') && onLineSelected(line, marker)
                }}
                onKeyUp={e =>
                  handleKeyUp(e, () => {
                    // Add line to historic
                    if (storageAvailable('localStorage')) {
                      addHistoricItem(line)
                    }

                    !history.location.pathname.includes('route-calculation') && onLineSelected(line, marker)
                  })
                }
                role="button"
                tabIndex="0"
                aria-label={translate('aria-line', false, { key: 'code', value: line.code })}>
                <UIDisruptedLineOverlay line={line} styleLine={styleLine}>
                  <div
                    className="lc-line lc-mode"
                    style={{
                      background: '#' + line.color,
                      color: luminance('#' + line.color) > 0.5 ? '#333' : '#fff',
                    }}>
                    {lineMode.name}
                  </div>
                </UIDisruptedLineOverlay>
                <div className="lc-name">{line.name}</div>
              </div>
            )
            break
          }
          case 'codeWithDirection':
            element = (
              <div
                className={'lc-attribute-line ' + (line?.routes?.length === 0 ? ' lc-without-routes' : '')}
                key={line.id}
                onClick={e => {
                  e.stopPropagation()

                  // Add line to historic
                  if (storageAvailable('localStorage')) {
                    addHistoricItem(line)
                  }

                  !history.location.pathname.includes('route-calculation') && onLineSelected(line, marker)
                }}
                onKeyUp={e =>
                  handleKeyUp(e, () => {
                    // Add line to historic
                    if (storageAvailable('localStorage')) {
                      addHistoricItem(line)
                    }

                    !history.location.pathname.includes('route-calculation') && onLineSelected(line, marker)
                  })
                }
                role="button"
                tabIndex="0"
                aria-label={translate('aria-line', false, { key: 'code', value: line.code })}>
                <UIDisruptedLineOverlay line={line} styleLine={styleLine}>
                  <div
                    className="lc-line lc-code"
                    style={{
                      background: '#' + line.color,
                      color: luminance('#' + line.color) > 0.5 ? '#333' : '#fff',
                    }}>
                    {line.code}
                  </div>
                </UIDisruptedLineOverlay>
                {strikedLine(line)}
                <div className="lc-name">{line.name}</div>
              </div>
            )
            break
          case 'imageWithRouteDirection':
          case 'image':
            element = (
              <div
                className={
                  'lc-line' +
                  (line?.routes?.length === 0 ? ' lc-without-routes' : '') +
                  (isActiveModule('route-calculation') ? ' lc-in-route-calculation' : '')
                }
                key={line.id}
                onClick={e => {
                  e.stopPropagation()

                  // Add line to historic
                  if (storageAvailable('localStorage')) {
                    addHistoricItem(line)
                  }

                  if (!isActiveModule('route-calculation')) {
                    onLineSelected(line, marker)
                    message({ clicked: 'line', id: line.id })
                  }
                }}
                onKeyUp={e =>
                  handleKeyUp(e, () => {
                    // Add line to historic
                    if (storageAvailable('localStorage')) {
                      addHistoricItem(line)
                    }

                    if (!isActiveModule('route-calculation')) {
                      onLineSelected(line, marker)
                      message({ clicked: 'line', id: line.id })
                    }
                  })
                }
                role="button"
                tabIndex="0"
                aria-label={translate('aria-line', false, { key: 'code', value: line.code })}>
                <UIDisruptedLineOverlay line={line} styleLine={styleLine}>
                  <img
                    src={assetsPath(
                      '/assets/images/lines/' + (prefixNetwork ? line.network + '-' : '') + line.code + '.svg',
                    )}
                    alt={line.code}
                  />
                </UIDisruptedLineOverlay>
                {styleLine === 'imageWithRouteDirection' && line.direction && (
                  <div className="lc-name">{line.direction}</div>
                )}
                {strikedLine(line)}
              </div>
            )
            break
          case 'color':
            element = (
              <div
                key={line.id}
                className={
                  'lc-line' +
                  (line?.routes?.length === 0 ? ' lc-without-routes' : '') +
                  (line.code.length > 6 ? ' lc-line-long-code' : '') +
                  (isActiveModule('route-calculation') ? ' lc-in-route-calculation' : '')
                }
                onClick={e => {
                  e.stopPropagation()

                  if (storageAvailable('localStorage')) {
                    addHistoricItem(line)
                  }

                  if (!history.location.pathname.includes('route-calculation')) {
                    onLineSelected(line, marker)
                    message({ clicked: 'line', id: line.id })
                  }
                }}
                onKeyUp={e =>
                  handleKeyUp(e, () => {
                    if (storageAvailable('localStorage')) {
                      addHistoricItem(line)
                    }

                    if (!history.location.pathname.includes('route-calculation')) {
                      onLineSelected(line, marker)
                      message({ clicked: 'line', id: line.id })
                    }
                  })
                }
                role="button"
                tabIndex="0"
                aria-label={translate('aria-line', false, { key: 'code', value: line.code })}>
                <UIDisruptedLineOverlay line={line} styleLine={styleLine}>
                  <div
                    className="lc-line-code"
                    style={{
                      background: '#' + line.color,
                      color: `#${line.text}`,
                    }}>
                    {line.code}
                  </div>
                </UIDisruptedLineOverlay>
                {strikedLine(line)}
              </div>
            )
            break
          default:
            element = null
            break
        }

        return (
          <Fragment key={`f-l-${line.id}`}>
            {pictomode}
            {element}
          </Fragment>
        )
      })}
    </div>
  )

  if (!lines) {
    return
  }

  if (info) {
    return (
      <>
        {info_type === 'html' ? <div dangerouslySetInnerHTML={{ __html: info }}></div> : <div>{info}</div>}
        {div(lines)}
      </>
    )
  }

  return div(lines)
}

/**
 * Used to render an HTML element repsenting a striked line
 * @param {Object} line Line object
 * @returns HTML element representif a striked line, or nothing
 */
const strikedLine = line => {
  return line?.routes?.length === 0 ? <div className="lc-striked-line" /> : null
}

export const renderPlacesLabels = component => {
  const { openedCollapse, searchIn, aroundTabs } = component.props
  const { pois } = component.state

  if (!pois) {
    return
  }

  // we don't display searchIn result because we display this place on top of tab
  if (Object.keys(pois).filter(categorie => categorie !== searchIn).length === 0) {
    return <div className="lc-empty">{translate('no-places-around')}</div>
  }

  return Object.keys(pois)
    .filter(categorie => categorie !== searchIn)
    .sort((a, b) => {
      if (aroundTabs?.places?.includes(a) || aroundTabs?.places?.includes(b)) {
        return aroundTabs?.places?.indexOf(a) - aroundTabs?.places?.indexOf(b)
      } else if (aroundTabs?.transports?.includes(a) || aroundTabs?.transports?.includes(b)) {
        return aroundTabs?.transports?.indexOf(a) - aroundTabs?.transports?.indexOf(b)
      } else {
        return a.localeCompare(b)
      }
    })
    .map(poi => (
      <div
        key={poi}
        className={'lc-group lc-group-cat-' + poi}
        onClick={() => {
          if (poi === openedCollapse && poi === 'poi_type:stations') {
            appStore.dispatch(actionSetPlaceClicked(null))
          }

          appStore.dispatch(actionSetOpenedCollapse(poi))
        }}
        onKeyUp={e =>
          handleKeyUp(e, () => {
            if (poi === openedCollapse && poi === 'poi_type:stations') {
              appStore.dispatch(actionSetPlaceClicked(null))
            }

            appStore.dispatch(actionSetOpenedCollapse(poi))
          })
        }
        role="button"
        tabIndex="0"
        aria-label={translate(poi, false)}>
        <div className="lc-group-name">
          <div className="lc-group-mode" dangerouslySetInnerHTML={{ __html: translate(poi) }}></div>
          <div className="lc-arrow-group">
            <img
              className={poi !== openedCollapse ? 'lc-closed' : ''}
              src={assetsPath('/assets/images/v.svg')}
              alt={translate('collapse-arrow')}
            />
          </div>
        </div>
        <Collapse isOpen={poi === openedCollapse}>
          {Array.isArray(pois[poi])
            ? appStore.dispatch(actionBuildPlacesByCatInList({ [poi]: pois[poi] }))
            : appStore.dispatch(actionBuildPlacesByCatInList(pois[poi]))}
        </Collapse>
      </div>
    ))
}

/**
 * Render a marker on the map
 * @param stop
 * @param options
 * @param component
 * @param place
 * @returns Marker
 */
export const renderMarker = (component, stop, options) => {
  return (
    <Marker
      key={stop.id}
      ref={r => {
        stop.ref = r
      }}
      name={stop.name}
      position={[+stop.coord.lat, +stop.coord.lon]}
      icon={L.icon({
        iconUrl: assetsPath('/assets/images/pin.svg'),
        iconSize: [50, 50],
        iconAnchor: [25, 25],
      })}
      onClick={e => updatePopupPosition(e.target)}
      {...options}>
      {stop.terminus && (
        <Tooltip
          key={'terminus_' + stop.id}
          direction={'right'}
          className={'lc-tooltip-leaflet-terminus'}
          opacity={1}
          permanent>
          {stop.name}
        </Tooltip>
      )}
      <Popup className={'lc-popup-leaflet'} closeButton={false} autoClose={false} autoPan={false}>
        {renderInfobox(component, stop, null)}
      </Popup>
    </Marker>
  )
}

export const renderMarkerRouteCalculation = (key, component, position) => {
  const { map, language, moduleData } = component.props
  const { localizeMarkers } = moduleData

  return (
    <Marker
      key={key}
      draggable={!component.state.loading && component.state.interactiveMap}
      onDragStart={component.onBackToParams}
      onDragEnd={event => {
        const { pathname } = history.location
        const params = getURLSearchParams(history.location)
        const latlng = event.target.getLatLng()

        if (key === 'inputStart-pin') {
          if (map.state.inputStartPin && !map.state.inputEndPin) {
            history.push({
              pathname,
              search: '?from=' + substringCoords(latlng),
            })
          } else if (map.state.inputStartPin && map.state.inputEndPin) {
            history.push({
              pathname,
              search: '?from=' + substringCoords(latlng) + '&to=' + params.to,
            })
          }
        } else {
          if (!map.state.inputStartPin && map.state.inputEndPin) {
            history.push({
              pathname,
              search: '?to=' + substringCoords(latlng),
            })
          } else if (map.state.inputStartPin && map.state.inputEndPin) {
            history.push({
              pathname,
              search: '?from=' + params.from + '&to=' + substringCoords(latlng),
            })
          }
        }
      }}
      zIndexOffset={999999}
      icon={L.icon({
        iconUrl: assetsPath(
          `/assets/images/route-calculation/${key === 'inputStart-pin' ? 'flag-start' : 'flag-end'}${
            localizeMarkers ? '-' + language : ''
          }.svg`,
        ),
        className: `lc-route-calculation-markers ${key === 'inputStart-pin' ? 'start-pin' : 'end-pin'}`,
      })}
      position={position}
    />
  )
}

// TODO RENAME
export const renderPlaces = async (component, pois) => {
  const { map } = component.props
  const places = pois ? component.state.pois : component.state.places

  // TODO THROW ERROR
  if (!places) {
    console.warn('No places')
    return
  }

  const allPlaces = []

  for (const place of Object.keys(places)) {
    const items = Array.isArray(places[place])
      ? places[place]
      : Object.keys(places[place]).reduce((acc, item) => {
          acc.push(...places[place][item])
          return acc
        }, [])

    allPlaces.push(...items)
  }

  const markers = []

  for (const place of allPlaces) {
    markers.push(
      renderMarker(component, place, {
        icon: L.icon({
          iconUrl: assetsPath('/assets/images/places/') + place.code + '.svg',
          iconSize: [25, 25],
          iconAnchor: [12, 12],
        }),
      }),
    )
  }

  map.setState(
    {
      clusters: null,
      status: null,
      markers,
    },
    () => resize(map.props.isMobile),
  )
}

export const renderPolygon = (path, options, key, props) => {
  return <Polygon {...options} key={key} positions={path} {...props} />
}

export const renderPolyline = (path, options, key) => {
  return <Polyline key={key} positions={path} options={options} />
}

/**
 * Retrieve schedules for a given stop in a given component
 * @param component
 * @param stop
 * @param line
 * @param date
 * @param fromMarkerClick
 */
export const schedules = (component, stop, line, date = '') => {
  const { stopsList } = component.state
  const { configApp } = appStore.getState().app

  if (!stopsList) {
    return
  }

  const isTerminus = stop.terminus && stop.id === stopsList[stopsList.length - 1]?.id // TCL style

  for (const s of stopsList) {
    s.schedules = null
    s.opened = false
  }

  // Remove any current line information message
  appStore.dispatch(actionSetLineInformation(null))

  // TODO real timetable fix
  if (component.state.timetable) {
    return
  }

  // TODO DANS AROUND ARRET LE PLUS PROCHE PAS VISIBLE DU COUP...
  if (line.cat === 'tad' || line.cat === 'rer') {
    return
  }

  // Avoid load schedules for a nonsense data
  let markerContainsLine = false

  for (const data of stop.lines) {
    if (data.id === line.id) {
      markerContainsLine = true
      break
    }
  }

  if (!markerContainsLine) {
    return
  }

  for (const s of stopsList) {
    s.schedules = null
    s.opened = false

    if (stop.id.includes('stop_area')) {
      s.opened = s.stop_area === stop.id && !isTerminus
    } else {
      s.opened = s.id === stop.id && !isTerminus
    }
  }

  component.setState(
    {
      // timetable: false,
      stopsList,
    },
    async () => {
      // Retrieve element and scroll to it
      const selected = stop.id.includes('stop_area')
        ? stopsList.filter(s => s.stop_area === stop.id)
        : stopsList.filter(s => s.stop_area === stop.stop_area && s.id === stop.id)

      if (selected.length > 0) {
        // Select the stop
        setTimeout(() => displayStopOnScrollToIt(component, selected[0]), 150)

        if (!isTerminus && (!line.tad || (line.tad && line.tad.schedules))) {
          const schedules = []
          const route = line.routes.find(r => r.direction_id === line.direction_id).route_id

          const moduleDataDisplayScheduleDestination = component.props.moduleData
            ? component.props.moduleData.displayScheduleDestination
            : component.props.displayScheduleDestination

          const params = {
            stop: selected[0].id,
            route,
            date,
            count: nextSchedules,
            withScheduleDestination: moduleDataDisplayScheduleDestination === true,
            area: moduleDataDisplayScheduleDestination === true ? selected[0].stop_area : undefined,
            line: line.id,
          }

          if (REACT_APP_SCHEDULES_EXTENDS) {
            params.extend = +REACT_APP_SCHEDULES_EXTENDS
          }

          // Because no moduleData for around...
          if (component.props?.moduleData?.defaultDateTime || component.props.defaultDateTime) {
            const { minDate, maxDate, defaultDateTime } = component.props?.moduleData || component.props

            params.date = component.props?.moduleData?.defaultDateTime || component.props.defaultDateTime

            // Check if current date is between min and max
            if (defaultDateTime && minDate && maxDate) {
              const minD = new Date(minDate),
                maxD = new Date(maxDate),
                now = new Date()

              if (now > minD && now < maxD) {
                params.date = formatDate(now, 'ymdThm') + '00'
              }
            }
          }

          try {
            const getScheduleList = (response, schedules) => {
              for (const resp of moduleDataDisplayScheduleDestination === true
                ? response.data
                : response.data.slice(0, nextSchedules)) {
                schedules.push({
                  time: resp.date_time,
                  realtime: resp.data_freshness === 'realtime',
                  extend:
                    REACT_APP_SCHEDULES_EXTENDS ||
                    (configApp.display_date_on_other_day_schedules &&
                      dateIsAtLeastTomorrow(navitiaDateToDate(resp.date_time)))
                      ? true
                      : false,
                  equipments: resp.equipments ? resp.equipments : [],
                  destination: resp.destination ? resp.destination : false,
                  route: resp.route ? resp.route : undefined,
                  stop_point: resp.stop_point ? resp.stop_point : undefined,
                })
              }

              appStore.dispatch(actionSetLinesSchedules(schedules))

              for (const select of selected) {
                select.schedules = schedules.filter(
                  s =>
                    (s.route ? s.route === select.route_id : true) &&
                    (s.stop_point ? s.stop_point === select.id : true),
                )
              }
            }

            const getSchedules = await axios({ url: '/api/schedules', params })

            getScheduleList(getSchedules, schedules)

            if (schedules.length === 0 && line.duplicate) {
              const routeFromDuplicate = line.duplicate.routes.find(r => r.direction_id === line.direction_id).route_id

              params.route = routeFromDuplicate
              const getSchedulesFromDuplicateLine = await axios({ url: '/api/schedules', params })

              getScheduleList(getSchedulesFromDuplicateLine, schedules)
            }
          } catch (e) {
            for (const select of selected) {
              select.schedules = []
            }

            const error = e.response && e.response.data ? e.response.data.id : e

            console.warn(error)
          }

          component.setState({ stopsList })
        }
      }
    },
  )
}

/**
 *
 * @param component
 * @param stop
 * @param line
 * @param selected
 */
const displayStopOnScrollToIt = (component, selected) => {
  const { stopsList } = component.state
  // The Elder Scrooooooooll
  let index = 0

  for (const s of stopsList) {
    if (s.id === selected.id) {
      index = s.index
      break
    }
  }

  const element = document.querySelector(`.lc-ul-stops > li.lc-stop[data-stop-index="${index}"]`)
  const disruptionsElement = document.querySelector('[data-lc-disruptions]')
  const tadElement = document.querySelector('.lc-line-tad-informations')
    ? document.querySelector('.lc-line-tad-informations').getBoundingClientRect().height +
      document.querySelector('.lc-line-tad-header').getBoundingClientRect().height
    : false

  // Scroll board to the selected element
  setTimeout(() => {
    element &&
      document?.querySelector('.lc-stops') &&
      (document.querySelector('.lc-stops').scrollTop =
        element.offsetTop -
        (element.parentNode.offsetTop + (disruptionsElement ? 10 : 5) - (tadElement ? tadElement : 0)))
  }, 250)
}

/**
 * Display line paths and info
 * @param component
 * @param line
 * @param linesTab
 * @param selectedLines
 */
export const selectLine = (component, line) => {
  const { stops } = component.props
  const displayLinesAt = appStore.getState().app.config?.displayLinesAt

  if (!line.direction_id) {
    line.direction_id = 'f'
  }

  if (line.routes?.length > 0 && line.routes.find(r => r.direction_id === line.direction_id) === undefined) {
    line.direction_id = line.routes[0].direction_id
  }

  const selectedRoute = line.routes?.find(r => r.id[r.id.length - 1] === line.direction_id)

  if (!selectedRoute?.stops || displayLinesAt !== undefined) {
    let folder = 'stops'

    if (displayLinesAt) {
      const datasets = appStore.getState().app.datasets
      const datedisplayLinesAt = new Date(displayLinesAt)

      const dataset = datasets.find(ds => {
        if (ds.start_validation_date && ds.end_validation_date) {
          const startDate = new Date(navitiaDateToDate(ds.start_validation_date))
          const endDate = new Date(navitiaDateToDate(ds.end_validation_date))

          if (startDate <= datedisplayLinesAt && endDate >= datedisplayLinesAt) {
            return true
          }
        }

        return false
      })

      if (dataset) {
        folder = `datasets/${dataset.id.replace(':', '_')}/stops`
      }
    }

    return axios
      .get(
        `/api/file?folder=${folder}&name=${encodeURIComponent(line.code)}_${line.network}_${line.direction_id}~${
          component.props.hash
        }`,
      )
      .then(async response => {
        // TODO Fix retrive lines at generate
        for (const stop of response.data) {
          const infos = stops.find(s => s.id === stop.id)

          stop.lines = infos.lines

          // Define line as PMR only if ONE stop is PMR
          if (stop.pmr) {
            line.pmr = true
          }
        }

        line.stops = response.data
        selectedRoute.stops = response.data
        return {
          currentLine: line,
          stopsList: response.data,
        }
      })
      .catch(error => {
        console.warn(
          'Unable to find stops/' + line.code + '_' + line.network + '_' + line.direction_id + '.json',
          error,
        )
      })
  } else {
    // Close all saved stops
    for (const stop of selectedRoute.stops) {
      stop.opened = false
    }

    line.stops = selectedRoute.stops

    return {
      currentLine: line,
      stopsList: selectedRoute.stops,
    }
  }
}

/**
 * Update map state events
 * @param map
 * @param event
 * @param callback
 */
export const updateMapEvents = (map, event, callback) => {
  if (!map) {
    return
  }

  map.setState(state => ({
    events: {
      ...state.events,
      [event]: callback,
    },
  }))
}

export const zoomOnTerritoryOutline = map => {
  const outline = map.props.territoryOutline
  const outlineToRender = []
  const bounds = []

  if (outline && outline?.features?.length > 0) {
    for (const feature of outline.features) {
      let featureCoords = feature.geometry.coordinates

      if (['Polygon', 'MultiLineString'].includes(feature.geometry.type)) {
        featureCoords = featureCoords[0]
      }

      for (const coords of featureCoords) {
        bounds.push([coords[1], coords[0]])
        outlineToRender.push(...coords)
      }
    }

    setTimeout(() => {
      fitBounds(map, bounds)
    })
  }
}

// --------------------------- PRIVATE --------------------------- //
const collapseTimetableOptions = component => {
  try {
    component.setState(
      {
        timetableData: null,
        timetableOptions: !component.state.timetableOptions,
      },
      async () => {
        if (component.state.timetableOptions) {
          const element = document.querySelector('[data-lc-timetable-options]')

          if (element) {
            element.style.top = document.querySelector('[data-lc-line-header]').offsetHeight + 'px'
          }
        }

        const timetables = []

        if (REACT_APP_TIMETABLES === 'local') {
          const response = await axios('/api/file?name=timetables')
          const timetable = response.data.find(data => data.ligne === component.state.currentLine.code)

          if (timetable && timetable.fiche_horaire) {
            timetable.fiche_horaire.split('~').map((fh, index) => {
              return timetables.push({
                id: index,
                name: fh,
                file: 'assets/timetables/' + fh + '.pdf',
                starting_validity_date: '',
                ending_validity_date: '',
              })
            })
          }
        } else if (REACT_APP_TIMETABLES === 'api') {
          const response = await axios('/api/timetable?line_id=' + component.state.currentLine.id)

          for (const timetable of response.data.data) {
            timetables.push({
              id: timetable.id[0].value,
              name: timetable.name[0].value,
              file: timetable.file_timetable[0].url,
              starting_validity_date: timetable.starting_validity_date[0].value,
              ending_validity_date: timetable.ending_validity_date[0].value,
            })
          }
        }

        if (timetables.length === 1 && REACT_APP_TIMETABLES === 'local') {
          updateDataLayer({
            event: 'map-downloadTimetable',
            line: component.state.currentLine.code,
          })

          component.setState(
            {
              directDownload: true,
            },
            () => {
              window.open('/' + timetables[0].file)
            },
          )
        } else {
          component.setState({
            timetableLineData: timetables,
          })
        }
      },
    )
  } catch (e) {
    console.warn(e)
  }
}

/**
 * Limit api call
 * @param func
 * @param wait
 * @param immediate
 * @returns {Function}
 */
export const debounce = (func, wait, immediate) => {
  let timeout

  return function () {
    const later = () => {
      timeout = null

      if (!immediate) {
        func.apply(this, arguments)
      }
    }

    const callNow = immediate && !timeout

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)

    if (callNow) {
      func.apply(this, arguments)
    }
  }
}

/**
 * Display a timetable for a stop and a specific line
 * @param component
 * @param stop
 * @param line
 * @param date
 */
export const displayTimeTable = (component, stop, line, date) => {
  // 0: morning, 1: afternoon, 2: evening selected by defaut with the hour if today
  const isToday = formatDate(new Date(), 'ymd') === date
  const nowHours = Math.floor(new Date().getHours())
  let slideIndex = isToday ? (nowHours >= 4 && nowHours <= 11 ? 0 : nowHours >= 12 && nowHours <= 19 ? 1 : 2) : 0

  // get stop id of line if we have a stop_area
  if (stop.id.includes('stop_area')) {
    stop.stop_id = stop.lines.find(l => l.id === line.id).stop_id
  }

  try {
    component.setState(
      {
        timetable: true,
        loadingTimetable: true,
        timetableStop: stop.name,
      },
      async () => {
        const route = line.routes.find(r => r.direction_id === line.direction_id).route_id

        const params = {
          stop: stop.stop_id || stop.id,
          route,
          timetable: true,
        }

        const nightLines = REACT_APP_NIGHT_LINES ? JSON.parse(REACT_APP_NIGHT_LINES) : { departure: '00', lines: [] }

        if (date) {
          appStore.dispatch(actionSetCalendarDate(new Date(date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'))))

          if (nightLines.lines.includes(component.state.currentLine.id)) {
            params.date = `${date}T${nightLines.departure}0000`
            slideIndex = 0 // There is only one slide for the night lines
          } else {
            params.date = date + 'T040000'
          }
        }

        // Because no moduleData for around...
        if (component.props?.moduleData?.defaultDateTime || component.props.defaultDateTime) {
          const { minDate, maxDate, defaultDateTime } = component.props?.moduleData || component.props

          // Check if current date is between min and max
          if (defaultDateTime && minDate && maxDate) {
            const minD = new Date(minDate),
              maxD = new Date(maxDate),
              scheduleDate = new Date(navitiaDateToDate(`${date}T040000`))

            if (scheduleDate > minD && scheduleDate < maxD) {
              params.date = scheduleDate
            } else {
              // force date to return at defaultDateTime if not in range
              params.date = component.props?.moduleData?.defaultDateTime || component.props.defaultDateTime
              initDateTimePicker(component.props?.moduleData || component.props)
            }
          }
        }

        try {
          const getTimetable = await axios({ url: '/api/schedules', params })
          let { timetableData, timetableHours } = buildTimetableDatas(getTimetable.data, line)

          if (timetableDataIsEmpty(timetableData) && !line.duplicate) {
            // fix crash
            slideIndex = 0
          } else if (timetableDataIsEmpty(timetableData) && line.duplicate) {
            const routeFromDuplicate = line.duplicate.routes.find(r => r.direction_id === line.direction_id).route_id

            params.route = routeFromDuplicate
            const getTimetableFromDuplicateLine = await axios({ url: '/api/schedules', params })

            ;({ timetableData, timetableHours } = buildTimetableDatas(getTimetableFromDuplicateLine.data, line))

            if (timetableDataIsEmpty(timetableData)) {
              // fix crash
              slideIndex = 0
            }
          }

          // Force timetable  data in redux cause component.setState crash all printing
          appStore.dispatch(actionSetTimetableData({ timetableData, timetableHours }))

          component.setState(
            {
              slideIndex,
              timetableData,
              timetableHours,
              loadingTimetable: false,
              timetableError: false,
            },
            () => {
              const divs = Array.from(document.querySelectorAll("[data-lc-scroll='scroll']"))

              resize(component.props.map.props.isMobile, divs[component.state.slideIndex])
            },
          )
        } catch (e) {
          const timetableData = {
            morning: [],
            afternoon: [],
            evening: [],
          }

          console.warn('error', e.response?.data ? e.response.data : e)
          component.setState({
            loadingTimetable: false,
            timetableData: timetableData,
            timetableError: e.response,
          })
        }
      },
    )
  } catch (e) {
    console.log(e)
  }
}

export const addMetersToLatitude = (meters, latitude) => {
  const earth = 6378.137, //radius of the earth in kilometer
    pi = Math.PI,
    m = 1 / (((2 * pi) / 360) * earth) / 1000 //1 meter in degree

  const new_latitude = parseFloat(parseFloat(parseFloat(latitude) + parseInt(meters) * m).toFixed(6))

  return new_latitude
}

export const addMetersToLongitude = (meters, latitude, longitude) => {
  const earth = 6378.137, //radius of the earth in kilometer
    pi = Math.PI,
    cos = Math.cos,
    m = 1 / (((2 * pi) / 360) * earth) / 1000 //1 meter in degree

  const new_longitude = parseFloat(
    parseFloat(parseFloat(longitude) + (parseInt(meters) * m) / cos(parseFloat(latitude) * (pi / 180))).toFixed(6),
  )

  return new_longitude
}

export const getStopPointsZoomLevel = placesToDisplay => {
  const overrideZoomSpoints =
    placesToDisplay?.zoom &&
    placesToDisplay?.zoom['stop_points']?.min !== undefined &&
    placesToDisplay?.zoom['stop_points']?.max !== undefined

  if (overrideZoomSpoints && placesToDisplay.zoom['stop_points'].min && placesToDisplay.zoom['stop_points'].max) {
    const levels = []

    for (let i = placesToDisplay.zoom['stop_points'].min; i <= placesToDisplay.zoom['stop_points'].max; i++) {
      levels.push(i)
    }

    return levels
  } else {
    return [17, 18, 19, 20, 21]
  }
}

export const getStopAreasZoomLevel = placesToDisplay => {
  const overrideZoomAreas =
    placesToDisplay?.zoom &&
    placesToDisplay?.zoom['stop_areas']?.min !== undefined &&
    placesToDisplay?.zoom['stop_areas']?.max !== undefined

  if (overrideZoomAreas && placesToDisplay.zoom['stop_areas'].min && placesToDisplay.zoom['stop_areas'].max) {
    const levels = []

    for (let i = placesToDisplay.zoom['stop_areas'].min; i <= placesToDisplay.zoom['stop_areas'].max; i++) {
      levels.push(i)
    }

    return levels
  } else if (!overrideZoomAreas && process.env.REACT_APP_AREAS_ZOOM_LEVEL) {
    const levels = []

    for (let i = +process.env.REACT_APP_AREAS_ZOOM_LEVEL; i <= 17; i++) {
      levels.push(i)
    }

    return levels
  } else {
    return []
  }
}
