import L from 'leaflet'
import { Component } from 'react'
import { connect } from 'react-redux'
import { appStore } from '../store'
import { actionBuildMarker, actionSetAreas, actionSetStops } from '../actions/withRedux'
import { actionHandleLegendState, actionSetZoom, actionSetCenter } from '../actions/map'
import { getLine, assetsPath, handleKeyUp, compareValues, getURLSearchParams, envVarToBool } from '../services/tools'
import history from '../history'
import { OnScreen } from '../hoc/onScreen'
import { componentIfModuleFallback } from '../utils/codeSplit'
import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import { getLinesModes, removeAccents } from '../utils/tools'
const Leaflet = componentIfModuleFallback('Leaflet', () => import('./Leaflet'))
const LeafletNetwork = componentIfModuleFallback('LeafletNetwork', () => import('./LeafletNetwork'))
const LeafletNewNetwork = componentIfModuleFallback('LeafletNewNetwork', () => import('./LeafletNewNetwork'))
const LeafletProjects = componentIfModuleFallback('LeafletProjects', () => import('./LeafletProjects'))
const LeafletTerritories = componentIfModuleFallback('LeafletTerritories', () => import('./LeafletTerritories'))

const {
  REACT_APP_START_POINT,
  REACT_APP_ZOOM,
  REACT_APP_TYPE,
  REACT_APP_DISABLED_STOP_AREA_MARKER_MODE,
  REACT_APP_MARKER_SELECTED_PICTO,
} = process.env

class Map extends Component {
  componentDidMount() {
    const center = this.props.startOnMobile
      ? JSON.parse(REACT_APP_START_POINT).mobile
      : JSON.parse(REACT_APP_START_POINT).desktop

    const zoom = this.props.startOnMobile ? JSON.parse(REACT_APP_ZOOM).mobile : JSON.parse(REACT_APP_ZOOM).desktop

    appStore.dispatch(actionSetZoom(zoom))
    appStore.dispatch(actionSetCenter(center))
  }

  componentDidUpdate(prevProps) {
    // Do not run this lifecycle on network map
    if (REACT_APP_TYPE === 'network' || REACT_APP_TYPE === 'projects') {
      return
    }

    let { areas, stops } = this.props
    const { lines, reactAreas, openedMarker } = this.props

    areas = areas.filter(m => {
      return m.lines.length
    })

    stops = stops.filter(m => {
      return m.lines.length
    })

    if (!reactAreas && areas.length > 0) {
      const reactAreas = []
      const reactStops = []

      for (const area of areas) {
        const mode = envVarToBool(REACT_APP_DISABLED_STOP_AREA_MARKER_MODE)
          ? 'area'
          : retrieveMostImportantMode(lines, area)

        if (this.props.configApp?.filter_stops_in_only_mode) {
          const areaModes = getLinesModes(area)
          if (areaModes.length === 1 && this.props.configApp.filter_stops_in_only_mode === areaModes[0]) {
            continue
          }
        }

        reactAreas.push(
          appStore.dispatch(
            actionBuildMarker(area, {
              icon: this.props.configApp?.stop_area_marker_with_stop_points_number
                ? L.divIcon({
                    className: `lc-stop-marker lc-stop-marker-area with-sp-number lc-stop-marker-area-${mode}`,
                    html: `<div className="nb-stops">${String(
                      stops.filter(s => s.stop_area === area.id).length,
                    )}</div>`,
                  })
                : L.icon({
                    className: `lc-stop-marker lc-stop-marker-area lc-stop-marker-area-${mode}`,
                    iconUrl: assetsPath(`/assets/images/stops/${mode}.svg`),
                  }),
              area,
              zIndexOffset: 50,
            }),
          ),
        )
      }

      for (const stop of stops) {
        const mode = retrieveMostImportantMode(lines, stop)

        if (this.props.configApp?.filter_stops_in_only_mode) {
          const stopModes = getLinesModes(stop)
          if (stopModes.length === 1 && this.props.configApp.filter_stops_in_only_mode === stopModes[0]) {
            continue
          }
        }

        reactStops.push(
          appStore.dispatch(
            actionBuildMarker(stop, {
              icon: L.icon({
                className:
                  'lc-stop-marker' + (stop.terminus ? ' lc-stop-marker-terminus' : '') + ' lc-stop-marker-' + mode,
                iconUrl: assetsPath(`/assets/images/stops/${mode}.svg`),
              }),
              stop,
              zIndexOffset: 50,
            }),
          ),
        )
      }

      // Dispatch markers in the store
      appStore.dispatch(actionSetAreas(reactAreas))
      appStore.dispatch(actionSetStops(reactStops))
    }

    if (
      REACT_APP_MARKER_SELECTED_PICTO &&
      JSON.parse(REACT_APP_MARKER_SELECTED_PICTO).includes('stops') &&
      ((openedMarker && openedMarker.ref) || (prevProps.openedMarker && !openedMarker))
    ) {
      const selected = !(prevProps.openedMarker && !openedMarker)
      const om = selected ? openedMarker : prevProps.openedMarker

      if (
        om &&
        om.id.startsWith('stop_') &&
        om.ref &&
        !om.ref?.leafletElement?._icon?.classList?.contains('lc-circle-icon-marker')
      ) {
        const mode =
          om.id.startsWith('stop_area') && envVarToBool(REACT_APP_DISABLED_STOP_AREA_MARKER_MODE)
            ? 'area'
            : retrieveMostImportantMode(lines, om)

        om.ref.leafletElement.setIcon(
          L.icon({
            className:
              'lc-stop-marker' +
              (om.terminus ? ' lc-stop-marker-terminus' : '') +
              ' lc-stop-marker-' +
              mode +
              (om.id.startsWith('stop_area') ? ' lc-stop-marker-area' : ''),
            iconUrl: assetsPath(`/assets/images/stops/${mode}${selected ? '_selected' : ''}.svg`),
          }),
        )
      }
    }
  }

  render() {
    const { showBoard, startOnMobile, isLegendOpen, component } = this.props
    const legendUrl = component?.props?.moduleData?.legend
    const avoidPaths = ['/route-calculation', '/lines']

    // Toggle the legend back if we don't have any legend anymore (and was toggle before)
    if (isLegendOpen && component?.props?.moduleData && !legendUrl) {
      appStore.dispatch(actionHandleLegendState(isLegendOpen))
    }

    // Run a separate map for network map
    if (REACT_APP_TYPE === 'network' && !avoidPaths.includes(history.location.pathname)) {
      const params = getURLSearchParams(history.location)

      return (
        <>
          {isLegendOpen && !params.line && !params.stop_point && !params.stop_area && !params.town && (
            <div
              className="lc-legend-panel"
              onClick={() => {
                appStore.dispatch(actionHandleLegendState(isLegendOpen))
              }}
              onKeyUp={e => handleKeyUp(e, () => appStore.dispatch(actionHandleLegendState(isLegendOpen)))}
              role="button"
              tabIndex="0">
              <span className="lc-legend-close">╳</span>
              <div
                className="lc-legend-img"
                style={{
                  backgroundImage: `url(/assets/images/legend-img.svg)`,
                }}
                alt="legend"></div>
            </div>
          )}
          <LeafletNetwork showBoard={showBoard} startOnMobile={startOnMobile} isLegendOpen={isLegendOpen} />
        </>
      )
    } else if (REACT_APP_TYPE === 'new-network' && !avoidPaths.includes(history.location.pathname)) {
      const params = getURLSearchParams(history.location)

      return (
        <>
          {isLegendOpen && !params.line && !params.stop_point && !params.stop_area && !params.town && (
            <div
              className="lc-legend-panel"
              onClick={() => {
                appStore.dispatch(actionHandleLegendState(isLegendOpen))
              }}
              onKeyUp={e => handleKeyUp(e, () => appStore.dispatch(actionHandleLegendState(isLegendOpen)))}
              role="button"
              tabIndex="0">
              <span className="lc-legend-close">╳</span>
              <div
                className="lc-legend-img"
                style={{
                  backgroundImage: `url(/assets/images/legend-img.svg)`,
                }}
                alt="legend"></div>
            </div>
          )}
          <LeafletNewNetwork showBoard={showBoard} startOnMobile={startOnMobile} isLegendOpen={isLegendOpen} />
        </>
      )
    } else if (REACT_APP_TYPE === 'projects') {
      return <LeafletProjects showBoard={showBoard} startOnMobile={startOnMobile} />
    } else if (REACT_APP_TYPE === 'territories') {
      return <LeafletTerritories showBoard={showBoard} startOnMobile={startOnMobile} />
    } else {
      return (
        <>
          {isLegendOpen && (
            <div
              className="lc-legend-panel"
              onClick={() => {
                appStore.dispatch(actionHandleLegendState(isLegendOpen))
              }}
              onKeyUp={e =>
                handleKeyUp(e, () => {
                  appStore.dispatch(actionHandleLegendState(isLegendOpen))
                })
              }
              role="button"
              tabIndex="0">
              <span className="lc-legend-close">╳</span>
              <div
                className="lc-legend-img"
                style={{
                  backgroundImage: `url(${assetsPath(`/assets/images/${legendUrl ?? 'legend-img.svg'}`)})`,
                }}
                alt="legend"></div>
            </div>
          )}
          <OnScreen>{onScreen => <Leaflet onScreen={onScreen} {...this.props} />}</OnScreen>
        </>
      )
    }
  }
}

const retrieveMostImportantMode = (lines, stop) => {
  // TODO : find a way to sort lines by important mode (with modes.json from wynd ?)
  stop.lines = stop.lines.sort(compareValues('cat', 'desc'))
  let indexOfMode = 99

  const modes = [
    'commercial_mode:train',
    'commercial_mode:ter',
    'commercial_mode:metro',
    'commercial_mode:funiculaire',
    'commercial_mode:telepherique',
    'commercial_mode:tramway',
    'commercial_mode:busc',
    'commercial_mode:ferry',
    'commercial_mode:bus',
    'commercial_mode:autocar',
    'commercial_mode:tad',
    'commercial_mode:busscolaire',
  ]

  const picto = [
    'train',
    'ter',
    'subway',
    'funicular',
    'cablecar',
    'tramway',
    'c-lines',
    'ferry',
    'bus',
    'autocar',
    'odt',
    'school',
  ]

  for (const l of stop.lines) {
    const info = getLine(lines, l)
    const mode = info?.mode ? removeAccents(info.mode).toLowerCase() : ''

    if (mode && modes.indexOf(removeAccents(mode)) !== -1 && modes.indexOf(removeAccents(mode)) <= indexOfMode) {
      indexOfMode = modes.indexOf(removeAccents(mode))
    }
  }

  return indexOfMode !== 99 ? picto[indexOfMode] : 'bus'
}

const mapStateToProps = state => {
  return {
    config: state.app.config,
    modules: state.app.modules,
    isMobile: state.app.isMobile,
    component: state.app.component,
    configApp: state.app.configApp,
    areas: state.app.areas,
    stops: state.app.stops,
    lines: state.app.lines,
    isLegendOpen: state.map.isLegendOpen,
    reactAreas: state.map.reactAreas,
    reactStops: state.map.reactStops,
    reduxMarkers: state.map.reduxMarkers,
    selectedLine: state.map.selectedLine,
    heavyLines: state.map.heavyLines,
    hideHeavyLines: state.map.hideHeavyLines,
    complementaryLine: state.map.complementaryLine,
    complementaryLines: state.map.complementaryLines,
    customLines: state.map.customLines,
    customMarkers: state.map.customMarkers,
    transportPlaces: state.map.transportPlaces,
    mapPlaces: state.map.mapPlaces,
    places: state.map.places,
    territoryOutline: state.map.territoryOutline,
    entranceMapMarkers: state.map.entranceMapMarkers,
    entrancePopup: state.map.entrancePopup,
    bikePaths: state.map.bikePaths,
    bikes: state.map.bikes,
    reactTourismPartnersStops: state.map.reactTourismPartnersStops,
    reactLines: state.map.reactLines,
    zoom: state.map.zoom,
    center: state.map.center,
    aroundPin: state.map.aroundPin,
    aroundCircles: state.map.aroundCircles,
    markerMode: state.map.markerMode,
    languageFile: state.app.languageFile,
    openedMarker: state.map.openedMarker,
    isExpandedMap: state.app.isExpandedMap,
  }
}

export default connect(mapStateToProps)(Map)
